import { Navigate, useRoutes } from 'react-router-dom';
import { lazy, useEffect, useState } from 'react';
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
import ProfileSettings from 'components/profile';
import {
  ComingSoonPage,
  Loadable,
  LoginPage,
  MaintenancePage,
  NewPasswordPage,
  Page403,
  Page404,
  Page500,
  RegisterPage,
  ResetPasswordPage,
  VerifyCodePage,
  UpdatePatientInfoFromTG,
  GuestInfo,
} from './elements';
import { PATH_AFTER_LOGIN } from '../config-global';
import { useAuthContext } from '../auth/useAuthContext';
import flattenArray from '../utils/flattenArray';
import LoadingScreen from '../components/loading-screen';
import { ROOTS_DASHBOARD } from './paths';
import { isEmpty } from 'lodash';
import { element } from 'prop-types';

// ----------------------------------------------------------------------
const load = (path, department_key) => {
  if (department_key) {
    return Loadable(
      lazy(() =>
        import(`../pages/main/default-visit`).catch(() => import(`../pages/ComingSoonPage`))
      )
    );
  }
  return Loadable(
    lazy(() =>
      import(`../pages${path}`).catch((error) => {
        console.log('catch:', error);
        return import(`../pages/ComingSoonPage`);
      })
    )
  );
};
// const load = (path, department_key) => {
//   if (!department_key) {
//     return Loadable(
//       lazy(() => import(`../pages${path}`).catch(() => import(`../pages/ComingSoonPage`)))
//     );
//   }
//   return Loadable(
//     lazy(() => import(`../pages${path}`).catch(() => import(`../pages/main/default-visit`)))
//   );
// };
// ----------------------------------------------------------------------

export const DashboardPath = ROOTS_DASHBOARD.replace('/', '');

export default function Router() {
  const [loading, setLoading] = useState(true);
  const { user } = useAuthContext();
  const accessModules = user?.user_access_module;
  const formatRoutes = flattenArray(accessModules, 'child');

  let firstRoute = { path: '', element: null };

  const dynamicRoutes =
    formatRoutes?.map((route, ind) => {
      const component = load(route?.url, route?.department_key);
      const url = route?.url?.replace(`/${DashboardPath}/`, '');

      const item = {
        path: url,
        element: component(),
      };

      if (isEmpty(route?.child) && firstRoute?.path === '') {
        firstRoute = {
          path: route?.url,
          index: true, // Use index true for the first route
          element: component(),
        };
      }

      return item;
    }) || [];

  const defaultRoutes = [
    {
      path: 'guest/patient-update-info/:key',
      element: <UpdatePatientInfoFromTG />,
    },
    {
      path: 'guest/info/:patient_data',
      element: <GuestInfo />,
      // element: <h2>hello</h2>,
    },
    {
      path: '',
      element: <Navigate to="/auth/login" replace />,
      index: true,
    },

    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },
  ];

  const otherRoutes = [
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ];

  const unprotectedRoutes = useRoutes([
    ...defaultRoutes,
    {
      path: '*',
      element: <Navigate to="/auth/login" replace />,
    },
  ]);

  const routes = useRoutes([
    ...defaultRoutes,
    {
      path: DashboardPath,
      element: (
        // <AuthGuard>
        <DashboardLayout />
        // </AuthGuard>
      ),
      children: [
        { path: '', element: <Navigate to={firstRoute?.path} replace /> },
        ...dynamicRoutes,
        // { path: 'profile-settings', element: <ProfileSettings /> },
      ],
    },
    ...otherRoutes,
  ]);

  useEffect(() => {
    if (user) {
      if (dynamicRoutes?.length > 0) {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [dynamicRoutes]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (!user) {
    return unprotectedRoutes;
  }

  return routes;
}
