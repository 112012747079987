import PropTypes from 'prop-types';
// @mui
import { IconButton } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// config
import { NAV } from '../../../config-global';
// components
import Iconify from '../../../components/iconify';
import { useSettingsContext } from '../../../components/settings';

// ----------------------------------------------------------------------

NavToggleButton.propTypes = {
  sx: PropTypes.object,
  actionClick: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default function NavToggleButton({ sx, actionClick = () => {}, isOpen = false, ...other }) {
  const { themeLayout, onToggleLayout } = useSettingsContext();

  const isDesktop = useResponsive('up', 'lg');

  if (!isDesktop) {
    return null;
  }

  return (
    <IconButton
      size="small"
      // onClick={onToggleLayout}
      onClick={actionClick}
      sx={{
        transition: 'all 0.2s ease-in-out',
        p: 0.5,
        top: 32,
        position: 'fixed',
        left: isOpen ? NAV.W_DASHBOARD + 35 : 35,
        bgcolor: 'background.default',
        zIndex: (theme) => theme.zIndex.appBar + 1,
        // border: (theme) => `dashed 1px ${theme.palette.divider}`,
        // '&:hover': {
        //   bgcolor: 'background.default',
        // },
        ...sx,
      }}
      {...other}
    >
      <Iconify width={20} icon="mdi:menu" sx={{ color: 'black' }} />
    </IconButton>
  );
}
