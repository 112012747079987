import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

// depthPath is the path to the slice from the root reducer end with a dot (.)
const name = 'doctorList';
const depthPath = 'managements.';

const apis = {
  getOne: '/api-employee/get-doctor/',
  getAll: '/api-employee/get-all-doctors',
  create: '/api-employee/add-doctor',
  update: '/api-employee/update-doctor/',
  delete: '/api-employee/delete-doctor/',

  // Doctor Benefits
  getBenefits: '/api-employee/get-doctor-benefit-by-object-type',
};

// initialState is the initial state of the slice
const initialState = {
  data: [],
  employee_data: [],
  department_data: [],
  benefitsOfDoctor: [],
  loading: false,
  detialLoding: false,
  currentEditData: null,
  openCreateDialog: false,
  doctorId: null,
  loadingBenefit: false,
};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setEmployeeData: (state, action) => {
      state.employee_data = action.payload;
    },
    setDepartmentData: (state, action) => {
      state.department_data = action.payload;
    },
    setBenefitsDoctor: (state, action) => {
      state.benefitsOfDoctor = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLoadingBenefit: (state, action) => {
      state.loadingBenefit = action.payload;
    },
    setDetailLoading: (state, action) => {
      state.detialLoding = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
    setOpenCreateDialog: (state, action) => {
      state.openCreateDialog = action.payload;
    },
    setCurrentEditData: (state, action) => {
      state.currentEditData = action.payload;
    },
    setDoctorID: (state, action) => {
      state.doctorId = action.payload;
    },
  },
});

// promiseActions are the actions that are dispatched by the components
const fetchAllDoctors = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAll, data);
    dispatch(
      slice.actions.setData(
        response?.data?.data?.map((item) => ({ ...item, id: item?.employee_id }))
      )
    );

    dispatch(slice.actions.setDepartmentData(response?.data?.department_data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const getOneData = (id) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.get(apis.getOne + id);

    dispatch(slice.actions.setCurrentEditData(response.data));
    dispatch(slice.actions.setEmployeeData(response?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

// const getBenefitsDoctor = (data) => async (dispatch) => {
//   try {
//     dispatch(slice.actions.setLoadingBenefit(true));
//     const res = await axios.post(apis.getBenefits, data);
//     dispatch(slice.actions.setBenefitsDoctor(res?.data?.data));
//   } catch (err) {
//     dispatch(slice.actions.setError(err));
//   } finally {
//     dispatch(slice.actions.setLoadingBenefit(false));
//   }
// };

const createData = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    await axios.post(apis.create, data);
    dispatch(fetchAllDoctors());
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const updateData = (data, id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    await axios.put(apis.update + id, data);
    await dispatch(fetchAllDoctors());
    await dispatch(getOneData(id));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const deleteData = (id) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    await axios.delete(apis.delete + id);
    dispatch(fetchAllDoctors());
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const promiseActions = {
  fetchAllDoctors,
  getOneData,
  createData,
  updateData,
  deleteData,
};
// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
