/* eslint-disable import/no-extraneous-dependencies */
import { Button, Dialog, IconButton, Paper, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Iconify from '../components/iconify';

const ConfirmDialog = {
  title: '',
  description: '',
  onConfirm: () => {},
  onCancel: () => {},
};

const WarningDialog = {
  title: '',
  description: '',
  onClose: () => {},
};

const ErrorDialog = {
  title: '',
  description: '',
  onClose: () => {},
};

const SuccessDialog = {
  title: '',
  description: '',
  onClose: () => {},
};

const InitState = {
  confirmDialog: ConfirmDialog,
  setConfirmDialog: (data) => {},
  errorDialog: ErrorDialog,
  setErrorDialog: (data) => {},
  successDialog: SuccessDialog,
  setSuccessDialog: (data) => {},
  warningDialog: WarningDialog,
  setWarningDialog: (data) => {},
};

const context = createContext(InitState);

export const useAlertContext = () => useContext(context);

export const AlertContextProvider = ({ children }) => {
  const snackbar = useSnackbar();
  const [confirmDialog, setConfirmDialog] = useState(null);
  const [successDialog, setSuccessDialog] = useState(null);
  const [errorDialog, setErrorDialog] = useState(null);
  const [warningDialog, setWarningDialog] = useState(null);

  useEffect(() => {
    const handleError = () => {
      const error = sessionStorage.getItem('error');
      if (error) {
        snackbar.enqueueSnackbar(error?.replace('Error:', ''), {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        sessionStorage.removeItem('error');
      }
    };
    window.addEventListener('storage', handleError);
    return () => window.removeEventListener('storage', handleError);
  }, []);

  const value = useMemo(
    () => ({
      confirmDialog,
      setConfirmDialog: (data) => setConfirmDialog(data),
      errorDialog,
      setErrorDialog: (data) => setErrorDialog(data),
      successDialog,
      setSuccessDialog: (data) => setSuccessDialog(data),
      warningDialog,
      setWarningDialog: (data) => setWarningDialog(data),
    }),
    [confirmDialog, errorDialog, successDialog, warningDialog]
  );

  return (
    <context.Provider value={value}>
      <Dialog open={!!confirmDialog} onClose={() => setConfirmDialog(null)}>
        <Paper sx={{ p: 3, minWidth: 280, position: 'relative' }}>
          <IconButton
            sx={{ position: 'absolute', top: 16, right: 16 }}
            onClick={() => {
              setConfirmDialog(null);
            }}
          >
            <Iconify icon="mdi:close" color="grey.500" />
          </IconButton>
          <Stack spacing={2} alignItems="center" justifyContent="center">
            <Iconify
              icon="mdi:alert-circle-outline"
              color="#FFC107"
              sx={{ width: 80, height: 80 }}
            />
            <Typography variant="h6">{confirmDialog?.title}</Typography>
            <Typography variant="body2">{confirmDialog?.description}</Typography>
            <Stack direction="row" spacing={2}>
              <Button variant="outlined" color="inherit" onClick={() => setConfirmDialog(null)}>
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  confirmDialog?.onConfirm();
                  setConfirmDialog(null);
                }}
              >
                Confirm
              </Button>
            </Stack>
          </Stack>
        </Paper>
      </Dialog>
      <Dialog
        open={!!successDialog}
        onClose={() => {
          successDialog?.onClose();
          setSuccessDialog(null);
        }}
      >
        <Paper sx={{ p: 3, minWidth: 280, position: 'relative' }}>
          <IconButton
            sx={{ position: 'absolute', top: 16, right: 16 }}
            onClick={() => {
              successDialog?.onClose();
              setSuccessDialog(null);
            }}
          >
            <Iconify icon="mdi:close" color="grey.500" />
          </IconButton>
          <Stack spacing={2} alignItems="center" justifyContent="center">
            <Iconify
              icon="mdi:check-circle-outline"
              color="success.dark"
              sx={{ width: 80, height: 80 }}
            />
            <Typography variant="h4" color="success.dark">
              {successDialog?.title}
            </Typography>
            <Typography variant="body2" color="grey.700">
              {successDialog?.description}
            </Typography>
            <Button
              variant="outlined"
              onClick={() => {
                successDialog?.onClose();
                setSuccessDialog(null);
              }}
              sx={{ mt: 2 }}
            >
              OK
            </Button>
          </Stack>
        </Paper>
      </Dialog>
      <Dialog
        open={!!errorDialog}
        onClose={() => {
          errorDialog?.onClose();
          setErrorDialog(null);
        }}
      >
        <Paper sx={{ p: 3, minWidth: 280, position: 'relative' }}>
          <IconButton
            sx={{ position: 'absolute', top: 16, right: 16 }}
            onClick={() => {
              errorDialog?.onClose();
              setErrorDialog(null);
            }}
          >
            <Iconify icon="mdi:close" color="grey.500" />
          </IconButton>
          <Stack spacing={2} alignItems="center" justifyContent="center">
            <Iconify
              icon="mdi:alert-circle-outline"
              color="error.dark"
              sx={{ width: 80, height: 80 }}
            />
            <Typography variant="h4" color="error.dark">
              {errorDialog?.title}
            </Typography>
            <Typography variant="body2">{errorDialog?.description}</Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'success.dark',
                color: 'white',
                mt: 2,
                '&:hover': {
                  backgroundColor: 'success.dark',
                  color: 'white',
                  filter: 'brightness(0.9)',
                },
              }}
              onClick={() => {
                errorDialog?.onClose();
                setErrorDialog(null);
              }}
            >
              OK
            </Button>
          </Stack>
        </Paper>
      </Dialog>
      <Dialog
        open={!!warningDialog}
        onClose={() => {
          warningDialog?.onClose();
          setWarningDialog(null);
        }}
      >
        <Paper sx={{ p: 3, minWidth: 280, position: 'relative' }}>
          <IconButton
            sx={{ position: 'absolute', top: 16, right: 16 }}
            onClick={() => {
              warningDialog?.onClose();
              setWarningDialog(null);
            }}
          >
            <Iconify icon="mdi:close" color="grey.500" />
          </IconButton>
          <Stack spacing={2} alignItems="center" justifyContent="center">
            <Iconify icon="ion:warning" color="warning.main" sx={{ width: 80, height: 80 }} />
            <Typography variant="h4" color="warning.main">
              {warningDialog?.title}
            </Typography>
            <Typography variant="body2">{warningDialog?.description}</Typography>
            <Button
              variant="contained"
              // color="success"
              sx={{
                backgroundColor: 'success.dark',
                color: 'white',
                mt: 2,
                '&:hover': {
                  backgroundColor: 'success.dark',
                  color: 'white',
                  filter: 'brightness(0.9)',
                },
              }}
              onClick={() => {
                warningDialog?.onClose();
                setWarningDialog(null);
              }}
            >
              OK
            </Button>
          </Stack>
        </Paper>
      </Dialog>
      {children}
    </context.Provider>
  );
};

AlertContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
