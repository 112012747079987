import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';

import { showError } from '../../../utils/error';

// depthPath is the path to the slice from the root reducer end with a dot (.)
const name = 'patient-visit-treatment';
const depthPath = 'managements.';

const apis = {
  getAllTreatment: '/api-patient/get-treatment-plan-by-visit-id',
  updateTreatment: '/api-patient/update-treatment-plan-by-visit-id',
};

// initialState is the initial state of the slice
const initialState = {
  treatmentData: [],
  updateTreatment: [],
  loading: false,
};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.treatmentData = action.payload;
    },
    setUpdate: (state, action) => {
      state.updateTreatment = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

// promiseActions are the actions that are dispatched by the components
const fetchAllVisitTreatment = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAllTreatment, data);

    console.log(response.data);

    dispatch(slice.actions.setData(response.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

// promiseActions are the actions that are dispatched by the components
const updateVisitTreatment = (data) => async (dispatch, getState) => {
  try {
    await axios.post(apis.updateTreatment, data);
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const promiseActions = {
  updateVisitTreatment,
  fetchAllVisitTreatment,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
