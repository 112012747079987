import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from 'utils/error';

const name = 'doctorSession';
const depthPath = 'managements.';

const apis = {
  getAll: '/api-employee/get-all-doctor-sessions',
  getOne: '/api-employee/get-doctor-session/',
  create: '/api-employee/add-doctor-session',
  update: '/api-employee/get-doctor-session/',
};

const initialState = {
  data: [],
  loading: false,
  currentEditData: null,
  createLoading: false,
  editLoading: false,
  deleteLoading: false,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCreateLoading: (state, action) => {
      state.createLoading = action.payload;
    },
    setEditLoading: (state, action) => {
      state.editLoading = action.payload;
    },
    setDeleteLoading: (state, action) => {
      state.deleteLoading = action.payload;
    },
    setCurrentEditData: (state, action) => {
      state.currentEditData = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

const fetchAllSessions = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const res = await axios.post(apis.getAll, data);
    dispatch(slice.actions.setData(res?.data));
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const getOneSessions = (data, id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const res = await axios.get(apis.getOne + id, data);
    dispatch(slice.actions.setCurrentEditData(res?.data));
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const createSessions = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setCreateLoading(true));
    await axios.post(apis.create, data);
    dispatch(slice.actions.fetchAllSessions());
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setCreateLoading(false));
  }
};

const updateSessions = (data, id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setEditLoading(true));
    await axios.get(apis.update + id, data);
    dispatch(slice.actions.fetchAllSessions());
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setEditLoading(false));
  }
};
const deleteSessions = (data, id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const res = await axios.get(apis.getOne + id, data);
    dispatch(slice.actions.setCurrentEditData(res?.data));
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const promiseActions = {
  fetchAllSessions,
  getOneSessions,
  createSessions,
  updateSessions,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
