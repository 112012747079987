import axios from 'axios';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from 'utils/error';
import { setLocale } from 'yup';

const name = 'financialCashFlow';
const depthPath = 'managements.';

const apis = {
  cashFlow: '/api-finance/get-cash-flows-statement',
};

const initialState = {
  data: [],
  loading: false,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

const fetchCashFlow = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const res = await axios.post(apis.cashFlow, data);
    dispatch(slice.actions.setData(res?.data?.data));
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const promiseActions = {
  fetchCashFlow,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
