import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

const name = 'serviceType';
const depthPath = 'managements.';

const apis = {
  getAll: '/api-medical-setting/get-all-medical-packages',
  add: '/api-medical-setting/add-medical-package',
  delete: '/api-medical-setting/delete-medical-package',
  update: '/api-medical-setting/update-medical-package',
};

const initialState = {
  data: [],
  loading: false,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    addData: (state, action) => {
      state.data = [...state.data, action.payload];
    },
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

const fetchAllServiceType = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAll, data);
    dispatch(slice.actions.setData(response.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};
const createServiceType = (data) => async (dispatch) => {
  try {
    const response = await axios.post(apis.add, data);
    if (response.status === 200) dispatch(slice.actions.setData(response.data?.data));
    return { status: true, message: response?.data?.message };
  } catch (error) {
    return { status: false, message: error.message };
  }
};
const UpdateServiceType = (data, id) => async (dispatch) => {
  try {
    const response = await axios.put(`${apis.update}/${id}`, data);
    if (response.status === 200) dispatch(slice.actions.setData(response.data?.data));
    return { status: true, message: response?.data?.message };
  } catch (error) {
    return { status: false, message: error.message };
  }
};
const deleteServiceType = (data) => async (dispatch) => {
  try {
    const response = await axios.post(apis.delete, data);
    if (response.status === 200) dispatch(slice.actions.setData(response.data?.data));
    return { status: true, message: response?.data?.message };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

const promiseActions = {
  fetchAllServiceType,
  createServiceType,
  UpdateServiceType,
  deleteServiceType,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
