import axios from 'axios';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { getClinic, getWithExpiry, setWithExpiry } from 'utils/config';
import { createSlice, current } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';
import snackbar from 'components/snackbar';
import { json2FormData } from 'utils/formdata';

const name = 'medication';
const depthPath = 'managements.';

const apis = {
  getAll: '/api-pharmacy/get-all-medications',
  getOne: '/api-pharmacy/get-medication',
  // Change from add-edit-medication to edit-medication
  editMedication: '/api-pharmacy/edit-medication',
  addMedicationImage: '/api-file-upload/action-file-upload',
  deleteMedicationImage: '/api-file-upload/delete-file-upload',

  // Medication provider
  addMedicationProvider: '/api-admin/add-medications-to-client',
};

const initialState = {
  data: [],
  loading: false,
  countries: [],
  filter: {
    limit: 25,
    page: 1,
    total_item: 0,
    total_pages: 0,
  },
  open: {
    detail: false,
    delete_medication: false,
    create: false,
  },
  current: {
    id: null,
    data: null,
    loading: false,
    loadingDelete: false,
  },
  dosage_data_form: [],

  // add medication provider:
  medication_provider: null,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setDosageFormData: (state, action) => {
      state.dosage_data_form = action.payload;
    },
    setMedicationProvider: (state, action) => {
      state.medication_provider = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    setCountries: (state, action) => {
      state.countries = action.payload;
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
    setCurrentData: (state, action) => {
      state.current.data = action.payload;
    },
    setCurrentId: (state, action) => {
      state.current.id = action.payload;
    },
    setCurrentLoading: (state, action) => {
      state.current.loading = action.payload;
    },
    setOpen: (state, action) => {
      const { target, value } = action.payload;
      state.open[target] = value;
    },
    setDeleteLoading: (state, action) => {
      state.current.loadingDelete = action.payload;
    },
    setImageCurrentData: (state, action) => {
      state.current.data = { ...state.current.data, image_data: action.payload };
    },
    removeImageFromCurrentData: (state, action) => {
      const imageId = action.payload;
      if (state.current.data && state.current.data.image_data) {
        state.current.data.image_data = state.current.data.image_data.filter(
          (image) => image.id !== imageId
        );
      }
    },
    // HANLDE UPDATE FIELD VALUE WHEN ADD NEW ITEM
    onChangeField: (state, action) => {
      const old_data = current(state.data);

      const { id, field_name, val } = action.payload;

      const new_data = old_data?.map((item) => {
        if (item?.id === id) {
          return { ...item, [field_name]: val };
        }
        return item;
      });
      state.data = new_data;
    },
    onDeleteForm: (state, action) => {
      const id = action.payload;

      const old_data = current(state.data);

      const new_data = old_data?.filter((item) => item?.id !== id);

      state.data = new_data;
    },
    removeItemById: (state, action) => {
      const old_data = current(state.data);
      const id = action.payload;

      const new_data = old_data?.filter((item) => item?.id !== id);
      state.data = new_data;
    },
  },
});

const fetchAllMedications = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAll, data);
    const resData = response.data;
    const filter = {
      total_item: resData?.result,
      total_pages: resData?.all_pages,
      page: data.get('page'),
      limit: data.get('limit'),
    };
    // console.log('filter:', filter);
    dispatch(slice.actions.setData(resData?.data));
    // console.log(resData);
    dispatch(slice.actions.setDosageFormData(resData?.dosage_form_data || []));
    dispatch(slice.actions.setFilter(filter));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

// Search Medicaiton
const searchMedication = (data) => async (dispatch) => {
  try {
    const response = await axios.post(apis.getAll, data);


    console.log(response.data);
    // dispatch(slice.actions.setData(response?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const addMedicationProvider = (newData) => async (dispatch, getState) => {
  try {
    const currentState = getState();
    const currentData = currentState.managements.medication.data || [];
    const updatedData = [newData, ...currentData];
    dispatch(slice.actions.setData(updatedData));

    console.log(newData);
    dispatch(slice.actions.setMedicationProvider(newData));

    await axios.post(
      `${apis.addMedicationProvider}`,
      json2FormData({
        medication_id: newData?.id || 0,
      })
    );
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setCurrentLoading(false));
  }
};

const fetchOneMedication = (id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setCurrentLoading(true));
    const response = await axios.get(`${apis.getOne}/${id}`);
    dispatch(slice.actions.setCurrentData(response.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setCurrentLoading(false));
  }
};

// Add updateMedication async function
const updateMedication = (data, id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setCurrentLoading(true));
    console.log(data);
    // const response = await axios.post(`${apis.update}/${medication.id}`, medication.data);
    const response = await axios.put(`${apis.editMedication}/${id}`, data);
    dispatch(slice.actions.setCurrentData(response.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
    // Handle error accordingly
  } finally {
    dispatch(slice.actions.setCurrentLoading(false));
  }
};

// Upload Medication Image async function
const uploadMedicationImage =
  ({ files, objectId }) =>
  async (dispatch) => {
    try {
      dispatch(slice.actions.setDeleteLoading(true));
      const clinicId = getClinic();
      const formData = new FormData();
      formData.append('clinic_id', clinicId.id);
      formData.append('object_id', objectId);
      formData.append('object_type', 'MEDICATION');

      files.forEach((file) => {
        formData.append('files', file);
      });

      const response = await axios.post(apis.addMedicationImage, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch(slice.actions.setImageCurrentData(response.data?.data));
    } catch (error) {
      console.error('Failed to upload medication image:', error);
      dispatch(slice.actions.setError(error));
    } finally {
      dispatch(slice.actions.setDeleteLoading(false));
    }
  };

// Delete Medication Image async function
const deleteMedicationImage = (imageId) => async (dispatch) => {
  try {
    dispatch(slice.actions.setDeleteLoading(true));
    const response = await axios.delete(`${apis.deleteMedicationImage}/${imageId}`);
    if (response.status === 200 || response.status === 204) {
      dispatch(slice.actions.removeImageFromCurrentData(imageId));
    } else {
      console.error('Unexpected response:', response.data);
    }
  } catch (error) {
    console.error('Failed to delete medication image:', error);
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setDeleteLoading(false));
  }
};

const addEditMedication = (data) => async (dispatch) => {
  let res;
  try {
    res = await axios.post('api-pharmacy/add-medication', data);
    if (res.status === 200 || res.status === 204) {
      const resData = res.data?.data?.map((item) => ({ ...item, id: item?.medication_id }));
      dispatch(slice.actions.setData(resData));
    }
  } catch (error) {
    console.error('Failed to delete medication image:', error);
    res = error;
  }
  return res;
};

const addMultipleMedication = (data) => async (dispatch) => {
  let res;
  try {
    res = await axios.post('api-pharmacy/add-medication', data);
    // if (res.status === 200 || res.status === 204) {
    //   const resData = res.data?.data?.map((item) => ({ ...item, id: item?.medication_id }));
    //   dispatch(slice.actions.setData(resData));
    // }
  } catch (error) {
    res = error;
  }
  return res;
};

const getAllCountry = (data) => async (dispatch) => {
  let res;
  const cache = getWithExpiry('medicalSetting');
  try {
    if(cache){
      res = cache;
      const list = res?.data?.data?.find(
        (item) => item?.name_latin?.toString()?.toLowerCase() === 'medication country'
      );
      dispatch(slice.actions.setCountries(list?.child || []));

    }else{
      res = await axios.post('api-medical-setting/get-all-medical-settings');
      if (res.status === 200 || res.status === 204) {
        const list = res?.data?.data?.find(
          (item) => item?.name_latin?.toString()?.toLowerCase() === 'medication country'
        );
        setWithExpiry('medicalSetting', res.data?.data, 43200000)
        dispatch(slice.actions.setCountries(list?.child || []));
      }
      
    }
  } catch (error) {
    res = error;
  }
  return res;
};

const deleteMedication = (id) => async (dispatch) => {
  let res;
  try {
    res = await axios.delete(`api-pharmacy/delete-medication/${id}`);
    if (res.status === 200 || res.status === 204) {
      dispatch(slice.actions.removeItemById(id));
      dispatch(slice.actions.setOpen({ target: 'delete_medication', value: false }));
    }
  } catch (error) {
    res = error;
  }
  return res;
};

const promiseActions = {
  deleteMedication,
  addMultipleMedication,
  getAllCountry,
  fetchAllMedications,
  fetchOneMedication,
  updateMedication,
  deleteMedicationImage,
  uploadMedicationImage,
  addEditMedication,
  searchMedication,

  addMedicationProvider,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
