import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { dispatch } from 'redux/store';
// @mui
import { Collapse } from '@mui/material';
// hooks
import useActiveLink from '../../../hooks/useActiveLink';
//
import NavItem from './NavItem';

// ----------------------------------------------------------------------

NavList.propTypes = {
  data: PropTypes.object,
  depth: PropTypes.number,
  hasChild: PropTypes.bool,
};

export default function NavList({ data, depth, hasChild }) {
  const { pathname } = useLocation();

  const { active, isExternalLink, parentActive } = useActiveLink(data.url);

  const [open, setOpen] = useState(active);
  const [openParent, setOpenParent] = useState(parentActive);

  useEffect(() => {
    if (!active && !parentActive) {
      handleClose();
    }
    if (parentActive) {
      setOpenParent(true);
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleToggle = () => {
    setOpen(!open);
    setOpenParent(!openParent);
    if (!hasChild) localStorage.setItem('selected_menu', JSON.stringify(data));
  };

  const handleClose = () => {
    setOpen(false);
    setOpenParent(false);
  };

  return (
    <>
      <NavItem
        item={data}
        depth={depth}
        open={open || openParent}
        active={active}
        isExternalLink={isExternalLink}
        onClick={handleToggle}
      />
      {hasChild && (
        <Collapse in={open || openParent} unmountOnExit>
          <NavSubList data={data.child} depth={depth} />
        </Collapse>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

NavSubList.propTypes = {
  data: PropTypes.array,
  depth: PropTypes.number,
};

function NavSubList({ data, depth }) {
  return (
    <>
      {data.map((list) => (
        <NavList
          key={list.title + list.url}
          data={list}
          depth={depth + 1}
          hasChild={!!list.child}
        />
      ))}
    </>
  );
}
