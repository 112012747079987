import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { json2FormData } from 'utils/formdata';
import { showError } from '../../../utils/error';

// depthPath is the path to the slice from the root reducer end with a dot (.)
const name = 'global-result-investigation';
const depthPath = 'managements.';

// initialState is the initial state of the slice
const initialState = {
  comingResult: false,
  openLabor: false,
  openImaging: false,
};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setAlertResult: (state, action) => {
      state.comingResult = action.payload;
    },
    setOpenLabor: (state, action) => {
      state.openLabor = action.payload;
    },
    setOpenImaging: (state, action) => {
      state.openImaging = action.payload;
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },

    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

const receiveResult = () => async (dispatch, getState) => {
  dispatch(slice.actions.setStatus(true));
};
const openLabarBox = (data) => async (dispatch, getState) => {
  dispatch(slice.actions.setOpenLabor(data));
};
const openImagingBox = (data) => async (dispatch, getState) => {
  dispatch(slice.actions.setOpenImaging(data));
};

const promiseActions = {
  receiveResult,
  openLabarBox,
  openImagingBox
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
