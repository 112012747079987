import React, { useState } from 'react';
import { useLocales } from 'locales';

// @mui
import { MenuItem, Stack, Typography } from '@mui/material';

// components
import Iconify from './iconify';
import MenuPopover from './menu-popover/MenuPopover';

import Image from './image';

// ----------------------------------------------------------------------

export default function CustomLanguage() {
  const [openPopover, setOpenPopover] = useState(null);
  const { allLangs, currentLang, onChangeLang } = useLocales();

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleChangeLang = (newLang) => {
    onChangeLang(newLang);
    handleClosePopover();
  };

  return (
    <>
      <Stack
        direction="row"
        // Comment to Disbale Change Language
        // onClick={handleOpenPopover}
        justifyContent="space-between"
        sx={{ p: 1.5, border: 1, borderColor: '#e9ecee', borderRadius: 1, cursor: 'pointer' }}
      >
        <Typography>{currentLang?.label}</Typography>
        <Iconify icon="jam:world" sx={{ color: '#637381' }} />
      </Stack>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 180 }}>
        <Stack spacing={0.75}>
          {allLangs.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => handleChangeLang(option.value)}
            >
              <Image
                disabledEffect
                alt={option.label}
                src={option.icon}
                sx={{ width: 28, mr: 2 }}
              />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
