import PropTypes from 'prop-types';
// @mui
import { List, Stack } from '@mui/material';
// locales
//
import { orderBy } from 'lodash';
import NavList from './NavList';
import { useAuthContext } from '../../../auth/useAuthContext';

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  sx: PropTypes.object,
};

export default function NavSectionVertical({ sx, ...other }) {
  const { user } = useAuthContext();
  const accessModules = user?.user_access_module;


  console.log(accessModules);

  return (
    <Stack sx={sx} {...other}>
      <List disablePadding sx={{ px: 0 }}>

        {orderBy(accessModules, ['sortkey'], ['asc'])?.map((list) => (
          <NavList key={list.title + list.path} data={list} depth={1} hasChild={!!list.child} />
        ))}
      </List>
    </Stack>
  );
}
