import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

// depthPath is the path to the slice from the root reducer end with a dot (.)
const name = 'patientPrintHistory';
const depthPath = 'managements.';

const apis = {
  profile: 'api-patient/print-patient-profile',
  vital_sign: '/api-patient/print-patient-vital-sign',
  medical: '/api-patient/print-patient-medical-history',
  vaccination: '/api-patient/print-patient-vaccination',
  prescription: '/api-patient/print-patient-prescription',
  observation_review: '/api-patient/print-patient-observation-review',
  appointment: '/api-patient/print-patient-appointment',
  physical_examination :'api-patient/print-patient-physical-examination',
};

// initialState is the initial state of the slice
const initialState = {
  data: null,
  loading: false,
  vital_data: null,
  medical_data: null,
  physical_data: null,
  prescription_data: null,
  vaccination_data: null,
  appointment_data: null,

};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setVitalData: (state, action) => {
      state.vital_data = action.payload;
    },
    setMedicalData: (state, action) => {
      state.medical_data = action.payload;
    },
    setPhysicalData: (state, action) => {
      state.physical_data = action.payload;
    },
    setPrescription: (state, action) => {
      state.prescription_data = action.payload;
    },
    setVaccination: (state, action) => {
      state.vaccination_data = action.payload;
    },
    setAppointment: (state, action) => {
      state.appointment_data = action.payload;
    },
   
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});


// promiseActions are the actions that are dispatched by the components
const fetchProfilePrint = (data) => async (dispatch, getState) => {
    // if (getState().history.vitalData) return;
    try {
      dispatch(slice.actions.setLoading(true));
      const response = await axios.post(apis.profile, data);
      dispatch(slice.actions.setData(response?.data?.data));
    } catch (error) {
      dispatch(slice.actions.setError(error));
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };

// promiseActions are the actions that are dispatched by the components
const fetchVitalSignPrint = (data) => async (dispatch, getState) => {
  // if (getState().history.vitalData) return;

  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.vital_sign, data);
    dispatch(slice.actions.setVitalData(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchAllMedicalPrint = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.medical, data);
    dispatch(slice.actions.setMedicalData(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchPhysicalPrint = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.physical_examination, data);
    dispatch(slice.actions.setPhysicalData(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchPrescriptionPrint = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.prescription, data);
    dispatch(slice.actions.setPrescription(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchVaccinationPrint = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.vaccination, data);
    dispatch(slice.actions.setVaccination(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchAppointmentPrint = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.appointment, data);
    dispatch(slice.actions.setAppointment(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};


const promiseActions = {
  fetchVitalSignPrint,
  fetchProfilePrint,
  fetchAllMedicalPrint,
  fetchPhysicalPrint,
  fetchPrescriptionPrint,
  fetchVaccinationPrint,
  fetchAppointmentPrint,

};
// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
