// @mui
import { enUS, khKH, viVN } from '@mui/material/locale';
import { useEffect } from 'react';
import { getClinic } from 'utils/config';

// Define a function to get available languages based on clinic's country language
const getAvailableLangs = (countryLanguage) => {
  const languages = [
    {
      label: 'English',
      value: 'en',
      systemValue: enUS,
      icon: '/assets/icons/flags/ic_flag_en.svg',
    },
    {
      label: 'Khmer',
      value: 'kh',
      systemValue: khKH,
      icon: '/assets/icons/flags/ic_flag_kh2.svg',
    },
    {
      label: 'Vietnamese',
      value: 'vn',
      systemValue: viVN,
      icon: '/assets/icons/flags/ic_flag_vn.svg',
    },
  ];

  switch (countryLanguage) {
    case 'VN':
      return languages.filter((lang) => lang.value === 'en' || lang.value === 'vn');
    case 'KH':
      return languages.filter((lang) => lang.value === 'en' || lang.value === 'kh');
    default:
      return languages;
  }
};

const clinic = getClinic();

console.log(clinic);

export const allLangs = getAvailableLangs(clinic?.country_language);
export const defaultLang = allLangs[0] || {
  label: 'English',
  value: 'en',
  systemValue: enUS,
  icon: '/assets/icons/flags/ic_flag_en.svg',
};
