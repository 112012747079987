function path(root, sublink) {
  return `${root}${sublink}`;
}

export const ROOTS_AUTH = '/auth';
export const ROOTS_DASHBOARD = `/main`;

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboard: path(ROOTS_DASHBOARD, '/dashboard'),
    health_care_management: {
      patient_management: {
        visit_list: path(ROOTS_DASHBOARD, '/health-care-management/patient-management/visit-list'),
        patient_list: path(
          ROOTS_DASHBOARD,
          '/health-care-management/patient-management/patient-list'
        ),
        appointment_list: path(
          ROOTS_DASHBOARD,
          '/health-care-management/patient-management/appointment-list'
        ),
        prescription_list: path(
          ROOTS_DASHBOARD,
          '/health-care-management/patient-management/prescription-list'
        ),
        in_patient_department: path(
          ROOTS_DASHBOARD,
          '/health-care-management/patient-management/in-patient-department'
        ),
      },
      bill_management: {
        list_bills: path(ROOTS_DASHBOARD, '/health-care-management/bill-management/list-bills'),
        list_fee_service_packages: path(
          ROOTS_DASHBOARD,
          '/health-care-management/bill-management/list-fee-service-packages'
        ),
      },
    },

    employee_management: {
      employee_list: path(ROOTS_DASHBOARD, '/employee-management/employee-list'),
      nurse_list: path(ROOTS_DASHBOARD, '/employee-management/nurse-list'),
      admin_staff_list: path(ROOTS_DASHBOARD, '/employee-management/admin-staff-list'),
      attendance_list: path(ROOTS_DASHBOARD, '/employee-management/attendance-list'),
    },

    pharmacy_management: {
      medication_search: path(ROOTS_DASHBOARD, '/pharmacy-management/medication-search'),
      stock: path(ROOTS_DASHBOARD, '/pharmacy-management/stock'),
      order: path(ROOTS_DASHBOARD, '/pharmacy-management/order'),
    },

    financial_management: {
      income: path(ROOTS_DASHBOARD, '/financial-management/income'),
      expenditure: path(ROOTS_DASHBOARD, '/financial-management/expenditure'),
    },

    user_management: {
      system_user: path(ROOTS_DASHBOARD, '/user-management/system-user'),
      user_access_module: path(ROOTS_DASHBOARD, '/user-management/user-access-module'),
    },

    health_care_setting: {
      list_diagnoses: path(ROOTS_DASHBOARD, '/health-care-setting/list-diagnoses'),
      list_specilist_types: path(ROOTS_DASHBOARD, '/health-care-setting/specialist-types'),
    },
    // profile_setting: path(ROOTS_DASHBOARD, '/profile-settings'),
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
