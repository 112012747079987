import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

// depthPath is the path to the slice from the root reducer end with a dot (.)
const name = 'expense';
const depthPath = 'managements.';

const apis = {
  getOne: '/api-finance/get-expense',
  getAll: '/api-finance/get-all-expenses',
  add: '/api-finance/add-expense',
  update: '/api-finance/update-expense/',
  /// Endpoint not found
  delete: '/api-finance/delete-expense',
};

// initialState is the initial state of the slice
const initialState = {
  data: [],
  loading: false,
  addLoading: false,
  editLoading: false,
  statusData: [],
  expenseTypeData: [],
  supplierData: [],
  paymentAccountData: [],
  currentData: null,
  openDialog: {
    create: false,
    edit: false,
    delete: false,
  },
};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setStatusData: (state, action) => {
      state.statusData = action.payload;
    },
    setExpenseTypeData: (state, action) => {
      state.expenseTypeData = action.payload;
    },
    setPaymentAccountData: (state, action) => {
      state.paymentAccountData = action.payload;
    },
    setSupplierData: (state, action) => {
      state.supplierData = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAddLoading: (state, action) => {
      state.addLoading = action.payload;
    },
    setEditLoading: (state, action) => {
      state.editLoading = action.payload;
    },
    setCurrentData: (state, action) => {
      state.currentData = action.payload;
    },
    setOpenDialog: (state, action) => {
      const { target, value } = action.payload;
      state.openDialog[target] = value;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

const fetchAllExpenses = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAll, data);

    dispatch(slice.actions.setData(response?.data?.data));
    dispatch(slice.actions.setStatusData(response?.data?.expense_status_data));
    dispatch(slice.actions.setExpenseTypeData(response?.data?.expense_type_data));
    dispatch(slice.actions.setPaymentAccountData(response?.data?.payment_account_data));
    dispatch(slice.actions.setSupplierData(response?.data?.supplier_data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};
const addEditExpense = (data) => async (dispatch) => {
  try {
    const response = await axios.post(apis.add_edit, data);
    if (response.status === 200) dispatch(slice.actions.setData(response.data?.data));
    return { status: true, message: response?.data?.message };
  } catch (error) {
    return { status: false, message: error.message };
  }
};
const addExpense = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setAddLoading(true));
    const res = await axios.post(apis.add, data);
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setAddLoading(false));
  }
};

const editExpense = (id, data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setEditLoading(true));
    const res = await axios.put(apis.update + id, data);
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setEditLoading(false));
  }
};
const deleteExpense = (id) => async (dispatch) => {
  try {
    const response = await axios.delete(`${apis.delete}/${id}`);
    if (response.status === 200) dispatch(slice.actions.setData(response.data?.data));
    return { status: true, message: response?.data?.message };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

const promiseActions = {
  fetchAllExpenses,
  // addEditExpense,
  addExpense,
  editExpense,
  deleteExpense,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
