// routes
// components
import SvgColor from '../../../components/svg-color';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  dashboard: icon('ic_dashboard'),
  information: <Iconify icon="solar:document-bold-duotone" />,
  patientList: <Iconify icon="fluent:patient-32-regular" />,
  visitList: <Iconify icon="material-symbols:patient-list-rounded" />,
};

const navConfig = [
  {
    subheader: '',
    items: [
      { title: 'dashboard', path: '/main/app', icon: ICONS.dashboard },
      {
        title: 'Health Care Management',
        path: '/main/health-care-management',
        icon: <Iconify icon="iconoir:healthcare" />,
        children: [
          {
            title: 'Patient Management',
            path: '/main/health-care-management/patient-management',
            children: [
              {
                title: 'Visit List',
                path: '/main/health-care-management/patient-management/visit-list',
              },
              {
                title: 'Patient List',
                path: '/main/health-care-management/patient-management/patient-list',
              },
              {
                title: 'Appointment List',
                path: '/main/health-care-management/patient-management/appointment-list',
              },
              {
                title: 'Prescription List',
                path: '/main/health-care-management/patient-management/prescription-list',
              },
              {
                title: 'In Patient Department',
                path: '/main/health-care-management/patient-management/in-patient-department',
              },
            ],
          },
          {
            title: 'Bill Management',
            path: '/main/health-care-management/bill-management',
            children: [
              {
                title: 'List Bills',
                path: '/main/health-care-management/bill-management/list-bills',
              },
              {
                title: 'List Fee Service Packages',
                path: '/main/health-care-management/bill-management/list-fee-service-packages',
              },
            ],
          },
        ],
      },
      {
        title: 'Employee Management',
        path: '/main/employee-management',
        icon: <Iconify icon="mdi:user-tie" />,
        children: [
          {
            title: 'Doctor List',
            path: '/main/employee-management/doctor-list',
          },
          {
            title: 'Nurse List',
            path: '/main/employee-management/nurse-list',
          },
          {
            title: 'Admin Staff List',
            path: '/main/employee-management/admin-staff-list',
          },
          {
            title: 'Attendance List',
            path: '/main/employee-management/attendance-list',
          },
        ],
      },
      {
        title: 'Pharmacy Managements',
        path: '/main/pharmacy-management',
        icon: <Iconify icon="iconoir:pharmacy-cross-tag" />,
        children: [
          {
            title: 'Medication Search',
            path: '/main/pharmacy-management/medication-search',
          },
          {
            title: 'Stock',
            path: '/main/pharmacy-management/stock',
          },
          {
            title: 'Order',
            path: '/main/pharmacy-management/order',
          },
        ],
      },
      {
        title: 'Financial Management',
        path: '/main/financial-management',
        icon: <Iconify icon="solar:dollar-bold" />,
        children: [
          {
            title: 'Income',
            path: '/main/financial-management/income',
          },
          {
            title: 'Expenditure',
            path: '/main/financial-management/expenditure',
          },
        ],
      },
      {
        title: 'User Management',
        path: '/main/user-management',
        icon: <Iconify icon="mdi:user" />,
        children: [
          {
            title: 'System User',
            path: '/main/user-management/system-user',
          },
          {
            title: 'User Access Module',
            path: '/main/user-management/user-access-module',
          },
        ],
      },
      {
        title: 'Health Care Setting',
        path: '/main/health-care-setting',
        icon: <Iconify icon="solar:health-bold" />,
        children: [
          {
            title: 'List Diagnoses',
            path: '/main/health-care-setting/list-diagnoses',
          },
          {
            title: 'List Specialist Types',
            path: '/main/health-care-setting/list-specialist-types',
          },
        ],
      },
      {
        title: 'System Setting',
        path: '/main/system-setting',
        icon: <Iconify icon="uil:setting" />,
        children: [
          {
            title: 'List Branches',
            path: '/main/system-setting/list-branches',
          },
          {
            title: 'List Clinics',
            path: '/main/system-setting/list-clinics',
          },
          {
            title: 'List Payment Methodes',
            path: '/main/system-setting/list-payment-methodes',
          },
          {
            title: 'List Banks',
            path: '/main/system-setting/list-banks',
          },
        ],
      },
    ],
  },
];

export default navConfig;
