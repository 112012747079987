import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

const name = 'userList';
const depthPath = 'managements.';

const apis = {
  getAll: '/api-user/get-all-users',
  getAccessModule: '/api-user/get-all-user-access-modules',
  actionAccessModule: '/api-user/action-assign-user-to-access-modules',
  assignUserToEmployee: '/api-user/assign-user-to-employee',
  delete: '/api-user/delete-user',
  create: '/api-user/create-user',
};

const initialState = {
  data: [],
  loading: false,
  deleting: false,
  openAssign: false,
  openCreateUser: false,
  deleteUserData: null,
  accessModule: [],
  currentData: {},
  accessModuleLoading: false,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setOpenCreateUser: (state, action) => {
      state.openCreateUser = action.payload;
    },
    setDeleteUserData: (state, action) => {
      state.deleteUserData = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setDeleting: (state, action) => {
      state.deleting = action.payload;
    },
    setAccessModule: (state, action) => {
      state.accessModule = action.payload;
    },
    setOpenAssign: (state, action) => {
      state.openAssign = action.payload;
    },
    setCurrentData: (state, action) => {
      state.currentData = action.payload;
    },
    setAccessModuleLoading: (state, action) => {
      state.accessModuleLoading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

const fetchAllUser = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAll, data);
    dispatch(slice.actions.setData(response.data?.data?.map((item) => ({ ...item }))));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};
const deleteUser = (data) => async (dispatch) => {
  dispatch(slice.actions.setDeleting(true));
  try {
    const response = await axios.post(apis.delete, data);
    dispatch(slice.actions.setData(response.data?.data?.map((item) => ({ ...item }))));
    dispatch(slice.actions.setDeleteUserData(null));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setDeleting(false));
  }
};
const createUser = (data) => async (dispatch) => {
  let response = null;
  dispatch(slice.actions.setDeleting(true));
  try {
    response = await axios.post(apis.create, data);
  } catch (error) {
    response = error;
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setDeleting(false));
  }
  return response;
};
const fetchAccessModule = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setAccessModuleLoading(true));
    const response = await axios.post(apis.getAccessModule, data);
    dispatch(slice.actions.setAccessModule(response.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setAccessModuleLoading(false));
  }
};
const actionAccessModule = (data) => async (dispatch) => {
  try {
    const response = await axios.post(apis.actionAccessModule, data);
    // dispatch(slice.actions.setAccessModule(response.data?.data));
    return { status: true, message: response.data?.message };
  } catch (error) {
    return { status: false, message: error?.message };
  }
};
const assignUserToEmployee = (data) => async (dispatch) => {
  try {
    const response = await axios.post(apis.assignUserToEmployee, data);
    return { status: true, message: response.data?.message };
  } catch (error) {
    return { status: false, message: error?.message };
  }
};

const promiseActions = {
  fetchAllUser,
  fetchAccessModule,
  actionAccessModule,
  assignUserToEmployee,
  deleteUser,
  createUser,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
