import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { get } from 'lodash';
import { EVETNS } from 'pages/main/visit-management/appointment-management/list/next-appointment-calendar/constants';
import { format } from 'date-fns';
import { showError } from '../../../utils/error';

// ===========================
// depthPath is the path to the slice from the root reducer end with a dot (.)
const depthPath = 'managements.';
const name = 'appointment';
const apis = {
  getOne: '/api-patient/get-appointment/',
  getAll: '/api-patient/get-all-appointment',
  create: '/api-patient/add-appointment',
  update: '/api-patient/update-appointment/',
  delete: '/api-patient/delete-appointment',
};

// ===========================
// initialState is the initial state of the slice
const initialState = {
  data: [],
  loading: false,
  openCreateDialog: false,
  currentEditData: null,
  currentDeleteData: null,
  calendarView: 'month',
  calendarViewDate: new Date(),
  calendarViewDays: [],
  calendarData: [],
  calendarLoading: false,
};

// ===========================
// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
    setOpenCreateDialog: (state, action) => {
      state.openCreateDialog = action.payload;
    },
    setCurrentEditData: (state, action) => {
      state.currentEditData = action.payload;
    },
    setCurrentDeleteData: (state, action) => {
      state.currentDeleteData = action.payload;
    },
    setCalendarView: (state, action) => {
      state.calendarView = action.payload;
    },
    setCalendarViewDate: (state, action) => {
      state.calendarViewDate = action.payload;
    },
    setCalendarViewDays: (state, action) => {
      state.calendarViewDays = action.payload;
    },
    // CALENDAR
    setCalendarLoading: (state, action) => {
      state.calendarLoading = action.payload;
    },
    pushNewAppointment: (state, action) => {
      const item = action.payload;
      console.log('item:', item);
      const newItem = {
        ...item,
        date: item?.appointment_date,
        day: format(new Date(item?.appointment_date), 'dd'),
      };
      state.calendarData = [...state.calendarData, newItem];
    },
    setCalendarData: (state, action) => {
      const data = action.payload;
      const fData = data?.map((item) => ({
        ...item,
        date: item?.appointment_date,
        day: format(new Date(item?.appointment_date), 'dd'),
      }));
      state.calendarData = fData;
    },
  },
});

// ===========================
// promiseActions are the actions that are dispatched by the components
const fetchData = (data) => async (dispatch, getState) => {
  try {
    const response = await axios.post(apis.getAll, data);
    dispatch(
      slice.actions.setData(
        response.data?.data?.map((item) => ({ ...item, id: item?.appointment_id }))
      )
    );
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const createData = (data) => async (dispatch, getState) => {
  try {
    await axios.post(apis.create, data);
    dispatch(fetchData());
  } catch (error) {
    dispatch(slice.actions.setError(error));
    throw error;
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const updateData = (data) => async (dispatch, getState) => {
  try {
    await axios.put(`${apis.update}${data?.id}`, data);
    dispatch(fetchData());
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const deleteData = (data) => async (dispatch, getState) => {
  try {
    await axios.post(apis.delete, data);
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

// HANDLE GET DATA FOR CALEDNDAR
const getDataForCalendar = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setCalendarLoading(true));
    const response = await axios.post('/api-patient/get-all-patient-appointments', data);
    dispatch(slice.actions.setCalendarData(response.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setCalendarLoading(false));
  }
};

const addEditAppointment = (data) => async (dispatch, getState) => {
  try {
    const endpoint = data.get('patient_appointment_id')
      ? '/api-patient/update-patient-appointment'
      : '/api-patient/add-patient-appointment';
    await axios.post(endpoint, data);
    // const response = await axios.post(endpoint, data);
    // dispatch(slice.actions.setCalendarData(response.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setCalendarLoading(false));
  }
};
const deleteAppointment = (data) => async (dispatch, getState) => {
  try {
    const response = await axios.post('/api-patient/delete-patient-appointment', data);
    dispatch(slice.actions.setCalendarData(response.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setCalendarLoading(false));
  }
};

// ===========================
// export
const promiseActions = {
  fetchData,
  createData,
  updateData,
  deleteData,
  getDataForCalendar,
  addEditAppointment,
  deleteAppointment,
};
// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
