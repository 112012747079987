import { Button, Stack } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import visitListSlice from 'redux/slices/managements/visit-list';
import globalResult from 'redux/slices/managements/global-result';
import laborResultOverlay from 'redux/slices/managements/labor-result-overlay';
import { dispatch } from 'redux/store';
import ipd from 'redux/slices/managements/ipd';
import { json2FormData } from 'utils/formdata';

export const columns = [
  // { field: 'no', headerName: 'No', width: 70 },
  {
    field: 'patient_info',
    headerName: 'Patient Info',
    // width: 150,

    flex: 1,
    renderCell: (params) => (
      <Stack direction="column" spacing={0}>
        <span>{params.value.fullname}</span>
        <span style={{ fontSize: '11', color: '#828282' }}>{params.value.fullname_latin}</span>
        <span style={{ fontSize: '11', color: '#828282' }}>
          {params.value.gender === 2 ? 'Female' : 'Male'}
        </span>
        <span style={{ fontSize: '11', color: '#828282' }}>{params.value.personal_card_id}</span>
      </Stack>
    ),
  },
  {
    field: 'investigation_type',
    align: 'center',
    headerAlign: 'center',
    headerName: 'Investigation Type',
    // width: 120,
    flex: 1,
  },
  {
    field: 'is_seen',
    headerName: 'Attachment',
    sortable: false,
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    renderCell: (params) => (
      <Button
        size="small"
        variant={params.value === 0 ? 'outlined' : 'contained'}
        startIcon={<InfoOutlinedIcon fontSize="small" />}
        onClick={() => {
          if (params.row.investigation_type === 'OPD') {
            dispatch(globalResult.promiseActions.openLabarBox(false));
            dispatch(visitListSlice.actions.setCurrentViewPatientId(params?.row?.patient_visit_id));
            dispatch(laborResultOverlay.actions.setOpenInves(true));
            dispatch(
              laborResultOverlay.promiseActions.seenResult(
                json2FormData({
                  patient_investigation_id: params.row?.patient_investigation_id,
                  object_type: 'LABORATORY',
                })
              )
            );
          } else if (params.row.investigation_type === 'IPD') {
            dispatch(globalResult.promiseActions.openLabarBox(false));
            dispatch(ipd.actions.setCurrentViewPatientId(params?.row?.patient_current_ipd_id));
            console.log(params?.row?.patient_current_ipd_id);
            dispatch(laborResultOverlay.actions.setOpenInvesIPD(true));

            console.log(params.row?.patient_investigation_id);
            dispatch(
              laborResultOverlay.promiseActions.seenResult(
                json2FormData({
                  patient_investigation_id: params.row?.patient_investigation_id,
                  object_type: 'LABORATORY',
                })
              )
            );
          }
        }}
      >
        {params.value === 0 ? 'Open' : 'Opened'}
      </Button>
    ),
  },
];
