import { Badge, Button, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// Redux
import { dispatch } from 'redux/store';
import globalResult from 'redux/slices/managements/global-result';
import investigationApp from 'redux/slices/managements/investigation-socket-app';

// Components and Utils
import Iconify from 'components/iconify';

export default function ToolbarImagingResult({ viewType = 1, count = 0 }) {
  const { individualImagingSocket } = investigationApp.selector();
  const { openImaging } = globalResult.selector();
  const [resultComing, setResultComing] = useState(false);

  const handleOpenResult = () => {
    dispatch(globalResult.promiseActions.openImagingBox(true));
    dispatch(investigationApp.actions.setIndividualImagingSocket(false));
  };

  useEffect(() => {
    if (individualImagingSocket === true) {
      setResultComing(individualImagingSocket);
      startCloseTimer();
    }
  }, [individualImagingSocket]);

  const startCloseTimer = () => {
    setTimeout(() => {
      setResultComing(false);
      dispatch(investigationApp.actions.setIndividualImagingSocket(false));
    }, 30000); // 30 seconds
  };

  return (
    <Stack
      sx={{
        transition: 'all 0.4s ease-in-out',
        height: '40px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px',
        boxShadow: 6,
        cursor: 'pointer',
        backgroundColor: 'white',
        opacity: openImaging ? 0 : 1,
        zIndex: openImaging ? -1 : 2000,
      }}
    >
      <Badge
        badgeContent={count}
        color="error"
        sx={{
          '& .MuiBadge-badge': {
            mt: -0.5,
            mr: -0.5,
          },
        }}
      >
        <Button
          sx={{
            p: 2,
            height: '40px',
            backgroundColor: resultComing ? '#FF8B00' : 'transparent',
            color: resultComing ? 'white' : 'primary',
            border: '1px solid',
            transition: 'background-color 0.1s ease-in-out',
            '@keyframes blink': {
              '30%': { backgroundColor: 'primary' },
              '50%': { backgroundColor: 'transparent' },
              '100%': { backgroundColor: 'primary' },
            },
            animation: resultComing ? 'blink 1s infinite' : 'none',
          }}
          size="small"
          onClick={handleOpenResult}
          variant={resultComing ? 'contained' : 'outlined'}
        >
          <Iconify icon="medical-icon:laboratory" />
        </Button>
      </Badge>
    </Stack>
  );
}

ToolbarImagingResult.propTypes = {
  viewType: PropTypes.number,
  count: PropTypes.number,
};
