import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

// depthPath is the path to the slice from the root reducer end with a dot (.)
const name = 'orderList';
const depthPath = 'managements.';

const apis = {
  getOne: '/api-pharmacy/get-order/',
  getAll: '/api-pharmacy/get-all-stoks',
  create: '/api-pharmacy/add-order',
  update: '/api-pharmacy/update-order-detail/',
};

// initialState is the initial state of the slice
const initialState = {
  data: [],
  loading: false,
  open: {
    detail: false,
  },
  current: {
    id: null,
    data: null,
    loading: false,
  },
};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
    setCurrentData: (state, action) => {
      console.log(action.payload);
      state.current.data = action.payload;
    },
    setCurrentId: (state, action) => {
      state.current.id = action.payload;
    },
    setCurrentLoading: (state, action) => {
      state.current.loading = action.payload;
    },
    setOpen: (state, action) => {
      const { target, value } = action.payload;
      state.open[target] = value;
    },
  },
});

// promiseActions are the actions that are dispatched by the components
const fetchAllStock = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAll, data);
    dispatch(slice.actions.setData(response.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const getOneData = (id) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.get(apis.getOne + id);
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const createOrder = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    await axios.post(apis.create, data);
    dispatch(fetchAllStock());
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const updateOrder = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    await axios.put(`${apis.update}${data?.id}`, data);
    dispatch(fetchAllStock());
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const promiseActions = {
  getOneData,
  createOrder,
  updateOrder,
  fetchAllStock,
};
// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
