import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';
import { json2FormData } from '../../../utils/formdata';
import { getWithExpiry, setWithExpiry } from 'utils/config';

const name = 'medicalSetting';
const depthPath = 'settings.';

const apis = {
  getAll: '/api-medical-setting/get-all-medical-settings',
  getInvestigationByObjType: '/api-medical-setting/get-investigation-by-object-type',
  getAllServiceType: '/api-medical-setting/get-all-service-types',
  getAllProcedureType: '/api-medical-setting/get-all-procedure-types',
  getAllLaboTestSheet: '/api-medical-setting/get-all-procedure-types',
  add_edit: '/api-medical-setting/add-edit-medical-setting',
  delete: '/api-medical-setting/delete-medical-setting',
};

const initialState = {
  data: [],
  loading: false,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

const fetchAllMedicalSetting = (clinic_id) => async (dispatch) => {
  try {
    const cache = getWithExpiry('medicalSetting');
    if(cache){
      dispatch(slice.actions.setData(cache));
      
    }else{
      
      dispatch(slice.actions.setLoading(true));
      const response = await axios.post(apis.getAll, {
        clinic_id,
      });
      setWithExpiry('medicalSetting', response.data?.data, 43200000)
      dispatch(slice.actions.setData(response.data?.data));
    }
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchInvestigationByObjType = (object_type) => async (dispatch) => {
  try {
    const body = json2FormData({ object_type });
    const response = await axios.post(apis.getInvestigationByObjType, body);
    return response.data?.data;
  } catch (error) {
    dispatch(slice.actions.setError(error));
    return null;
  }
};

const fetchServiceType = (object_type) => async (dispatch) => {
  try {
    const body = json2FormData({ object_type });
    const response = await axios.post(apis.getAllServiceType, body);
    return response.data?.data;
  } catch (error) {
    dispatch(slice.actions.setError(error));
    return null;
  }
};

const fetchProcedureType = (object_type) => async (dispatch) => {
  try {
    const body = json2FormData({ object_type });
    const response = await axios.post(apis.getAllProcedureType, body);
    return response.data?.data;
  } catch (error) {
    dispatch(slice.actions.setError(error));
    return null;
  }
};

const fetchLaboTestSheet = (object_type) => async (dispatch) => {
  try {
    const body = json2FormData({ object_type });
    const response = await axios.post(apis.getAllLaboTestSheet, body);
    return response.data?.data;
  } catch (error) {
    dispatch(slice.actions.setError(error));
    return null;
  }
};

const addEditMedicalSetting = (data) => async (dispatch) => {
  try {
    const response = await axios.post(apis.add_edit, data);
    if (response.status === 200) dispatch(slice.actions.setData(response.data?.data));
    return { status: true, message: response?.data?.message };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

const deleteMedicalSetting = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`${apis.delete}/${id}`);
    if (response.status === 200) dispatch(slice.actions.setData(response.data?.data));
    return { status: true, message: response?.data?.message };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

const promiseActions = {
  fetchAllMedicalSetting,
  addEditMedicalSetting,
  deleteMedicalSetting,
  fetchInvestigationByObjType,
  fetchServiceType,
  fetchProcedureType,
  fetchLaboTestSheet,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
