import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

const name = 'laborTestResult';
const depthPath = 'managements.';

const apis = {
  getAll: '/api-laboratory/get-all-labor-test-results',
  add: '/api-laboratory/add-labor-test-template',
  edit: '/api-laboratory/edit-labor-test-template',
  delete: '/api-laboratory/delete-labor-test-template',
  getOne: '/api-laboratory/get-labor-test-template',
};

const initialState = {
  data: [],
  loading: false,
  requested_date: null,
  option: {
    UNIT_DATA: null,
    REFERENCE_RANGE: null,
  },
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setOption: (state, action) => {
      const { unit, reference } = action.payload;
      state.option.UNIT_DATA = unit;
      state.option.REFERENCE_RANGE = reference;
    },
    setRequestedDate: (state, action) => {
      state.requested_date = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

const fecthAlllaborTestResult = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAll, data);
    dispatch(slice.actions.setData(response.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};
const createlaborTemplet = (data, add_child) => async (dispatch) => {
  try {
    const response = await axios.post(apis.add, data);
    if (response.status === 200) {
      if (!add_child) dispatch(slice.actions.setData(response.data?.data));
      else {
        const res = await axios.post(apis.getAll, null);
        dispatch(slice.actions.setData(res.data?.data));
      }
    }
    return { status: true, message: response?.data?.message };
  } catch (error) {
    return { status: false, message: error.message };
  }
};
const updatelaborTemplet = (data, id) => async (dispatch) => {
  try {
    const response = await axios.put(`${apis.edit}/${id}`, data);
    if (response.status === 200) {
      const res = await axios.post(apis.getAll, null);
      dispatch(slice.actions.setData(res.data?.data));
    }
    return { status: true, message: response?.data?.message };
  } catch (error) {
    return { status: true, message: error?.data?.message };
  }
};
const deletelaborTemplet = (id) => async (dispatch) => {
  try {
    const response = await axios.delete(`${apis.delete}/${id}`);
    if (response.status === 200) dispatch(slice.actions.setData(response.data?.data));
    return { status: true, message: response?.data?.message };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

const promiseActions = {
  fecthAlllaborTestResult,
  createlaborTemplet,
  updatelaborTemplet,
  deletelaborTemplet,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
