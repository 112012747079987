import { alpha } from '@mui/system';

export const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const SETTINGS = {
  column_width: '8%',
  column_height: '120px',
};

export const EVETNS = [
  {
    id: 1,
    day: 2,
    date: new Date(),
    events: [
      {
        id: 1,
        title: 'Delicious Recipes for a Vegan Diet',
        Description: 'Event One Description',
        start_time: new Date(),
        end_time: new Date().getDate() + 1,
        color: alpha('rgb(255, 171, 0)', 1),
        backgroundColor: alpha('rgb(255, 171, 0)', 0.2),
      },
      {
        id: 2,
        title: 'Event Title Two',
        Description: 'Event Two Description',
        start_time: new Date(),
        end_time: new Date().getDate() + 1,
        color: alpha('rgb(34, 197, 94)', 1),
        backgroundColor: alpha('rgb(34, 197, 94)', 0.2),
      },
      {
        id: 3,
        title: 'Delicious Recipes for a Vegan Diet',
        Description: 'Event One Description',
        start_time: new Date(),
        end_time: new Date().getDate() + 1,
        color: alpha('rgb(255, 171, 0)', 1),
        backgroundColor: alpha('rgb(255, 171, 0)', 0.2),
      },
      {
        id: 4,
        title: 'Event Title Two',
        Description: 'Event Two Description',
        start_time: new Date(),
        end_time: new Date().getDate() + 1,
        color: alpha('rgb(34, 197, 94)', 1),
        backgroundColor: alpha('rgb(34, 197, 94)', 0.2),
      },
    ],
  },
  {
    id: 2,
    day: 3,
    date: new Date(),
    events: [
      {
        id: 1,
        title: 'Delicious Recipes for a Vegan Diet',
        Description: 'Event One Description',
        start_time: new Date(),
        end_time: new Date().getDate() + 1,
        color: alpha('rgb(255, 171, 0)', 1),
        backgroundColor: alpha('rgb(255, 171, 0)', 0.2),
      },
      {
        id: 2,
        title: 'Event Title Two',
        Description: 'Event Two Description',
        start_time: new Date(),
        end_time: new Date().getDate() + 1,
        color: alpha('rgb(34, 197, 94)', 1),
        backgroundColor: alpha('rgb(34, 197, 94)', 0.2),
      },
      {
        id: 3,
        title: 'Delicious Recipes for a Vegan Diet',
        Description: 'Event One Description',
        start_time: new Date(),
        end_time: new Date().getDate() + 1,
        color: alpha('rgb(255, 171, 0)', 1),
        backgroundColor: alpha('rgb(255, 171, 0)', 0.2),
      },
      {
        id: 4,
        title: 'Event Title Two',
        Description: 'Event Two Description',
        start_time: new Date(),
        end_time: new Date().getDate() + 1,
        color: alpha('rgb(34, 197, 94)', 1),
        backgroundColor: alpha('rgb(34, 197, 94)', 0.2),
      },
    ],
  },
];
