import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Stack,
  Box,
  TextField,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  CardContent,
} from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { DatePicker } from '@mui/x-date-pickers';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Scrollbar from 'components/scrollbar';
import ResponsiveDrawer from 'components/drawer/reponsive_drawer';
import MedicalHeader from 'pages/main/header_medical_setting';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { json2FormData } from 'utils/formdata';
import { useLocales } from 'locales';

export default function ProfileSettings({ handleClick, handleClose }) {

  const { translate, currentLang } = useLocales();
  const {enqueueSnackbar} = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [profileData,setProfileData] = useState();

  const getUserLoginData = async () => {
    setLoading(true);
    try {
      const res = await axios.post('/api-user/get-my-login-profile');
      setData(res?.data?.data || {});
      setProfileData(res?.data?.data?.profile_data);
      console.log(res.data);
      console.log(res);

    } catch (error) {
      console.log('error:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUserLoginData();
  }, []);

  const initialValues = useMemo(
    () => ({
      username: data?.username || '',
      lastname: profileData?.lastname || '',
      lastname_latin: profileData?.lastname_latin || '',
      firstname: profileData?.firstname || '',
      firstname_latin: profileData?.firstname_latin || '',
      gender: profileData?.gender || '',
      phone: profileData?.phone || '',
      email: profileData?.email || '',
      address: profileData?.address || '',
      date_of_birth: profileData?.date_of_birth || '',
    }),
    [data]
  );

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    loading: true,
    onSubmit: async (values) => {
      console.log('Form submitted with values:', values);
      const res = await axios.post('/api-user/update-my-login-profile', json2FormData(values));
      enqueueSnackbar(translate('YOUR_DATA_HAS_BEEN_SAVED_SUCCESSFULLY'),{variant:'success'})
    },
  });

  return (
    <ResponsiveDrawer
      open={handleClick}
      onClose={handleClose}
      drawerWidth="40%"
      body={
        <Scrollbar sx={{ px: 1 }}>
          <form onSubmit={formik.handleSubmit}>
          <MedicalHeader onClose={handleClose} />
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="profile-settings-content"
              id="profile-settings-header"
            >
              <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                {translate('USER_INFORMATION')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <CardContent>
                  <Stack>
                    <Typography variant="subtitle1">{translate('USER_NAME')}</Typography>
                    <TextField
                      value={formik.values.username}
                      onChange={(e) => formik.setFieldValue('username', e.target.value)}
                    />
                  </Stack>
              </CardContent>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="profile-settings-content"
              id="profile-settings-header"
            >
              <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                {translate('PERSONAL_INFORMATION')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <CardContent>
                  <Stack sx={{my:2}}>
                    <Typography variant="subtitle1">{translate('LASTNAME')}</Typography>
                    <TextField
                      value={formik.values.lastname}
                      onChange={(e) => formik.setFieldValue('lastname', e.target.value)}
                    />
                  </Stack>
                  <Stack sx={{my:2}}>
                    <Typography variant="subtitle1">{translate('FIRSTNAME')}</Typography>
                    <TextField
                      value={formik.values.firstname}
                      onChange={(e) => formik.setFieldValue('firstname', e.target.value)}
                    />
                  </Stack>
                  <Stack sx={{my:2}}>
                    <Typography variant="subtitle1">{translate('LASTNAME_LATIN')}</Typography>
                    <TextField
                      value={formik.values.lastname_latin}
                      onChange={(e) => formik.setFieldValue('lastname_latin', e.target.value)}
                    />
                  </Stack>
                  <Stack sx={{my:2}}>
                    <Typography variant="subtitle1">{translate('FIRSTNAME_LATIN')}</Typography>
                    <TextField
                      value={formik.values.firstname_latin}
                      onChange={(e) => formik.setFieldValue('firstname_latin', e.target.value)}
                    />
                  </Stack>

                  <Stack sx={{my:2}}>
                    <Typography variant="subtitle1">{translate('DATE_OF_BIRTH')}</Typography>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      placeholder={translate('DATE_OF_BIRTH')}
                      value={formik.values.date_of_birth}
                      onChange={(value) => formik.setFieldValue('date_of_birth', value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          fullWidth
                          name="date_of_birth"
                          error={Boolean(formik.errors.date_of_birth)}
                          helperText={formik.errors.date_of_birth}
                        />
                      )}
                    />
                  </Stack>

                  <Stack sx={{my:2}}>
                    <FormControl
                      component="fieldset"
                      error={formik.touched.gender && Boolean(formik.errors.gender)}
                    >
                      <Typography variant="subtitle1">{translate('GENDER')}</Typography>
                      <FormGroup row sx={{ mt: 2 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formik?.values?.gender === '0'}
                              onChange={(e) =>
                                formik?.setFieldValue('gender', e.target.checked ? '0' : '')
                              }
                            />
                          }
                          label={translate('MALE')}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formik?.values?.gender === '1'}
                              onChange={(e) =>
                                formik?.setFieldValue('gender', e.target.checked ? '1' : '')
                              }
                            />
                          }
                          label={translate('FEMALE')}
                        />
                      </FormGroup>
                      {formik.touched.gender && formik.errors.gender && (
                        <FormHelperText>{formik.errors.gender}</FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                  <Stack sx={{my:2}}>
                    <Typography variant="subtitle1">{translate('PHONE')}</Typography>
                    <TextField
                      value={formik.values.phone}
                      onChange={(e) => formik.setFieldValue('phone', e.target.value)}
                    />
                  </Stack>
                  <Stack sx={{my:2}}>
                    <Typography variant="subtitle1">{translate('EMAIL')}</Typography>
                    <TextField
                      value={formik.values.email}
                      onChange={(e) => formik.setFieldValue('email', e.target.value)}
                    />
                  </Stack>
              </CardContent>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="profile-settings-content"
              id="profile-settings-header"
            >
              <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                {translate('ADDRESS_INFORMATION')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <CardContent>
                  <Stack>
                    <Typography variant="subtitle1">{translate('ADDRESS')}</Typography>
                    <TextField
                      value={formik.values.address}
                      onChange={(e) => formik.setFieldValue('address', e.target.value)}
                    />
                  </Stack>
              </CardContent>
            </AccordionDetails>
          </Accordion>
          {/* Save Button */}
          <Stack sx={{ display: 'flex', justifyContent:'end',pt: 2,flexDirection:'row' }}>
            <LoadingButton variant="contained" sx={{mr:1}} onClick={handleClose} color="error">
              {translate('CANCEL')}
            </LoadingButton>
            <LoadingButton variant="contained" type="submit">
              {translate('SAVE')}
            </LoadingButton>
          </Stack>
        </form>
        </Scrollbar>
      }
    />
  );
}

ProfileSettings.propTypes = {
  handleClick: PropTypes.bool,
  handleClose: PropTypes.func,
};
