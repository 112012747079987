import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';

import { showError } from '../../../utils/error';

const name = 'toVisitFromPatientList';
const depthPath = 'managements.';

const apis = {
  getEmployeeAll: '/api-employee/search-employee',
  getAllDepartment: '/api-medical-setting/get-medical-setting-by-object-type',
  registration: '/api-patient/add-patient-visit-from-patient-list',
};

const initialState = {
  employeeData: [],
  departmentData: [],
  adata: [], // Apply Data response back

  aLoading: false, // Add Loading
  eLoading: false, // Employee Loading
  dLoading: false, // Department Loading
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.employeeData = action.payload;
    },
    setDepartmentData: (state, action) => {
      state.departmentData = action.payload;
    },
    setApplyData: (state, action) => {
      state.adata = action.payload;
    },
    setALoading: (state, action) => {
      state.aLoading = action.payload;
    },
    setELoading: (state, action) => {
      state.eLoading = action.payload;
    },
    setDLoading: (state, action) => {
      state.dLoading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
    setDError: (state, action) => {
      showError(action.payload);
    },
  },
});

const submitRegistration = (data) => async (dispatch) => {
  let res = null;
  try {
    dispatch(slice.actions.setALoading(true));
    res = await axios.post(apis.registration, data);
    if (res.status === 200) {
      console.log('datatata', res);
      dispatch(slice.actions.setApplyData(res?.data));
    }
  } catch (error) {
    dispatch(slice.actions.setError(error));
    res = error;
  } finally {
    dispatch(slice.actions.setALoading(false));
  }
  return res;
};

const fetchAllEmployee = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setELoading(true));
    const response = await axios.post(apis.getEmployeeAll, data);
    console.log(response);
    if (response.status === 200) {
      const childData = response?.data?.data?.map((item, index) => ({
        ...item,
        index,
      }));
      dispatch(slice.actions.setData(childData));
    }
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setELoading(false));
  }
};

const fetchAllDepartment = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setELoading(true));
    const response = await axios.post(apis.getAllDepartment, data);

    // Display Index in Datagrid
    if (response.status === 200) {
      const childs = response?.data?.data?.child?.map((item, index) => ({ ...item, index }));
      dispatch(slice.actions.setDepartmentData({ ...response?.data?.data, child: childs }));
    }
  } catch (error) {
    dispatch(slice.actions.setDError(error));
  } finally {
    dispatch(slice.actions.setDLoading(false));
  }
};

const promiseActions = {
  fetchAllEmployee,
  fetchAllDepartment,
  submitRegistration,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
