import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice, current } from '@reduxjs/toolkit';

import { showError } from '../../../utils/error';

// depthPath is the path to the slice from the root reducer end with a dot (.)
const name = 'voice';
const depthPath = 'managements.';

// initialState is the initial state of the slice
const initialState = {
  loading: false,
  data: null,
  error: null,
  obj_has_alert: {},
};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      const data = action.payload;
      const list_voice_by_object_type = [];
      data?.forEach((item) => {
        list_voice_by_object_type.push(...(item?.child ?? []));
      });
      state.data = list_voice_by_object_type;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
    updateVoiceDataByObjectType: (state, action) => {
      const data = current(state.data);

      const { object_type, new_data } = action.payload;
      const newList = data?.map((item) => {
        if (item?.object_type === object_type) {
          return { ...item, data: new_data };
        }
        return item;
      });
      state.data = newList;
      state.obj_has_alert[`${object_type}`] = true;
    },
    removeVoice: (state, action) => {
      const data = current(state.data);
      const { object_type, id } = action.payload;

      const newList = data?.map((item) => {
        if (item?.object_type === object_type) {
          const newChild = item?.data?.filter((voice) => voice.id !== id);
          return { ...item, data: newChild };
        }
        return item;
      });

      state.data = newList;
    },
    updateObjHasUpdate: (state, action) => {
      const { object_type, status } = action.payload;
      state.obj_has_alert[`${object_type}`] = status;
    },
  },
});

const getAllVoice = (data) => async (dispatch, getState) => {
  let res = null;
  try {
    res = await axios.post('/api-patient/get-patient-visit-voice', data);
    if (res?.status === 200) {
      dispatch(slice.actions.setData(res?.data?.data?.data));
    }
  } catch (error) {
    dispatch(slice.actions.setError(error));
    res = error;
  }
  return res;
};

const onDeleteVoice = (object_type, id) => async (dispatch) => {
  let res = null;
  try {
    res = await axios.delete(`/api-file-upload/delete-file-upload/${id}`);
    if (res?.status === 200) {
      dispatch(slice.actions.removeVoice({ object_type, id }));
    }
  } catch (error) {
    dispatch(slice.actions.setError(error));
    res = error;
  }
  return res;
};

const promiseActions = {
  getAllVoice,
  onDeleteVoice,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
