import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

// depthPath is the path to the slice from the root reducer end with a dot (.)
const name = 'patientHistory';
const depthPath = 'managements.';

const apis = {
  get_vital_sign: '/api-patient/get-vital-sign-history-logs',
  get_medical: '/api-patient/get-medical-history-logs',
  get_physical: '/api-patient/get-physical-examination-history-logs',
  get_prescription: '/api-patient/get-prescription-history-logs',
  get_vaccination: '/api-patient/get-vaccination-history-logs',
  get_appointment: '/api-patient/get-appointment-history-logs',
  get_labor: '/api-laboratory/get-laboratory-test-result-logs',
  get_imaging: '/api-laboratory/get-imaging-result-logs',
  get_note: 'api-patient/get-note-history-logs',
  get_medical_certificate: '/api-patient/get-medical-certificate-logs',
};

// initialState is the initial state of the slice
const initialState = {
  data: [],
  loading: false,
  vital_data: null,
  medical_data: null,
  physical_data: null,
  prescription_data: null,
  vaccination_data: null,
  appointment_data: null,
  labor_data: null,
  imaging_data: null,
  note_data: null,
  medical_certificate: null,
};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setVitalData: (state, action) => {
      state.vital_data = action.payload;
    },
    setMedicalData: (state, action) => {
      state.medical_data = action.payload;
    },
    setPhysicalData: (state, action) => {
      state.physical_data = action.payload;
    },
    setPrescription: (state, action) => {
      state.prescription_data = action.payload;
    },
    setVaccination: (state, action) => {
      state.vaccination_data = action.payload;
    },
    setAppointment: (state, action) => {
      state.appointment_data = action.payload;
    },
    setLabor: (state, action) => {
      state.labor_data = action.payload;
    },
    setImaging: (state, action) => {
      state.imaging_data = action.payload;
    },
    setNote: (state, action) => {
      state.note_data = action.payload;
    },
    setMedicalCertificate: (state, action) => {
      state.medical_certificate = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

// promiseActions are the actions that are dispatched by the components
const fetchVitalSignHistory = (data) => async (dispatch, getState) => {
  // if (getState().history.vitalData) return;

  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.get_vital_sign, data);
    dispatch(slice.actions.setVitalData(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchAllMedicalHistory = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.get_medical, data);
    dispatch(slice.actions.setMedicalData(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchPhysicalHistory = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.get_physical, data);
    dispatch(slice.actions.setPhysicalData(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchPrescriptionHistory = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.get_prescription, data);
    dispatch(slice.actions.setPrescription(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchVaccinationHistory = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.get_vaccination, data);
    dispatch(slice.actions.setVaccination(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchAppointmentHistory = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.get_appointment, data);
    dispatch(slice.actions.setAppointment(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchLaborHistory = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.get_labor, data);
    dispatch(slice.actions.setLabor(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchImagingHistory = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.get_imaging, data);
    dispatch(slice.actions.setImaging(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchNoteHistory = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.get_note, data);
    dispatch(slice.actions.setNote(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchMedicalCertificateHistory = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.get_medical_certificate, data);
    console.log('response', response);
    dispatch(slice.actions.setMedicalCertificate(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const promiseActions = {
  fetchVitalSignHistory,
  fetchAllMedicalHistory,
  fetchPhysicalHistory,
  fetchPrescriptionHistory,
  fetchVaccinationHistory,
  fetchAppointmentHistory,
  fetchLaborHistory,
  fetchImagingHistory,
  fetchNoteHistory,
  fetchMedicalCertificateHistory,
};
// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
