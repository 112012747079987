import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from '@mui/material/Drawer';
import Slide from '@mui/material/Slide';
import MedicalHeader from 'pages/main/header_medical_setting';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="right" ref={ref} {...props} />
));

function ResponsiveDrawer({ open, handleClose, body, drawerWidth = '30%' }) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const isLg = useMediaQuery(theme.breakpoints.down('lg'));

  // let drawerWidth = '30%';
  if (isXs) {
    drawerWidth = '100%';
  } else if (isSm) {
    drawerWidth = '80%';
  } else if (isMd) {
    drawerWidth = '60%';
  } else if (isLg) {
    drawerWidth = '50%';
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      PaperProps={{ sx: { width: drawerWidth, zIndex: 2005, padding: 2 } }}
    >
      {/* <MedicalHeader onClose={handleClose} /> */}
      {body}
    </Drawer>
  );
}

ResponsiveDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  body: PropTypes.node,
  drawerWidth: PropTypes.string,
};

export default ResponsiveDrawer;
