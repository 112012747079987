import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

// depthPath is the path to the slice from the root reducer end with a dot (.)
const name = 'financialList';
const depthPath = 'managements.';

const apis = {
  getAll: '/api-finance/get-billing-statement',
  getAllIncome: '/api-finance/get-all-incomes',
  getIncomeType: '/api-finance/get-all-income-types',
  useActionIncomeType: '/api-finance/action-use-income-type',

  // Expense
  getAllExpense: '/api-finance/get-all-expenses',
  getExpenseType: '/api-finance/get-all-expense-types',
  addExpese: '/api-finance/add-expense',
  addItemExpense: '/api-finance/add-item-expense',
  doctor_income: '/api-finance/get-doctor-income',
  useActionExpenseType: '/api-finance/action-use-expense-type',
  deleteIncome: '/api-finance/delete-income',
  // Add Delete Expense
  deleteExpense: '/api-finance/delete-expense',

  // Payment Type
};

// initialState is the initial state of the slice
const initialState = {
  data: [],

  // Income
  incomeData: [],
  incomeType: [],
  openDrawer: {
    create: false,
    edit: false,
    delete: false,
  },
  doctor_income: [],

  // Expense
  expenseData: [],
  expenseType: [],
  openExpenseDrawer: {
    create: false,
    edit: false,
    delete: false,
  },
  paymentType: [],

  supplierType: [],

  singleIncomeData: null,
  // Add SingleExpense
  singleExpenseData: null,
  loading: false,
  addLoading: false,

  deleteLoading: false,
};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },

    // Income
    setIncomeData: (state, action) => {
      state.incomeData = action.payload;
    },

    setDoctorIncome: (state, action) => {
      state.doctor_income = action.payload;
    },
    setIncomeType: (state, action) => {
      state.incomeType = action.payload;
    },
    setSingleIncomeData: (state, action) => {
      state.singleIncomeData = action.payload;
    },
    setOpenDrawer: (state, action) => {
      const { target, value } = action.payload;
      state.openDrawer[target] = value;
    },

    // Expense
    setExpenseData: (state, action) => {
      state.expenseData = action.payload;
    },
    setExpenseType: (state, action) => {
      state.expenseType = action.payload;
    },
    // Add SingleExpenseData
    setSingleExpenseData: (state, action) => {
      state.singleExpenseData = action.payload;
    },

    setOpenExpenseDrawer: (state, action) => {
      const { target, value } = action.payload;
      state.openExpenseDrawer[target] = value;
    },

    setPaymentType: (state, action) => {
      state.paymentType = action.payload;
    },

    setSupplierType: (state, action) => {
      state.supplierType = action.payload;
    },

    // Global
    setCloseDrawer: (state, action) => {
      const { target, value } = action.payload;
      state.openDrawer[target] = value;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAddLoading: (state, actoin) => {
      state.addLoading = actoin.payload;
    },
    setDeleteLoading: (state, action) => {
      state.deleteLoading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

const onGetBillStatement = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const res = await axios.post(apis.getAll, data);
    console.log(res.data);
    dispatch(slice.actions.setData(res?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const onDoctorIncome = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const res = await axios.post(apis.doctor_income, data);
    dispatch(slice.actions.setDoctorIncome(res?.data));
    console.log(res.data);
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const onGetAllIncome = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const res = await axios.post(apis.getAllIncome, data);
    dispatch(slice.actions.setIncomeData(res?.data));
    console.log(res.data);
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const onGetIncomeType = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const res = await axios.post(apis.getIncomeType);
    dispatch(slice.actions.setIncomeType(res?.data));
    console.log(res.data);
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const onUseActionIncome = (data) => async (dispatch) => {
  try {
    const res = await axios.post(apis.useActionIncomeType, data);

    console.log(res.data);
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const onUseActionExpense = (data) => async (dispatch) => {
  try {
    const res = await axios.post(apis.useActionExpenseType, data);

    console.log(res.data);
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

// Expense
const onGetAllExpense =
  (isLoading = true) =>
  async (dispatch) => {
    try {
      if (isLoading) {
        dispatch(slice.actions.setLoading(true));
      }
      const res = await axios.post(apis.getAllExpense);
      dispatch(slice.actions.setExpenseData(res?.data));

      console.log(res?.data);
      dispatch(slice.actions.setPaymentType(res.data?.payment_account_data));
      dispatch(slice.actions.setExpenseType(res?.data?.expense_type_data));
      dispatch(slice.actions.setSupplierType(res?.data?.supplier_data));

      dispatch(slice.actions.setLoading(false));
      if (!isLoading) {
        console.log(res?.data.data[0]);
        dispatch(slice.actions.setSingleExpenseData(res?.data.data[0]));
      }
    } catch (error) {
      dispatch(slice.actions.setError(error));
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };

const onAddExpense = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setAddLoading(true));
    const res = await axios.post(apis.addExpese, data);

    console.log(res.data);
    dispatch(slice.actions.setAddLoading(false));
    dispatch(onGetAllExpense(false));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setAddLoading(false));
  }
};

const onAddItemExpense = (data) => async (dispatch) => {
  try {
    // dispatch(slice.actions.setAddLoading(true));
    const res = await axios.post(apis.addItemExpense, data);
    console.log(res.data);
    // console.log(res.data);
    // dispatch(slice.actions.setAddLoading(false));
    // dispatch(onGetAllExpense(false));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setAddLoading(false));
  }
};

const onGetExpenseType = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const res = await axios.post(apis.getExpenseType);
    dispatch(slice.actions.setExpenseType(res?.data));
    console.log('resdata', res.data);
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const onDeletIncome = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setDeleteLoading(true));
    const res = await axios.post(apis.deleteIncome, data);

    console.log(res.data);
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setDeleteLoading(false));
  }
};

const onDeleteExpense = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setDeleteLoading(true));
    const res = await axios.post(apis.deleteExpense, data);

    console.log(res.data);
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setDeleteLoading(false));
  }
};

const promiseActions = {
  onDoctorIncome,
  onGetBillStatement,
  onGetAllIncome,
  onGetIncomeType,
  onDeletIncome,
  onDeleteExpense,
  onUseActionIncome,
  // Expense
  onGetAllExpense,
  onAddExpense,
  onGetExpenseType,
  onUseActionExpense,
  onAddItemExpense,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
