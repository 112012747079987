import axios from 'axios';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
// import { getClinic } from 'utils/config';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

const name = 'medical-equipment';
const depthPath = 'managements.';

const apis = {
  getAll: '/api-medical-equipment/get-all-medical-equipment-stocks',
  getListEquipment: '/api-medical-equipment/get-all-medical-equipments',
  getOne: '/api-medical-equipment/get-medical-equipment',
  addEquipmentStock: 'api-medical-equipment/set-medical-equipment-to-stock',
  updateEquipmentStock: 'api-medical-equipment/update-medical-equipment-stock',

  getListRequestMainEquipment: '/api-medical-equipment/get-all-medical-equipment-main-requests',
  addRequestMainEquipment: 'api-medical-equipment/add-medical-equipment-main-requests',
  // approveRequestMainEquipment: 'api-medical-equipment/get-approve-medical-equipment-main-requests',

  getListRequestEquipment: '/api-medical-equipment/get-all-medical-equipment-requests',
  getListEmployess: '/api-employee/get-all-employees',
  addRequestEquipment: 'api-medical-equipment/add-medical-equipment-request',
  deleteRequestEquipment: 'api-medical-equipment/delete-medical-equipment-request',
};

const initialState = {
  loading: false,
  data: [],
  equipments: [],
  requestEquipments: [],
  listEnployees: [],
  filter: {
    limit: 25,
    page: 1,
    total_item: 0,
    total_pages: 0,
  },
  is_return: '0',
  open: {
    openStockEquipment: false,
    openDrawer: false,
    openDeleteDialog: false,
    openDrawerEdit: false,
    openAddRequestEquipment: false,
    openComfirmApprove: false,
  },
  current: {},
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setCurrent: (state, action) => {
      state.current = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    setEquipments: (state, action) => {
      state.equipments = action.payload;
    },
    updateEquipments: (state, action) => {
      if (state.equipments.length <= 0) {
        return;
      }
      const index = state.equipments.findIndex(
        (v) => v.medical_equipment_id === action.payload.medical_equipment_id
      );
      if (index) {
        const equipments = [...state.equipments];
        equipments[index].is_in_stock = action.payload.is_in_stock ? 1 : 0;
        state.equipments = [...equipments];
      }
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
    setOpen: (state, action) => {
      const { target, value } = action.payload;
      state.open[target] = value;
    },
    setIsReturn: (state, action) => {
      state.is_return = action.payload;
    },
    setRequestEquipment: (state, action) => {
      state.requestEquipments = action.payload;
    },
    setListEnployees: (state, action) => {
      state.listEnployees = action.payload;
    },
  },
});

const fetchAllMedicalEquipment = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAll, data);
    const resData = response.data;
    const filter = {
      total_item: resData?.result,
      total_pages: resData?.all_pages,
      page: resData?.page,
      limit: resData?.limit,
    };
    // console.log('filter:', filter);
    const d = resData?.data.map((v) => {
      v.id = v.medical_equipment_stock_id;
      return v;
    });
    dispatch(slice.actions.setData(d));
    dispatch(slice.actions.setFilter(filter));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const getAllEquipment = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getListEquipment, data);
    if (response.status === 200 || response.response === 204) {
      const resData = response.data;
      const d = resData?.data.map((v) => {
        v.id = v.medical_equipment_id;
        return v;
      });
      dispatch(slice.actions.setEquipments(d));
    }
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchOneMedicalEquipment = (id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    dispatch(slice.actions.setCurrentLoading(true));
    const response = await axios.get(`${apis.getOne}/${id}`);
    dispatch(slice.actions.setCurrentData(response.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setCurrentLoading(false));
  }
};

const addEquipmentStock = (data) => async (dispatch) => {
  try {
    // dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.addEquipmentStock, data);
    if (response.status === 200 || response.response === 204) {
      dispatch(slice.actions.updateEquipments(data));

      const res = await axios.post(apis.getAll, data);
      const resData = res.data;
      const filter = {
        total_item: resData?.result,
        total_pages: resData?.all_pages,
        page: resData?.page,
        limit: resData?.limit,
      };
      // console.log('filter:', filter);
      const d = resData?.data.map((v) => {
        v.id = v.medical_equipment_stock_id;
        return v;
      });
      dispatch(slice.actions.setData(d));
      dispatch(slice.actions.setFilter(filter));
    }

    // console.log('it is response:', response);
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    // dispatch(slice.actions.setLoading(false));
  }
};

const editEquipmentStock = (data) => async (dispatch) => {
  try {
    // dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.updateEquipmentStock, data);
    if (response.status === 200 || response.response === 204) {
      const resData = response.data;
      const filter = {
        total_item: resData?.result,
        total_pages: resData?.all_pages,
        page: resData?.page,
        limit: resData?.limit,
      };
      // console.log('filter:', filter);
      const d = resData?.data.map((v) => {
        v.id = v.medical_equipment_stock_id;
        return v;
      });
      dispatch(slice.actions.setData(d));
      dispatch(slice.actions.setFilter(filter));
    }
    // console.log('it is response:', response);
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    // dispatch(slice.actions.setLoading(false));
  }
};

/// medical equipment request stocks
const fetchAllEquipmentRequest = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getListRequestMainEquipment, data);
    const resData = response.data;
    // const d = resData?.data.map((v) => {
    //   v.id = v.medical_equipment_request_id;
    //   return v;
    // });
    const filter = {
      total_item: resData?.result,
      total_pages: resData?.all_pages,
      page: resData?.page,
      limit: resData?.limit,
    };
    dispatch(slice.actions.setRequestEquipment(resData.data));
    dispatch(slice.actions.setFilter(filter));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchListEmployees = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getListEmployess, data);
    const resData = response.data;
    dispatch(slice.actions.setListEnployees(resData.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const addRequestEquipmentStock = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.addRequestMainEquipment, data);
    if (response.status === 200 || response.response === 204) {
      const resData = response.data;
      // const d = resData?.data.map((v) => {
      //   v.id = v.medical_equipment_request_id;
      //   return v;
      // });
      const filter = {
        total_item: resData?.result,
        total_pages: resData?.all_pages,
        page: resData?.page,
        limit: resData?.limit,
      };
      dispatch(slice.actions.setRequestEquipment(resData.data));
      dispatch(slice.actions.setFilter(filter));
    }
    // console.log('it is response:', response);
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const deleteRequestEquipmentStock = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.deleteRequestEquipment, data);
    if (response.status === 200 || response.response === 204) {
      const resData = response.data;
      const d = resData?.data.map((v) => {
        v.id = v.medical_equipment_request_id;
        return v;
      });
      const filter = {
        total_item: resData?.result,
        total_pages: resData?.all_pages,
        page: resData?.page,
        limit: resData?.limit,
      };
      dispatch(slice.actions.setRequestEquipment(d));
      dispatch(slice.actions.setFilter(filter));
    }
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

// const approveRequestMainEquipment = () => async (dispatch) => {
//   try {
//     dispatch(slice.actions.setLoading(true));
//     const response = await axios.get(`${apis.approveRequestMainEquipment}/${current?.id}`);
//     if (response.status === 200 || response.response === 204) {
//       const resData = response.data;
//       const d = resData?.data.map((v) => {
//         v.id = v.medical_equipment_request_id;
//         return v;
//       });
//       const filter = {
//         total_item: resData?.result,
//         total_pages: resData?.all_pages,
//         page: resData?.page,
//         limit: resData?.limit,
//       };
//       dispatch(slice.actions.setRequestEquipment(d));
//       dispatch(slice.actions.setFilter(filter));
//     }
//   } catch (error) {
//     dispatch(slice.actions.setError(error));
//   } finally {
//     dispatch(slice.actions.setLoading(false));
//   }
// };

const promiseActions = {
  fetchAllMedicalEquipment,
  fetchOneMedicalEquipment,
  getAllEquipment,
  addEquipmentStock,
  editEquipmentStock,
  fetchAllEquipmentRequest,
  fetchListEmployees,
  addRequestEquipmentStock,
  deleteRequestEquipmentStock,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
