import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import orderBy from 'lodash/orderBy';
import { showError } from '../../../utils/error';
import { getUser } from 'utils/config';

// depthPath is the path to the slice from the root reducer end with a dot (.)
const name = 'visitList';
const depthPath = 'managements.';

const apis = {
  getOne: '/api-patient/get-visit/',
  update: '/api-patient/update-visit',
  delete: '/api-patient/delete-visit',
  getAll: '/api-patient/get-all-visits',
  deleteVisit: '/api-patient/delete-visit',
  create: '/api-patient/add-new-to-visit-list',
  addPatient: '/api-patient/add-patient-to-visit-list',
  addPatientAttachmentInvestigation: '/api-patient/add-patient-investigation-result-attachment',
};

// initialState is the initial state of the slice
const initialState = {
  testData: null,
  data: [],
  visit_statuses: [],
  loading: false,
  currentEditData: null,
  openCreateDialog: false,
  openAddPatientDialog: false,
  currentViewPatientId: null,
  currentViewPatientData: null,
  selectPatientQr: null,
  isSubmitting: false,
  expendPatientDetail: false,
};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setTestAddData: (state, action) => {
      state.testData = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setSelectPatientQr: (state, action) => {
      state.selectPatientQr = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsSubmitting: (state, action) => {
      state.isSubmitting = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
    setOpenCreateDialog: (state, action) => {
      state.openCreateDialog = action.payload;
    },
    setOpenAddPatientDialog: (state, action) => {
      state.openAddPatientDialog = action.payload;
    },
    setCurrentViewPatientId: (state, action) => {
      state.currentViewPatientId = action.payload;
    },
    setCurrentEditData: (state, action) => {
      state.currentEditData = action.payload;
    },
    setCurrentViewPatientData: (state, action) => {
      state.currentViewPatientData = action.payload;
    },
    setVisitFeatureStatus: (state, action) => {
      const { status, object_key } = action.payload;
      const index = state.currentViewPatientData?.visit_feature_data?.findIndex(
        (x) => x?.object_key === object_key
      );
      if (index !== -1 && state.currentViewPatientData) {
        state.currentViewPatientData.visit_feature_data[index].completed_status = `${status}`;
      }
    },
    setVisitStatuses: (state, action) => {
      state.visit_statuses = action.payload;
    },

    setExpendPatientDetail: (state, action) => {
      state.expendPatientDetail = action.payload;
    },
  },
});

const modifyPatientVisitOPD = (data) => async (dispatch, getState) => {
  const currentState = getState();
  const user = getUser();
  const { is_doctor, is_nurse, is_staff } = user;

  const shouldShowUI = !is_doctor && (is_nurse || is_staff);

  // Check current state to get All VisitList
  const currentData = currentState.managements?.visitList?.data || [];

  // Select Data
  const adjustNewData = data.data.data;
 
  const exists = currentData.some((item) => item.id === adjustNewData.patient_visit_id);

  let updatedData;

  if (is_nurse) {
    updatedData = exists
      ? [adjustNewData, ...currentData.filter((item) => item.id !== adjustNewData.patient_visit_id)]
      : [adjustNewData, ...currentData];
  } else if (adjustNewData.refer_doctor_date != null) {
    if (user?.profile_data?.id === adjustNewData.refer_doctor_date.id) {
      updatedData = exists
        ? [
            adjustNewData,
            ...currentData.filter((item) => item.id !== adjustNewData.patient_visit_id),
          ]
        : [adjustNewData, ...currentData];
    } else if (exists) {
      updatedData = currentData.filter((item) => item.id !== adjustNewData.patient_visit_id);
    }
  } else {
    updatedData = exists
      ? [adjustNewData, ...currentData.filter((item) => item.id !== adjustNewData.patient_visit_id)]
      : [adjustNewData, ...currentData];
  }

  // Dispatch the updated data if it has been modified
  if (updatedData) {
    dispatch(slice.actions.setData(updatedData));
  }
};

// promiseActions are the actions that are dispatched by the components
const fetchAllVisitList = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAll, data);
    dispatch(
      slice.actions.setData(
        response.data?.data?.map((item) => ({ ...item, id: item?.patient_visit_id }))
      )
    );
    dispatch(slice.actions.setVisitStatuses(response.data?.visit_status_data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchAllIpdList = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post('/api-patient/get-all-patient-current-ipds', data);
    dispatch(
      slice.actions.setData(
        response.data?.data?.map((item) => ({ ...item, id: item?.patient_current_ipd_id }))
      )
    );
    dispatch(slice.actions.setVisitStatuses(response.data?.visit_status_data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const getOneData = (id) => async (dispatch, getState) => {
  let res = null;
  try {
    res = await axios.get(apis.getOne + id);
     dispatch(
      slice.actions.setCurrentViewPatientData({
        ...res.data?.data,
        visit_feature_data: res.data?.user_visit_feature_data
      })
    );
  } catch (error) {
    dispatch(slice.actions.setError(error));
    res = error;
  }
  return res;
};

const createNewPatient = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    await axios.post(apis.create, data);
    dispatch(fetchAllVisitList());
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const createNewPatientToVisitList = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    await axios.post(apis.addPatient, data);
    dispatch(fetchAllVisitList());
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const updateVisitList = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    await axios.put(`${apis.update}${data?.id}`, data);
    dispatch(fetchAllVisitList());
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const deleteVisitList = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    await axios.post(apis.delete, data);
    dispatch(fetchAllVisitList());
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const addPatientAttachmentInvestigation = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setIsSubmitting(true));
    const res = await axios.post(apis.addPatientAttachmentInvestigation, data);
    console.log(res);
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setIsSubmitting(false));
  }
};

const promiseActions = {
  getOneData,
  deleteVisitList,
  updateVisitList,
  fetchAllVisitList,
  createNewPatient,
  createNewPatientToVisitList,
  fetchAllIpdList,
  addPatientAttachmentInvestigation,
  modifyPatientVisitOPD,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };

