import { get, isEmpty } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';

const name = 'laboratory';
const depthPath = 'managements.';

// const initialState = {
//   deleteData: {
//     title: 'Deletes',
//     laboratoryName: 'Laboratory Test Sheet',
//     laboratorySheet: [],
//     modal: {
//       title: '',
//       data: [],
//     },
//   },
//   addLabor: {
//     title: '',
//     data: [],
//   },
//   openDialog: false,
// };

const initialState = {
  changed: false,
  onSave: false,
  onCancel: false,
  addLabor: [],
  deleteLabor: [],
  addInves: [],
  deleteInves: [],
  openDialog: false,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setIsOnSave: (state, action) => {
      state.onSave = action.payload;
    },
    setIsCancel: (state, action) => {
      state.onCancel = action.payload;
    },
    setIsChanged: (state, action) => {
      state.changed = action.payload;
    },
    setIsAddLabor: (state, actoin) => {
      state.addLabor = actoin.payload;
    },
    setIsDeleteLabor: (state, action) => {
      state.deleteLabor = action.payload;
    },

    setIsDeleteModal: (state, action) => {
      const { title, data } = action.payload;
      state.deleteData.modal.title = title;
      state.deleteData.modal.push(data);
    },
    setIsResetLabor: (state, action) => {
      state.deleteData.laboratorySheet = [];
    },
    setOpenDialog: (state, action) => {
      state.openDialog = action.payload;
    },
  },
});

const clickSave = (data) => (dispatch) => {
  dispatch(slice.actions.setIsOnSave(data));
};
const clickCancel = (data) => (dispatch) => {
  dispatch(slice.actions.setIsCancel(data));
};

const checkChange = (data) => (dispatch) => {
  dispatch(slice.actions.setIsChanged(data));
  console.log('slice check change', data);
};

const cancelChanage = () => (dispatch) => {
  dispatch(slice.actions.setIsChanged(false));
};

const addLabor = (data) => (dispatch) => {
  dispatch(slice.actions.setIsAddLabor(data));
};

const someDeleteLabor =
  ({ data }) =>
  (dispatch) => {
    // dispatch(slice.actions.setIsDelete(data));
    dispatch(slice.actions.setOpenDialog(true));
  };

const deleteOtherModal =
  ({ title, data }) =>
  (dispatch) => {
    dispatch(slice.actions.setIsDeleteModal({ title, data }));
  };

const resetDeleteLabor = () => (dispatch) => {
  dispatch(slice.actions.setIsResetLabor());
  dispatch(slice.actions.setOpenDialog(false));
};

const promiseActions = {
  clickSave,
  clickCancel,
  checkChange,
  cancelChanage,
  addLabor,
  someDeleteLabor,
  deleteOtherModal,
  resetDeleteLabor,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
