import axios from 'axios';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from 'utils/error';
import { setLocale } from 'yup';

const name = 'customerBalanceSheet';
const depthPath = 'managements.';

const apis = {
  getAll: '/api-report/get-customer-balance-report',
};

const initialState = {
  data: [],
  totalValue: null,
  loading: false,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setTotalValue: (state, action) => {
      state.totalValue = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

const fetchAllCustomerBalanceSheet = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const res = await axios.post(apis.getAll, data);

    console.log(res.data.data);
    console.log(res.data.data[0]);
    dispatch(slice.actions.setData(res?.data?.data));
    dispatch(slice.actions.setTotalValue(res?.data?.data[0] || []));
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const promiseActions = {
    fetchAllCustomerBalanceSheet,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
