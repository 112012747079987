import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';
import { limit } from 'firebase/firestore';

// depthPath is the path to the slice from the root reducer end with a dot (.)
const name = 'patientList';
const depthPath = 'managements.';

const apis = {
  getOne: '/api-patient/get/',
  getAll: '/api-patient/get-all',
  getAllOldPatient: '/api-patient/get-all-old-patients',
  create: '/api-patient/add/',
  update: '/api-patient/update/',
  systemSetting: '/api-system-setting/get-all',
  listBloodType: '/api-medical-setting/get-medical-setting-by-object-type',
};

// initialState is the initial state of the slice
const initialState = {
  data: [],
  pageCount: 0,
  paginationModel: {},
  loading: false,
  paginationLoading: false,
  currentEditData: null,
  statusData: [],
  openCreateDialog: false,
  openPatientDetailDialog: false,
  currentViewPatientId: null,
  currentViewPatientData: null,
  systemSetting: {},
  isScan: false,
};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setStatus: (state, action) => {
      state.statusData = action.payload;
    },
    setPagCount: (state, action) => {
      state.pageCount = action.payload;
    },
    setPaginationModel: (state, action) => {
      state.paginationModel = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setPaginationLoading: (state, action) => {
      state.paginationLoading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
    setOpenCreateDialog: (state, action) => {
      state.openCreateDialog = action.payload;
    },
    setOpenPatientDetailDialog: (state, action) => {
      state.openPatientDetailDialog = action.payload;
    },
    setCurrentEditData: (state, action) => {
      state.currentEditData = action.payload;
    },
    setCurrentViewPatientId: (state, action) => {
      state.currentViewPatientId = action.payload;
    },
    setCurrentViewPatientData: (state, action) => {
      state.currentViewPatientData = action.payload;
    },
    setIsScan: (state, action) => {
      state.isScan = action.payload;
    },
    setSystemSetting: (state, action) => {
      state.systemSetting = action.payload;
    },
  },
});

// promiseActions are the actions that are dispatched by the components
const fetchAllPatients = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAllOldPatient, data);
    const page = response?.data?.page || 1;
    dispatch(slice.actions.setData(response?.data?.data));
    dispatch(
      slice.actions.setPaginationModel({
        page: page - 1,
        pageSize: response?.data?.limit,
      })
    );
    dispatch(slice.actions.setStatus(response?.data?.status_data ?? []));
    dispatch(slice.actions.setPagCount(response?.data?.result));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const getAllPatients = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAllOldPatient, data);
    const page = response?.data?.page || 1;
    dispatch(slice.actions.setData(response.data?.data));
    dispatch(
      slice.actions.setPaginationModel({
        page: page - 1,
        pageSize: response?.data?.limit,
      })
    );
    dispatch(slice.actions.setStatus(response?.data?.status_data ?? []));
    dispatch(slice.actions.setPagCount(response?.data?.result));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const filterPatient = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setPaginationLoading(true));
    const response = await axios.post(apis.getAllOldPatient, data);
    const page = response?.data?.page || 1;
    dispatch(slice.actions.setData(response.data?.data));
    dispatch(
      slice.actions.setPaginationModel({
        page: page - 1,
        pageSize: response?.data?.limit,
      })
    );
    dispatch(slice.actions.setPagCount(response?.data?.result));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setPaginationLoading(false));
  }
};

const getOneData = (id) => async (dispatch, getState) => {
  const state = getState();
  const { currentViewPatientId } = state.managements.patientList;
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.get(`${apis.getOne}${id}`);
    if (currentViewPatientId !== null) {
      dispatch(slice.actions.setCurrentViewPatientData(response.data?.data));
    } else {
      dispatch(slice.actions.setCurrentEditData(response.data?.data));
    }
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const createPatient = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    await axios.post(apis.create, data);
    dispatch(fetchAllPatients());
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const updatePatient = (data) => async (dispatch, getState) => {
  let res = {};
  try {
    // dispatch(slice.actions.setLoading(true));
    const patient_id = data.get('id');
    console.log('patient_id:', patient_id);
    res = await axios.put(`${apis.update}${patient_id}`, data);
    dispatch(slice.actions.setCurrentViewPatientData(res?.data?.data));
    dispatch(fetchAllPatients());
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    // dispatch(slice.actions.setLoading(false));
  }
  return res;
};

const getSystemSetting = () => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const res = await axios.post(apis.systemSetting);
    const formData = new FormData();
    formData.append('object_type', 'BLOOD_GROUP_TYPE');
    const resListBloodType = await axios.post(apis.listBloodType, formData);
    console.log(res);
    console.log('resListBloodType', resListBloodType);
    dispatch(
      slice.actions.setSystemSetting({
        ...res?.data,
        list_blood_type: resListBloodType?.data?.data?.child,
      })
    );
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};
const promiseActions = {
  getOneData,
  createPatient,
  updatePatient,
  fetchAllPatients,
  getAllPatients,
  getSystemSetting,
  filterPatient,
};
// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
