const en = {
  IR: 'IR',
  ENT: 'ENT',
  ECG: 'ECG',
  MRI: 'MRI',
  HPV: 'HPV',
  BMD: 'BMD',
  EEG: 'EEG',
  EMG: 'EMG',
  DATE: 'Date',
  NOTE: 'Note',
  SPO2: 'SPO2',
  FEES: 'FEES',
  X_RAY: 'X-Ray',
  OTHER: 'Other',
  PULSE: 'Pulse',
  HEIGHT: 'Height',
  WEIGHT: 'Weight',
  CT_SCAN: 'CT Scan',
  ABDOMEN: 'Abdomen',
  GLUCOSE: 'Glucose',
  VACCINES: 'Vaccines',
  SYSTOLIC: 'Systolic',
  OPERATION: 'Operation',
  FIBROSCAN: 'Fibroscan',
  ALLERGIES: 'Allergies',
  DIASTOLIC: 'Diastolic',
  PATHOLOGY: 'Pathology',
  VACCINATION: 'Vaccination',
  SPIROMETRY: 'Spirometry',
  EVALUATION: 'Evaluation',
  VITAL_SIGN: 'Vital Sign',
  ECHOGRAPHY: 'Echography',
  OSTEOSCOPY: 'Osteoscopy',
  COLONOSCOPY: 'Colonoscopy',
  TEMPERATURE: 'Temperature',
  MEDICATIONS: 'Medications',
  MAMMOGRAPHY: 'Mammography',
  PRESCRIPTION: 'Prescription',
  CATH_LAB: 'Cath Laboratory',
  MEDICAL_SUPPLY: 'Medical Supply',
  RADIOTHERAPY: 'Radiotherapy',
  ADMINISTERED: 'Administered',
  ENT_ENDOSCOPY: 'ENT Endoscopy',
  INVESTIGATION: 'Investigation',
  MENTAL_STATUS: 'Mental Status',
  NEUROSONOLOGY: 'Neurosonology',
  TREATMENT_PLAN: 'Treatment Plan',
  SKIN_EXTREMITY: 'Skin Extremity',
  CLINIC_FEATURE: 'Clinic Feature',
  FAMILY_HISTORY: 'Family history',
  SOCIAL_HISTORY: 'Social History',
  PRESENT_ILLNESS: 'Present Illness',
  MEDICAL_HISTORY: 'Medical History',
  CHIEF_COMPLAINT: 'Chief Complaint',
  RESPIRATORY_RATE: 'Respiratory Rate',
  CYTOPATHOLOGY_HPV: 'Cytopathology HPV',
  UROGENITAL_SYSTEM: 'Urogenital System',
  REVIEW_OF_SYSTEMS: 'Review of Systems',
  CURRENT_MEDICATION: 'Current Medication',
  HISTORY_OF_ILLNESS: 'History of Illness',
  GENERAL_APPEARANCE: 'General Appearance',
  RESPIRATORY_SYSTEM: 'Respiratory System',
  GLASGOW_COMA_SCALE: 'Glasgow Coma Scale',
  EVALUATION_SUMMARY: 'Evaluation Summary',
  OTORHINOLARYNGOLOGY: 'Otorhinolaryngology',
  VACCINATION_HISTORY: 'Vaccination History',
  EVALUATION_CATEGORY: 'Evaluation Category',
  EVALUATION_DIAGNOSIS: 'Evaluation Diagnosis',
  PHYSICAL_EXAMINATION: 'Physical Examination',
  PAST_MEDICAL_HISTORY: 'Past Medical History',
  H_PYLORI_BREATH_TEST: 'H-Pylori Breath Test',
  LABORATORY_TEST_SHEET: 'Laboratory Test Sheet',
  CARDIOVASCULAR_SYSTEM: 'Cardiovascular System',
  CENTRAL_NERVOUS_SYSTEM: 'Central Nervous System',
  GASTRO_INTESTINAL_SYSTEM: 'Gastro-intestinal System',
  PRODUCT_NAME_LOT_NUMBER: 'Product Name | Lot Number',
  FINAL_DIAGNOSIS_EVALUATION: 'Final Diagnosis & Evaluation',
  TODAY_APPOINTMENT_LIST: 'Today Appointment List',
  APPOINTMENT_TIME: 'Appointment Time',
  PATIENT: 'Patient',
  TOTAL_PATIENT: 'Total Patients',
  CURRENT_PATIENT_IPD_INFORMATION: 'Current Patient IPD Information',
  SAVE: 'Save',
  ADMISSION_CODE: 'Admission Code',
  ADMISSION_DATE: 'Admission Date',
  EXPECTED_DISCHARGE_DATE: 'Expected discharge date',
  DEPARTMENT: 'Department',
  ADMISSION_TYPE: 'Admission Type',
  ATTENDING_PHYSICIAN: 'Attending physician',
  NURSE_ASSIGNED: 'Nurse assigned',
  DIAGNOSIS: 'Diagnosis',

  // VISIT TABLE
  STATUS: 'Status',
  BLOOD_PRESSURE: 'Blood pressure',
  PHYSICIAN: 'Physician',
  PATIENT_INFORMATION: 'Patient Information',
  PATIENT_QR_CODE: 'Patient QR Code',
  FULLNAME: 'Fullname',
  FULLNAME_LATIN: 'Fullname (Latin)',
  NSSF_MEMBER: 'NSSF Member',
  GENDER: 'Gender',
  AGE: 'Age',
  PATIENT_PAYMENT_TYPE: 'Patient Payment Type',
  REFER_DOCTOR: 'Refer Doctor',
  BLOOD_GROUP: 'Blood Group',
  VISIT_INFORMATION: 'Visit Information',
  PREVIEW: 'Preview',

  ADD_NEW: 'Add new',
  EDIT: 'Edit',
  DELETE: 'Delete',
  EXIPIRED_DATE: 'Expired date',
  UPLOAD_IMAGE: 'Upload image',
  OPEN: 'Open',

  PRICE_DOMESTIC: 'Price domestic',
  PRICE_FOREIGNER: 'Price foreigner',
  PRICE_NSSF: 'Price NSSF',
  PRICE_INSURANCE: 'Price insurance',

  // MEDICATION
  PHARMACY: 'Pharmacy',
  MEDICATION: 'Medication',
  MEDICATION_LIST: 'Medication list',
  ADD_MEDICAL_FROM_PROVIDER: 'Add medical from provider',
  MEDICATION_FORM: 'Medication form',
  PACK: 'Pack',
  IN_STOCK: 'In stock',
  STOCK_LSIT: 'Stock list',
  COUNT: 'Count',
  INSTRUCTION_INFORMATION: 'Instruction information',
  FREQUENCY: 'Frequency',
  ROUTE: 'Route',
  STRENGTH: 'Strength',
  INSTRUCTIONS_FOR_USE: 'Instructions for use',
  COUNT_IN_STOCK: 'Count in stock',
  LAST_DELIVERY: 'Last delivery',

  // PATIENT VISIT
  VISIT_DATE: 'Visit date',
  VISIT_CODE: 'Visit code',
  PATIENT_NAME: 'Patient name',
  VISIT_INFORMAITON: 'Visit information',

  // ISSUED PRESCRIPTION
  ISSUED_PRESCRIPTION: 'Issued Prescription',
  QUANTITY: 'Quantity',
  DOSAGE_FORM_MORNING: 'Dosage form (Morning)',
  DOSAGE_FORM_AFTERNOON: 'Dosage form (Afternoon)',
  DOSAGE_FORM_EVENING: 'Dosage form (Evening)',
  DOSAGE_FORM_NIGHT: 'Dosage form (Night)',
  DETAIL_PRESCRIPTION: 'Detail prescription',

  // FEE SETTING
  MEDICAL_SETTING: 'Medical setting',
  INVESTIGATION_SETTING: 'Investigation setting',
  IMAGING_TYPE: 'Imaging type',
  EXTERNAL_SERVICE: 'External service',
  SERVICE_TYPE: 'Service type',
  AUTOMATIC_FEE: 'Automatic fee',
  PROCEDURE_TYPE: 'Procedure type',
  LABORATORY_TYPE: 'Laboratory type',
  VACCINATION_TYPE: 'Vaccination type',
  ALERGY_TYPE: 'Allergy type',
  MEDICAL_SUPPLY_TYPE: 'Medical supply type',
  MEDICAL_DIAGNOSIS_TYPE: 'Medical diagnosis type',
  SHORT_DESCRIPTION: 'Short description',
  LONG_DESCRIPTION: 'Long description',

  // EQUIPMENT INVENTORY
  EQUIPMENT_TYPE: 'Equipment type',
  UNIT_PRICE: 'Unit price',
  ALERT_COUNT_IN_STOCK: 'Alert count in stock',
  RETURN: 'Return',
  NOT_RETURN: 'Not return',
  EQUIPMENT: 'Equipment',
  REQUEST: 'Request',
  NOT_RETURN_ITMES: 'Not return items',
  RETURN_ITEMS: 'Return items',
  ISSUED_BY: 'Issued by',
  OPEN_REQUEST_EQUIPMENT: 'Open request equipment',
  REQUEST_BY: 'Request by',
  LIST_REQUESTED_EQUIPMENTS: 'List requested equipments',

  // FINANCE
  BILL_NUMBER: 'Bill number',
  PAYMENT_DATE: 'Payment date',
  TOTAL: 'Total',
  BILL_STATEMENT: 'Bill statement',
  BILL_LIST: 'Bill list',
  INCOME: 'Income',
  PRINT_INVOICE: 'Print invoice',
  INVOICE: 'Invoice',
  TO_PATIENT: 'To patient',
  MEDICAL_PACKAGE: 'Medical package',
  DISCOUNT: 'Discount',
  PAYMENT: 'Payment',

  PAYMENT_ACCOUNT: 'Payment account',
  AMOUNT: 'Amount',
  INCOME_TYPE: 'Income type',
  LIST: 'List',

  EXPENSE_INFO: 'Expense info',
  EXPENSE: 'Expense',
  SUPPLIER_NAME: 'Supplier',
  TOTAL_AMOUNT: 'Total amount',
  EXPENSE_STATEMENT: 'Expense statement',
  STATEMENT: 'Statement',
  EXPENSE_CODE: 'Expense code',
  EXPENSE_TYPE: 'Expense type',
  EXPENSE_DATE_TIME: 'Expense date/time',

  PATIENT_RERUND: 'Patient refund',
  IPD_DATE: 'IPD date',

  // 31.07.2024
  REGISTERED: 'Registered',
  PERSONAL_CARD: 'Personal card',
  PHONE: 'Phone',
  ACTIONS: 'Actions',
  SEARCH_PATIENT: 'Search patient',
  ARE_YOU_SURE: 'Are you sure?',
  CANCEL: 'Cancel',
  FOREINGER: 'Foreigner',
  LASTNAME: 'Last Name',
  FIRSTNAME: 'Firstname',
  LASTNAME_LATIN: 'Last Name (Latin)',
  FIRSTNAME_LATIN: 'Firstname (Latin)',
  DATE_OF_BIRTH: 'Date of birth',
  PROFESSION: 'Profession',
  EMAIL: 'Email',
  FIRST_PHONE: 'First phone',
  SECOND_PHONE: 'Second phone',
  PERSONAL_CARD_ID: 'Personal card ID',
  NATIONALITY: 'Nationality',
  EMERGENCY_CONTACT: 'Emergency Contact',
  ADDRESS_INFORMATION: 'Address information',
  CITY_DISTRICT: 'City / District',
  COMMUNE: 'Commune',
  VILLAGE: 'Village',
  ADDRESS: 'Address',
  HEALTH_INFORMATION: 'Health Information',
  FULL_NSSF: 'NSSF (National Social Security Fund)',
  NSSF_REGISTRATION: 'NSSF registration',
  INSURANCE: 'Insurance',
  MALE: 'Male',
  FEMALE: 'Female',

  // 01.08.2024
  MEDICATION_IS_EMPTY: 'Medication is empty',
  NAME: 'Name',
  COUNTRY: 'Country',
  DETAIL: 'Detail',
  LIST_OF_DEPARTMENTS: 'List of departments',
  ROOM_NUMBER: 'Room number',
  COUNT_OF_BEDS: 'Count of beds',
  ROOM_TYPE: 'Room type',
  FLOOR: 'Floor',
  WARDS_TYPE: 'Wards type',
  SPECIAL_FEATURES: 'Special Features',
  BED_COUFNT: 'Bed count',
  CURRENT_CURRENCY: 'Current Currency',
  BED_NUMBER: 'Bed number',
  OCCUPIED: 'Occupied',
  NAME_LATIN: 'Name (Latin)',
  PRICE: 'Price',
  LIST_OF_BEDS: 'List of beds',
  LIST_OF_ROOMS: 'List of rooms',
  LIST_OF_WARDS: 'List of wards',
  SEARCH: 'Search',
  CODE: 'Code',
  CREATE_CHILD: 'Create child',
  SELECT_COUNTRY: 'Select country',
  SELECT_MEDICATION_FORM: 'Select medication form',
  CREATE: 'Create ',

  // 03.08.2024
  CREATE_INVESTIGATION: 'Crate investigation',
  AUTOMATIC_SERVICE: 'Automatic service',
  OPD: 'OPD',
  IPD: 'IPD',
  YES: 'Yes',
  NO: 'No',
  CONFIRM: 'Confirm',
  UPDATE: 'Update',

  // 04.08.2024
  DESCRIPTION: 'Description',
  HEALTH_CARE_DESCRIPTION: 'Health Care Description',
  ADD: 'Add',
  CATEGORY: 'Category',
  SORT_KEY: 'Sort key',
  TYPE: 'Type',
  PLEASE_SELECT_EQUIPMENT_TO_STOCK: 'Please Select Equipment to stock',
  REQUESTED_PERSON: 'Requested person',
  START_DATE: 'Start date',
  END_DATE: 'End date',
  APPROVED: 'Approved',
  DO_YOU_STILL_WANT_TO_APPROVE_THIS_REQUEST: 'Do you still want to approve this request?',
  NO_REQUESTED_EQUIPMENTS: 'No requested equipments',
  NOT_APPROVED: 'Not Approved',
  NO_DATA_AVAILABLE: 'No data available',
  DO_YOU_WANT_TO_DELETED: 'Do you still want to deleted?',
  APPROVE: 'Approve',
  TOTAL_DOCTORS: 'Total doctors',
  TOTAL_APPOINTMENTS: 'Total appointments',
  TOTAL_REVENUES: 'Total reventures',
  DOCTOR: 'Doctor',
  APPOINTMENT_TYPE: 'Appointment type',
  REJECT: 'Reject',
  ACCEPT: 'Accept',
  CHECK: 'Check',
  EMPLOYEE_INFORMATION: 'Employee Information',
  ADMINISTRATOR: 'Administrator',
  PAYMENT_METHOD: 'Payment Method',
  SIGNATURE_OF_AUTHORIZED_PERSON: 'Signature Of Authorized Person',
  BILL_STATEMENT_TYPE: 'Bill Statement Type',
  START_TIME: 'Start time',
  END_TIME: 'End time',
  PATIENT_PAYMENT_REQUEST_TYPE: 'Patient Payment Request Type',
  REASON: 'Reason',
  SELECTION: 'Selection',
  FIELD_IS_REQUIRED: 'Field is required',
  INFORMATION: 'Information',
  TIME: 'Time',
  CHOOSE: 'Choose',
  APPLY_TO: 'Apply to',
  APPLY_DEPARTMENT_DOCTOR: 'Apply Department | Doctor',
  SELECT_APPLY_TYPE: 'Select Apply Type',
  JANUARY: 'January',
  FEBRUARY: 'February',
  MARCH: 'March',
  APRIL: 'April',
  MAY: 'May',
  JUNE: 'June',
  JULY: 'July',
  AUGUST: 'August',
  SEPTEMBER: 'September',
  OCTOBER: 'October',
  NOVEMBER: 'November',
  DECEMBER: 'December',
  OPERATION_INCOME: 'Operating income',
  USE: 'Use',
  SUPPLIER: 'Supplier',
  REFERENCE_NUMBER: 'Reference Number',
  PATIENT_INFO: 'Patient information',
  BED_NUMBER_WARD_ROOM: 'Bed | Ward | Room',
  ADD_PATIENT_BED: 'Add Patient | Bed',
  PERSONAL_INFORMATION: 'Personal information',
  BED_WARD_ROOM_INFORMATION: 'Bed | Ward | Room information',
  NURSE_INFORMATION: 'Nurse information',
  ASSIGNED_NURSE_NAME: 'Assigned nurse name',
  ASSIGNED_NURSE_NAME_LATIN: 'Assigned nurse name (Latin)',
  PHYSICIAN_INFORMATION: 'Physician information',
  NURSE_AND_PHYSICIAN_INFORMATION: 'Nurse | Physician information',
  ASSIGNED_PHYSICIAN_NAME: 'Assigned physician name',
  ASSIGNED_PHYSICIAN_NAME_LATIN: 'Assigned physician name (Latin)',
  WARD_NAME: 'Ward name',
  ROOM_NAME: 'Room name',
  DISCHARGE_DATE: 'Discharge date',
  BED: 'Bed',
  ROOM: 'Room',
  WARD: 'Ward',
  IPD_TREATMENT_IS_EMPTY: 'IPD Treatment is empty',
  DAILY: 'Daily',
  CONTINUE: 'Continue',
  DESTINATION: 'Destination',
  SUMMARY: 'Summary',
  ADDITIONAL_INFORMATION: 'Additional information',
  INSTRUCTION_FOR_PATIENT: 'Instruction for patient',
  SUBJECTIVE: 'Subjective',
  OBJECTIVE: 'Objective',
  ASSESSMENT: 'Assessment',
  PLAN: 'Plan',
  APPOINTMENT: 'Appointment',
  FROM_MEDICAL_PACKAGE: 'From medical package',
  EXTERNAL: 'Service external',
  RESULT: 'Result',
  SEND_REQUESTS_TO_LABORATORY: 'Send requests to laboratory',
  SEND_REQUESTS_TO_IMAGING: 'Send requests to imaging',
  PRINT: 'Print',
  ADD_NEW_PACKAGE: 'Add new package',
  MENU_OPTIONS: 'Menu options',
  SUPPLY_TYPE: 'Supply type',
  ADD_FROM_PATIENT_LIST: 'Add From patient list',
  MEASUREMENT: 'Measurement',
  OPERATION_DATE: 'Operation date',
  OPERATION_TYPE: 'Operation Type',
  REQUEST_DATE: 'Request date',
  REFUSE: 'Refuse',
  REASON_TYPE: 'Reason type',
  DATE_OUT: 'Date out',
  VOICE_ATTACHMENT: 'Voice | Attachment',
  ATTACHMENT: 'Attachment',
  VOICE: 'Voice',
  CURRENT: 'Current',
  HISTORY: 'History',
  HISTORY_DATE: 'History date',
  WARD_TYPE: 'Type of wards',
  OBSERVATION_REVIEW: 'Observation review',
  SERVICE: 'Service',
  INCREASE_PRICE: 'Increase price',
  DECREASE_PRICE: 'Decrease price',
  CALCULATED_PRICE: 'Calculated price',
  ITEM: 'Item',
  OVER_PACKAGE: 'Over package',
  LIST_OF_PATIENTS: 'List of patients',
  APPLY_PATIENT_BED: 'Apply patient | bed',
  NSSF_NUMBER: 'NSSF number',
  LIST_OF_PATIENT_REFUNDS: 'List of refunds',
  REFUND_DATE: 'Refund date',
  REASON_OF_REFUND: 'Reason of refund',
  VISIT_CODE_DATE: 'Visit Code | Date',
  ADMISSION_CODE_DATE: 'Admission Code | Date',
  OPERATING_EXPENSE: 'Operating expense',
  OPERATING_INCOME: 'Operating income',
  TOTAL_PRICE: 'Total price',
  SOURCE: 'Source',
  DEPOSIT_DATE: 'Deposit date',
  NUMBER_OF_DEPOSIT: 'Number of deposit',
  DEPOSIT_SOURCE: 'Deposit source',
  FINANCIAL_DEPOSIT: 'Finacial deposit',
  BREAKDOWN_OF_DEPOSIT_SOURCE: 'Breakdown of deposit sources',
  SUMMARY_OF_DEPOSITS: 'Summary of Deposits',
  MONTH: 'Month',
  YEAR: 'Year',
  ASSET: 'Asset',
  ACCOUNT: 'Account',
  LIABILITY: 'Liability',
  EQUITY: 'Equity',
  FINANCIAL_BALANCE_SHEET_DATE: 'Financial balance sheet date',
  CASH_FLOW_ANALYSIS: 'Cash Flow Analysis',
  NUMBER_OF_TRANSACTIONS: 'Number of transactions',
  INCOME_GENERETED: 'Income generated',
  PROCEDURE: 'Procedure',
  PROVIDING_MEDICATION: 'Providing medication',
  DOSAGE: 'Dosage',
  PROVIDED_TIME: 'Provided time',
  YES_PROVIDE: 'Yes provide',
  INSTRUCTION: 'Instruction',
  NR: 'Nr.',
  TEMPLATE: 'Template',
  LETTER: 'Letter',
  SHOW: 'Show',
  LOT_NUMBER: 'Lot number',
  LOCATION: 'Location',
  HOME_PRESCRIPTION: 'Home prescription',
  IMAGING: 'Imaging',
  MEDICAL_CERTIFICATE: 'Medical certificate',
  USER_INFORMATION: 'User information',
  USER_NAME: 'User name',
  DASHBOARD: 'Dashboard',
  LOGOUT: 'Logout',
  YOUR_DATA_HAS_BEEN_SAVED_SUCCESSFULLY: 'Your data has been saved successfully',
  FILE_NAME: 'File name',
  FILE_TYPE: 'File type',
  FILE_SIZE: 'File size',
  LIST_OF_INSURANCES: 'List of insurances',
  LIST_OF_SUPPLIERS: 'List of suppliers',
  CONTACT_PERSON: 'Contact person',
  CONTACT_PHONE: 'Contact phone',
  CONTACT_EMAIL: 'Contact email',
  VITAL_SIGN_HISTORY: 'Vital Sign history',
  PRESCRIPTION_HISTORY: 'Prescription history',
  APPOINTMENT_HISTORY: 'Appointment history',
  NOTE_HISTORY: 'Note history',
  MEDICAL_CERTIFICATE_HISTORY: 'Medical certificate history',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  IPD_INFO: 'IPD information',
  MEDICATION_INFORMATION: 'Medication informaton',
  SEARCH_MEDICATION_FORM: 'Search medication form',
  EXPIRED_DATE: 'Expired date',
  PHYSCAL_EXAMINATION: 'Physical examination',
  WEEK: 'Week',
  // 17.08.2024
  PAYMENT_TYPE: 'Payment type',
  PAYMENT_STATUS: 'Payment status',
  PAYMENT_INFORMATION: 'Payment information',
  LIST_OF_PAID_PAYMENTS: 'List of paid payments',
  REGISTERED_NUMBER: 'Registered number',
  INVOICE_INFORMATION: 'Invoice information',
  INVOICE_NUMBER: 'Invoice number',
  INVOICE_DATE: 'Invoice date',
  TOTAL_PAYMENT_AMOUNT: 'Total payment amount',
  TOTAL_PAID_AMOUNT: 'Total paid amount',
  TOTAL_AMOUNT_REMAINING: 'Total amount remaining',
  OPD_INFO: 'OPD information',
  END_PRICE: 'End price',
  TOTAL_PAYMENT: 'Total payment',
  PAYMENT_BY: 'Payment by',
  REFRESH: 'Refresh',
  TODAY: 'Today',
  NUMBER_OF_VISITS: 'Number of visits',
  NUMBER_OF_ADMISSIONS: 'Number of admissions',
  ACCOUNTS_RECEIVALBE_AGING_BREAKDOWN: 'Accounts receivable aging breakdown',
  AGE_CATEGORY: 'Age category',
  AMOUNT_OUTSTANDING: 'Amount outstanding',
  ACCOUNTS_PAYABLE_AGING_BREAKDOWN: 'Accounts Payable Aging Breakdown',
  TOTAL_OUTSTANDING_BALANCE: 'Total outstanding balance',
  OUTSTANDING_BALANCE: 'Outstanding balance',
  DISCOUNT_OUTSTANDING: 'Discount outstanding',
  DISCOUNT_BREAKDOWN_BY_SERVICE: 'Discount breakdown by service',
  SERVICE_INFO: 'Service info',
  VALUE: 'Value',
  AVERAGE: 'Average',
  DEPOSIT_BREAKDOWN_BY_SERVICE: 'Deposit breakdown by service',
  AVERAGE_DEPOSIT: 'Average deposit',
  BALANCE_BREAKDOWN_BY_SUPPLIER: 'Balance breakdown by supplier',
  NUMBER_OF_INVOICES: 'Number of invoices',
  PAYMENT_DUE_DATE: 'Payment due date',
  MONTHLY_BALANCE_DISTRIBUTION: 'Monthly balance distribution',
  DEPOSIT_BREAKDOWN_BY_PAYMENT_METHOD: 'Deposit breakdown by payment method',
  NUMBER: 'Number',
  ITEM_OF_EXPENSE: 'Item of expense',
  DETAIL_INFORMATION: 'Detail information',
  EXPENSE_DATE: 'Expense date',
  REFERENCE: 'Reference',
  ASSIGNED_INFORMATION: 'Assigned information',
  GENERAL_INFORMATION: 'General information',
  INCOME_DATE: 'Income date',
  YOUR_DATA_HAS_BEEN_SUCCESSFULLY_REMOVED: 'Your data has been successfully removed.',
  YOUR_DATA_HAS_BEEN_SUCCESSFULLY_UPDATED: 'Your data has been successfully updated.',
  YOUR_DATA_HAS_BEEN_SUCCESSFULLY_CREATED: 'Your data has been successfully created.',
  YOUR_DATA_HAS_BEEN_SUCCESSFULLY_SAVED: 'Your data has been successfully saved.',
  ARE_YOU_SURE_WANT_TO_DELETE: 'Are you sure want to delete?',
  BILL_DATE: 'Bill date',
  UPLOAD_ATTACHMENT: 'Upload attachment',
  REASON_FOR_REFUND: 'Reason for refund',
  MEDICATION_FROM_PROVIDER:'Medication from provider',
  PATIENT_APPOINTMENT:'Patient appointment',
  RESPIRATORY_SYTSTEM:'Respiratory system',
  PDF_VIEWER:'PDF viewer',
  JOB_STATUS:'Job status',
  JOB_TYPE:'Job type',
  FACTORY_NUMBER:'Factory number',
  PATENT_NUMBER:'Patent number',
  START_WORK_DATE:'Start work date',
  WORKING_STATUS:'Working status',
  APPLY:'Apply',
  APPLIED:'Applied',
  CHOOSED:'Choosed',
  USERNAME:'Username',
  NURSE:'Nurse',
  ASSIGN_TO:'Assign to',
  ADMIN_STAFF:'Admin staff',
  DELETE_USER:'Delete user',
  ACCESS_MODULE_LIST:'Access module list',
  EXECUTE:'Execute',
  MENU_BY_ROLE:'Menu by role',
  VIEW:'View',
  ASSIGN:'Assign',
  ASSIGN_MODULE:'Assign​ module',
  LABORATORY_IMAGING:'Laboratory | Imaging',
  BY:'By',
  CHECK_EXCHANGE_RATE:'Check Exchange Rate',
  WORKING_DAYS:'Working Days',
  TIME_TABLES:'Time Tables',
  CURRENCY_TYPE:'Currency Type',
  TELEGRAM_NAME:'Telegram Name',
  SYSTEM_FEATURES:'System Features',
  THE_CLINIC_HOSPITAL_TYPE:'The Clinic | Hospital type',
  THE_CLINIC_HOSPITAL_INFO:'The Clinic | Hospital information',
  MONDAY:'Monday',
  TUESDAY:'Tuesday',
  WEDNESDAY:'Wednesday',
  THURSDAY:'Thursday',
  FRIDAY:'Friday',
  SUTURDAY:'Suturday',
  SUNDAY:'Sunday',
  EXCHANGE_RATE:'Exchange Rate',
  MORNING:'Morning',
  AFTERNOON:'Afternoon',
  EVENING:'Evening',
  REFERENCE_RANGE_PEDIATRIC:'Reference range (Pediatric)',
  REFERENCE_RANGE_ADULT_FEMALE:'Reference range (Adult Female)',
  REFERENCE_RANGE_ADULT_MALE:'Reference range (Adult Male)',
  UNIT:'Unit',
  SEARCH_ENTER:'Search (Enter)',
  ID:'ID',
  LABORATORY:'Laboratory',
  LABORATORY_ITEM:'Laboratory item',
  LABORATORY_TEST_TEMPLATE:'Laboratory test template',
  REQUESTED_DATE:'Requested date',
  RESULT_DATA_ENTRY:'Result data entry',
  RESULT_DATA_UPLOAD:'Result data upload',
  PENDING:'Pending',
  DONE:'Done',
  PLEASE_SELECT_DATE:'Plese select date',
  BENEFIT_VALUE:'Benefit value',
  LABORATORY_TEST_RESULT:'Laboratory test result',
  LABORATORY_TEST_UNIT:'Laboratory test unit',
  CLINIC_LOCATION:'Location',
  ADD_PAYMENT:'Add payment',
  PAID_AMOUNT:'Paid amount',
  SELECTED:'Selected',
  SELECT:'Select',
  PLEASE_SECTIONS:'Please selections',
  REASON_FOR_VISIT:'Reason for visit',
  DOCTOR_DETAILS:'Doctor details',
  DOCTOR_SESSION:'Doctor visit session',
  DOCTOR_BENEFITS:'Doctor benefits',
  NURSE_DETAILS:'Nurse details',
  STAFF_DETAILS:'Staff details',
  PAYMENT_BILL_STATUS:'Payment bill status',
  QUALIFICATIONS:'Qualifications',
  SPECIALTY:'Specialty',
  CLINICAL_EXPERTISE:'Clinical expertise',
  PROFESSIONAL_MEMBERSHIPS_AND_AFFILIATIONS:'Professional membership and Affiliation',
  PATIENT_REVIEWS_AND_TESTIMONIALS:'Patient reviews and Testimonials',
  BENEFIT_PERCENTAGE:'Benefit percentage',
  QUALIFICATIONS_DETAILS:'Qualification details',
  SPECIALTY_DETAILS:'Specialty details',
  CLINICAL_EXPERTISE_DETAILS:'Clinical expertise details',
  PROFESSIONAL_MEMBERSHIP_AND_AFFILIATION_DETAILS:'Professional membership and Affiliation details',
  PATIENT_REVIEWS_AND_TESTIMONIALS_DETAILS:'Patient reviews and Testitimonials details',
  LIST_OF_PATIENT_INSURANCE_CLAIMS:'List of patient insurance claims',
  AMOUNT_CLAIM:'Amount claim',
  DESCRIPTION_OF_CLAIM:'Description of claim',
  DATE_OF_CLAIM:'Date of claim',
  BILL_INFORMATION:'Bill information',
  INSURANCE_CLAIM:'Insurance claim',
  TOTAL_AMOUNT_OWED:'Total amount owed',
};

export default en;
