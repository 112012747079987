import React, { useEffect, useState } from 'react';
import { dispatch } from 'redux/store';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import settingsSlice from 'redux/slices/settings/setting';

// Redux
import visitListSlice from 'redux/slices/managements/visit-list';
import laborResultSlice from 'redux/slices/managements/labor-result-overlay';

import patientListSlice from 'redux/slices/managements/patient-list';
import medicalSettingSlice from 'redux/slices/settings/medical-setting';
import DialogContainer from 'components/dialogs';
import LaborResultOverlay from 'components/labor-result/labor-result-overlay';
import getSocket from 'utils/socket';
import { useAuthContext } from 'auth/useAuthContext';
import ImagingResultOverlay from 'components/labor-result/imaging-result-overlay';
import { json2FormData } from 'utils/formdata';

import { getClinic, getKeyValue, getUser, setKeyValue } from '../../utils/config';
import NavVertical from './nav/NavVertical';
import Header from './header';
import Main from './Main';
import { getValue } from '@mui/system';
import ToolbarImagingResult from 'components/labor-result/toolbar-imaging-result';
import investigationApp from 'redux/slices/managements/investigation-socket-app';
import ToolbarLaborResult from 'components/labor-result/toolbar-labor-result';
import axios from 'axios';
import { isEmpty } from 'lodash';

export default function DashboardLayout() {
  const navigate = useNavigate();
  const clinic = getClinic();
  const [open, setOpen] = useState(false);
  const { data: laborData, imagingData } = laborResultSlice.selector();
  const { individualLabSocket, individualImagingSocket } = investigationApp.selector();
  // const { data, testData } = visitListSlice.selector();

  const { updateAccessModule } = useAuthContext();

  const [profileId, setProfileId] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [patientId, setPatientId] = useState(null); // Store "scan-user" data
  const { user } = useAuthContext();
  const socket = getSocket();
  const appKey = getKeyValue('app_key');
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const renderNavVertical = (
    <NavVertical
      openNav={open}
      onCloseNav={handleClose}
      setOpen={setOpen}
      setOpenDrawer={setOpenDrawer}
      openDrawer={openDrawer}
    />
  );

  const onHandleUpdateAccessModule = async () => {
    try {
      const getNewAccessModule = await axios.post(
        '/api-user/get-user-access-module',
        json2FormData({ user_id: user?.id })
      );
      const newModule = getNewAccessModule?.data?.data;
      await updateAccessModule(newModule);

      if (!isEmpty(newModule)) {
        const newPath = isEmpty(newModule[0]?.child) ? newModule[0] : newModule[0]?.child[0];
        setKeyValue('selected_menu', newPath);
        navigate(newPath?.url, { replace: true });
      } else {
        navigate('/main', { replace: true });
      }

      // UPDATE USER HAS UPDATE TO 0
      await axios.put(
        `/api-user/get-my-login-log/${user?.app_token}`,
        json2FormData({ is_update: 0 })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const onCheckHasUpdateMenu = async () => {
    try {
      const res = await axios.get(`/api-user/get-my-login-log/${user?.app_token}`);
      // HANDLE UPDATE NEW ACCESS MODULE
      if (res?.data?.data?.is_update === 1) {
        await onHandleUpdateAccessModule();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const localProfileId = user?.profile_data?.id;
    if (localProfileId) {
      setProfileId(localProfileId);
    } else {
      console.error('Profile ID not found or not available');
    }
  }, []);

  useEffect(() => {
    onCheckHasUpdateMenu();
  }, [profileId, clinic?.id]);

  const countIsSeenZero = laborData ? laborData?.filter((item) => item.is_seen === 0).length : 0;
  const countImaging = imagingData ? imagingData?.filter((item) => item.is_seen === 0).length : 0;

  useEffect(() => {
    dispatch(settingsSlice.promiseActions.fetchAllSettings());
    dispatch(medicalSettingSlice.promiseActions.fetchAllMedicalSetting(clinic?.id));

    if (appKey) {
      console.log(appKey);
      // Setup socket events and connection
      socket.on('connect', () => {
        const payload = {
          user_id: `${appKey}-user-${profileId}-${clinic?.id}`,
        };
        socket.emit('login', payload, handleLoginResponse);
        console.log('payload', payload);
      });

      socket.on('scan-user', (userScan) => {
        dispatch(patientListSlice.actions.setCurrentViewPatientId(userScan.patient_id));
        dispatch(patientListSlice.actions.setIsScan(true));
        // if(data.patient)
        // console.log(data);
        // setPatientId(data); // Store the patient ID
        // setShowDialog(true); // Open dialog
      });

      socket.on('patient-laboratory-test-result', () => {
        dispatch(investigationApp.actions.setIndividualLabSocket(true));
      });

      socket.on('patient-investigation-result', () => {
        dispatch(investigationApp.actions.setIndividualImagingSocket(true));
      });

      socket.on('user-access-module-update', async (userModuleData) => {
        if (userModuleData?.data) {
          await onHandleUpdateAccessModule();
        }
      });

      // Patient Visit
      socket.on(`${appKey}-patient-visit`, (patientData) => {
        console.log('socket patient data', patientData);
        dispatch(visitListSlice.promiseActions.modifyPatientVisitOPD(patientData));
      });
    }

    return () => {
      socket.off(); // Cleanup listeners
    };
  }, [profileId, clinic?.id]);

  function handleLoginResponse(response) {
    console.log('Server response:', response);
  }

  const handleDialogConfirm = () => {
    setShowDialog(false);

    localStorage.setItem('patientId', patientId);
    window.open(`https://dev-web-clinic.camemis-learn.com/main/patient-list?scan=1`);

    // window.open(`http://localhost:3000/main/patient-list?patientId=${patientId}`); // Open a new tab
    // dispatch(patientListSlice.actions.setCurrentViewPatientId(patientId)); // Set patient ID
  };

  const handleDialogCancel = () => setShowDialog(false);

  // check role
  const userRole = getUser();
  const { is_doctor, is_nurse, is_staff } = userRole;

  const shouldShowUI = !is_doctor && (is_nurse || is_staff);



  const handleClick = () => {
    // Update state after 10 seconds
    setTimeout(() => {
      // setClicked(true);
      dispatch(visitListSlice.promiseActions.modifyPatientVisitOPD());
      // Call your function here, for example:
      // doSomething();
    }, 10000); // 10000 milliseconds = 10 seconds
  };

  return (
    <>
      <Header onOpenNav={handleOpen} />
      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
          alignItems: 'center',
        }}
      >
        {renderNavVertical}
        <DialogContainer />

        {!shouldShowUI && (
          <Stack
            direction="row"
            sx={{
              zIndex: 2001,
              position: 'fixed',
              bottom: 10,
              left: '48%',
            }}
          >
            <ToolbarLaborResult viewType={2} count={countIsSeenZero} />
            <Box width="40px" />
            <ToolbarImagingResult viewType={2} count={countImaging} />
          </Stack>
        )}
        {/* <Button
          onClick={() => {
            handleClick();
         
          }}
        >
          sdf
        </Button> */}

        <ImagingResultOverlay />
        <LaborResultOverlay />
        <Main>
          <Outlet />
        </Main>
      </Box>

      {/* Dialog for "scan-user" event */}
      <Dialog open={showDialog} onClose={handleDialogCancel} sx={{ zIndex: 2009 }}>
        <DialogTitle>Scan User</DialogTitle>
        <DialogContent>Do you want to open a new tab for this user?</DialogContent>
        <DialogActions>
          <Button onClick={handleDialogCancel}>No</Button>
          <Button onClick={handleDialogConfirm}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
