import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

// depthPath is the path to the slice from the root reducer end with a dot (.)
const name = 'medicalRecordLetter';
const depthPath = 'settings.';

const apis = {
  getOne: '/api-system-setting/get-all-medical-record-letters',
  getAll: '/api-system-setting/get-all-medical-record-letters',
  add: '/api-system-setting/edit-medical-record-letter',
  edit: '/api-system-setting/edit-medical-record-letter',
  delete: '/api-system-setting/delete-medical-record-letter',
};

// initialState is the initial state of the slice
const initialState = {
  data: [],
  loading: false,
  currentData: null,
  open: {
    create: false,
    delete: false,
  },
};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCurrentData: (state, action) => {
      state.currentData = action.payload;
    },
    setOpenDialog: (state, action) => {
      const { target, value } = action.payload;
      state.open[target] = value;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

const fetchAllMedicalRecordLetter = (data) => async (dispatch, getState) => {
  try {
    if (data) dispatch(slice.actions.setLoading(true));

    const response = await axios.post(apis.getAll, data);
    dispatch(slice.actions.setData(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};
const addMedicalRecordLetter = (data) => async (dispatch) => {
  try {
    const response = await axios.post(apis.add, data);
    if (response.status === 200) dispatch(slice.actions.setData(response.data?.data));
    return { status: true, message: response?.data?.message };
  } catch (error) {
    return { status: false, message: error.message };
  }
};
const editMedicalRecordLetter = (data) => async (dispatch) => {
  try {
    const id = data.get('id');
    const response = await axios.put(`${apis.edit}/${id}`, data);
    if (response.status === 200) {
      // dispatch(slice.actions.setData(response.data?.data));

      dispatch(fetchAllMedicalRecordLetter());
    }
    return { status: true, message: response?.data?.message };
  } catch (error) {
    return { status: false, message: error.message };
  }
};
const deleteMedicalRecordLetter = (id) => async (dispatch) => {
  try {
    const response = await axios.delete(`${apis.delete}/${id}`);
    if (response.status === 200) dispatch(slice.actions.setData(response.data?.data));
    return { status: true, message: response?.data?.message };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

const promiseActions = {
  fetchAllMedicalRecordLetter,
  addMedicalRecordLetter,
  editMedicalRecordLetter,
  deleteMedicalRecordLetter,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
