export const json2FormData = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (data[key] instanceof Array) {
      data[key].forEach((item) => {
        formData.append(`${key}[]`, item);
      });
    } else if (data[key] !== null || data[key] !== undefined) {
      formData.append(key, data[key]);
    }
  });
  return formData;
};
