import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

// depthPath is the path to the slice from the root reducer end with a dot (.)
const name = 'feeServicePackages';
const depthPath = 'managements.';

const apis = {
  getAll: '/api-system-setting/get-all-fee-service-packages',
  create: '/api-system-setting/add-fee-service-packages',
  update: '/api-system-setting/update-fee-service-packages',
};

// initialState is the initial state of the slice
const initialState = {
  data: [],
  loading: false,
  currentEditData: null,
  openCreateDialog: false,
};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
    setOpenCreateDialog: (state, action) => {
      state.openCreateDialog = action.payload;
    },
    setCurrentEditData: (state, action) => {
      state.currentEditData = action.payload;
    },
  },
});

// promiseActions are the actions that are dispatched by the components
const fetchAllFeeServicePackages = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAll, data);
    dispatch(
      slice.actions.setData(
        response.data?.data?.map((item) => ({
          ...item,
          // id: item?.fee_service_package_id,
        }))
      )
    );
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const createFeeServicePackage = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    await axios.post(apis.create, data);
    dispatch(fetchAllFeeServicePackages());
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const updateFeeServicePackage = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    await axios.put(`${apis.update}${data?.id}`, data);
    dispatch(fetchAllFeeServicePackages());
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const promiseActions = {
  createFeeServicePackage,
  updateFeeServicePackage,
  fetchAllFeeServicePackages,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
