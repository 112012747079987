import axios from 'axios';
import { get, isEmpty } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

// depthPath is th path to the slice from the root reducer end with a dot (.)
const name = 'progressNote';
const depthPath = 'managements.';

// initialState is the initial state of the slice
const initialState = {
  data: [],
  dateList: [],
  timeList: [],
  selectedDate: {},
  loading: false,
  createLoading: false,
  deleteLoading: false,
  currentEditData: null,
};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setDateList: (state, action) => {
      state.dateList = action.payload;
    },
    setTimeList: (state, action) => {
      state.timeList = action.payload;
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCreateLoading: (state, action) => {
      state.createLoading = action.payload;
    },
    setDeleteLoading: (state, action) => {
      state.deleteLoading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
    setCurrentEditData: (state, action) => {
      state.currentEditData = action.payload;
    },
    removeItemFromData: (state, action) => {
      state.data = state.data?.filter((item) => +item.patient_progress_note_id !== +action.payload);
    },
  },
});

const fetchPatiendProgressNote = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post('/api-patient/get-all-progress-notes', data);
    dispatch(slice.actions.setData(response?.data));
    dispatch(slice.actions.setDateList(response.data?.day_data));
    dispatch(slice.actions.setTimeList(response.data?.time_data));
    // const state = getState();

    // const dayDate = state.managements.progressNote.selectedDate;
    // if (isEmpty(dayDate)) {
    //   dispatch(slice.actions.setSelectedDate(response.data?.day_data[0]));
    // }
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const createPatientProgressNote = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setCreateLoading(true));
    const response = await axios.post('/api-patient/add-patient-progress-note', data);

    // if (!response.err) {
    //   dispatch(fetchPatiendProgressNote());
    //   dispatch(slice.actions.setCreateLoading(false));
    // }
    // dispatch(slice.actions.setData(response.data?.data || []));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setCreateLoading(false));
  }
};

const deletePatientProgressNote = (data) => async (dispatch) => {
  try {
    // dispatch(slice.actions.setLoading(true));
    const response = await axios.post('/api-patient/delete-patient-progress-note', data);
    dispatch(slice.actions.removeItemFromData(data.get('patient_progress_note_id') || []));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    // dispatch(slice.actions.setLoading(false));
  }
};

const promiseActions = {
  createPatientProgressNote,
  fetchPatiendProgressNote,
  deletePatientProgressNote,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
