/* eslint-disable no-undef */
import io from 'socket.io-client';

let socket;

const getSocket = () => {
  if (!socket) {
    socket = io('https://dev-ws-clinic.camemis-learn.com/', {
      transports: ['websocket'],
    });
  }
  return socket;
};

export default getSocket;
