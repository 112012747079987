import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

// depthPath is the path to the slice from the root reducer end with a dot (.)
const name = 'income';
const depthPath = 'managements.';

const apis = {
  getOne: '/api-finance/get-income',
  getAll: '/api-finance/get-all-incomes',
  add: '/api-finance/add-income',
  update: '/api-finance/update-income/',
  delete: '/api-finance/delete-income',
};

// initialState is the initial state of the slice
const initialState = {
  data: [],
  loading: false,
  addLoading: false,
  editLoading: false,
  statusData: [],
  incomeTypeData: [],
  paymentAccountData: [],
  currentData: null,
  openDialog: {
    create: false,
    edit: false,
    delete: false,
  },
};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setStatusData: (state, action) => {
      state.statusData = action.payload;
    },
    setIncomeTypeData: (state, action) => {
      state.incomeTypeData = action.payload;
    },
    setPaymentAccountData: (state, action) => {
      state.paymentAccountData = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAddLoading: (state, action) => {
      state.addLoading = action.payload;
    },
    setEditLoading: (state, action) => {
      state.editLoading = action.payload;
    },
    setCurrentData: (state, action) => {
      state.currentData = action.payload;
    },
    setOpenDialog: (state, action) => {
      const { target, value } = action.payload;
      state.openDialog[target] = value;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

const fetchAllIncomes = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAll, data);

    dispatch(slice.actions.setData(response?.data?.data));
    dispatch(slice.actions.setStatusData(response?.data?.income_status_data));
    dispatch(slice.actions.setIncomeTypeData(response?.data?.income_type_data));
    dispatch(slice.actions.setPaymentAccountData(response?.data?.payment_account_data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};
const addEditIncome = (data) => async (dispatch) => {
  try {
    const response = await axios.post(apis.add_edit, data);
    if (response.status === 200) dispatch(slice.actions.setData(response.data?.data));
    return { status: true, message: response?.data?.message };
  } catch (error) {
    return { status: false, message: error.message };
  }
};
const addIncome = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setAddLoading(true));
    const res = await axios.post(apis.add, data);
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setAddLoading(false));
  }
};

const editIncome = (id, data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setEditLoading(true));
    const res = await axios.put(apis.update + id, data);
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setEditLoading(false));
  }
};
const deleteIncome = (id) => async (dispatch) => {
  try {
    const response = await axios.delete(`${apis.delete}/${id}`);
    if (response.status === 200) dispatch(slice.actions.setData(response.data?.data));
    return { status: true, message: response?.data?.message };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

const promiseActions = {
  fetchAllIncomes,
  addIncome,
  editIncome,
  deleteIncome,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
