import axios from 'axios';
import { get, isEmpty } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from 'utils/error';

const name = 'wardRoomManagements';
const depthPath = 'managements.';

const apis = {
  fetchAllPatientBeds: '/api-patient/get-all-patient-beds',
  actionOnPatientBeds: '/api-patient/action-set-bed-to-patient-current-ipd',
  editPatientBeds: '/api-patient/get-patient-current-ipd-bed',
  fetchAllCurrentPatients: '/api-patient/get-all-patient-current-ipds',
};

const initialState = {
  loading: false,
  data: [],
  patientData: [],
  openDrawer: {
    create: false,
    edit: false,
    delete: false,
  },
  currentData: null,
  createLoading: false,
  editLoading: false,
  deleteLoading: false,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setPatientData: (state, action) => {
      state.patientData = action.payload;
    },

    // Loading
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCreateLoading: (state, action) => {
      state.createLoading = action.payload;
    },

    setEditLoading: (state, action) => {
      state.editLoading = action.payload;
    },
    setDeleteLoading: (state, action) => {
      state.deleteLoading = action.payload;
    },

    // OpenDrawer
    setOpenDrawer: (state, action) => {
      const { target, value } = action.payload;
      state.openDrawer[target] = value;
    },

    // currentEditData
    setCurrentData: (state, action) => {
      state.currentData = action.payload;
    },

    // Error
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

const fetchAllpatientBedsData = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const res = await axios.post(apis.fetchAllPatientBeds, data);
    dispatch(slice.actions.setData(res?.data?.data?.data));
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchAllCurrentPatientsData = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const res = await axios.post(apis.fetchAllCurrentPatients, data);
    dispatch(slice.actions.setPatientData(res?.data?.data));
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const currentPatientBeds = (data, id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const res = await axios.get(`${apis.editPatientBeds}/${id}`, data);
    dispatch(slice.actions.setCurrentData(res?.data?.data));
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const actionsOnPatient = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setCreateLoading(true));
    const res = await axios.post(apis.actionOnPatientBeds, data);
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setCreateLoading(false));
  }
};

const promiseActions = {
  fetchAllCurrentPatientsData,
  fetchAllpatientBedsData,
  currentPatientBeds,
  actionsOnPatient,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
