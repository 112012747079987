import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

// depthPath is the path to the slice from the root reducer end with a dot (.)
const name = 'billsList';
const depthPath = 'managements.';

// initialState is the initial state of the slice
const initialState = {
  data: [],
  all_bills: [],
  patient_data:[],
  payment_status: [],
  patient_bill_data: [],
  patient_payment_bills: [],
  total_patient_bill_amount_value: 0,
  total_paid_amount_value: 0,
  bill_status_data: [],
  payment_account_data: [],
  payment_method_data: [],
  loading: false,
  loadingPatientBill: false,
  selectedItem: null,
  openBillDetail: false,
  openInvoiceInBills: false,
  billSearchKey: {
    bill_number: '',
  },
};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setPatientData: (state, action) => {
      state.patient_data = action.payload;
    },
    setPatientPaymentBill: (state, action) => {
      state.patient_payment_bills = action.payload;
    },
    setPatientBillData: (state, action) => {
      state.patient_bill_data = action.payload;
    },
    setAllBills: (state, action) => {
      state.all_bills = action.payload?.map((item, index) => ({ ...item, index }));
    },
    setTotalPatientBillAmountValue: (state, action) => {
      state.total_patient_bill_amount_value = action.payload;
    },
    setTotalPaidAmountValue: (state, action) => {
      state.total_paid_amount_value = action.payload;
    },
    setBillStatusData: (state, action) => {
      state.bill_status_data = action.payload;
    },
    setPaymentMethodData: (state, action) => {
      state.payment_method_data = action.payload;
    },
    setPaymentAccountData: (state, action) => {
      state.payment_account_data = action.payload;
    },
    setPaymentStatus: (state, action) => {
      state.payment_status = action.payload;
    },
    setSlectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    setOpenBillDetail: (state, action) => {
      state.openBillDetail = action.payload;
    },
    setOpenInvoiceInBill: (state, action) => {
      state.openInvoiceInBills = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLoadingPatientBill: (state, action) => {
      state.loadingPatientBill = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
    setBillSearchKey: (state, action) => {
      state.billSearchKey = action.payload;
    },
  },
});

const onGetPatientPaymentMethodBill = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoadingPatientBill(true));
    const res = await axios.post('/api-finance/get-all-bill-payments', data);
    // console.log(res.data);
    dispatch(slice.actions.setPatientPaymentBill(res?.data));
    dispatch(slice.actions.setPatientBillData(res?.data.bill_data));
    dispatch(slice.actions.setTotalPatientBillAmountValue(res?.data.patient_bill_amount));
    dispatch(slice.actions.setTotalPaidAmountValue(res?.data.paid_amount));
    dispatch(slice.actions.setBillStatusData(res?.data.bill_status_data));
    dispatch(slice.actions.setPaymentMethodData(res?.data.payment_method_data));
    dispatch(slice.actions.setPaymentAccountData(res?.data.payment_account_data));
    dispatch(slice.actions.setPatientData(res?.data.patient_data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoadingPatientBill(false));
  }
};

const onGetPatientBill = (data) => async (dispatch) => {
  let res = null;
  try {
    dispatch(slice.actions.setLoadingPatientBill(true));
    res = await axios.post('/api-patient/get-patient-bill', data);
    dispatch(slice.actions.setData(res?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoadingPatientBill(false));
  }
  return res;
};

const onGetAllBill = (data) => async (dispatch) => {
  let res = null;
  try {
    dispatch(slice.actions.setLoading(true));
    res = await axios.post('/api-finance/get-all-bills', data);
    console.log('res:', res);
    dispatch(slice.actions.setAllBills(res?.data?.data));
    dispatch(slice.actions.setPaymentStatus(res?.data?.payment_status_data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
  return res;
};
const onUpdateBill = (data) => async (dispatch) => {
  let res = null;
  try {
    res = await axios.post('/api-finance/action-payment-bill', data);
    dispatch(slice.actions.setAllBills(res?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  }
  return res;
};

const onDeletePatientBill = (data) => async (dispatch) => {
  let res = null;
  try {
    res = await axios.post('/api-finance/delete-bill-payment', data);
  } catch (error) {
    res = error;
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
  return res;
};

const promiseActions = {
  onGetPatientBill,
  onUpdateBill,
  onGetAllBill,
  onGetPatientPaymentMethodBill,
  onDeletePatientBill,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
