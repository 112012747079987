import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

// depthPath is the path to the slice from the root reducer end with a dot (.)
const name = 'dischargeDoctor';
const depthPath = 'managements.';

const apis = {
  fetchAll: '/api-patient/get-patient-discharge',
  postDischarge: '/api-patient/update-patient-discharge',
};

// initialState is the initial state of the slice
const initialState = {
  data: [],
  dischargeDataMenu: [],
  loading: false,
  saveData: null,
  saveLoading: false,
  status: null,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setDischargeDataMenu: (state, action) => {
      state.dischargeDataMenu = action.payload;
    },
    setSaveData: (state, action) => {
      state.saveData = action.payload;
    },
    setSaveLoading: (state, action) => {
      state.saveLoading = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

const fetchDischargePatient = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.fetchAll, data);
    // console.log(response.data);
    dispatch(slice.actions.setData(response.data?.data));
    dispatch(slice.actions.setStatus(response?.data?.data?.status));
    dispatch(slice.actions.setDischargeDataMenu(response?.data?.discharge_menu_data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const saveDischargeApi = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setSaveLoading(true));
    const response = await axios.post(apis.postDischarge, data);

    if (response?.data?.data.status !== 0) {
      dispatch(slice.actions.setSaveData(response?.data));
      dispatch(slice.actions.setStatus(response?.data?.data.status));

      // // Get Patient Data Again after saving
      // const patientData = await axios.post(apis.fetchAll, data);

      // dispatch(slice.actions.setData(response.data?.data));
    } else {
      dispatch(
        slice.actions.setError(
          'Failed to save discharge doctor information. Please try again later.'
        )
      );
    }
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setSaveLoading(false));
  }
};

const promiseActions = {
  saveDischargeApi,
  fetchDischargePatient,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
