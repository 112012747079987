import { Box, Divider, IconButton, Stack, TextField, Typography } from '@mui/material';

// redux
import { dispatch } from 'redux/store';
import laborResultOverlay from 'redux/slices/managements/labor-result-overlay';

import Iconify from 'components/iconify';
import Scrollbar from 'components/scrollbar/Scrollbar';
import globalResult from 'redux/slices/managements/global-result';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { json2FormData } from 'utils/formdata';
import { imagingColumn } from './data-grid/imaging-column';
import ToolbarImagingResult from './toolbar-imaging-result';

export default function ImagingResultOverlay() {
  const { openImaging } = globalResult.selector();

  const { imagingData } = laborResultOverlay.selector();

  const [searchQuery, setSearchQuery] = useState('');
  const handleClose = () => {
    dispatch(globalResult.promiseActions.openImagingBox(false));
  };

  const countIsSeenZero = imagingData
    ? imagingData?.filter((item) => item.is_seen === 0).length
    : 0;

  // console.log(countIsSeenZero);
  useEffect(() => {
    if (openImaging === true) {
      dispatch(
        laborResultOverlay.promiseActions.fetchImagingGlobalResult(
          json2FormData({ object_type: 'IMAGING' })
        )
      );
    }
  }, [openImaging]);

  console.log(imagingData);

  // Filtering the data based on the search query
  const filteredData =
    imagingData?.filter((item) =>
      item.patient_info.fullname.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      {/* <ToolbarImagingResult viewType={2} count={countIsSeenZero} /> */}
      <Stack
        sx={{
          position: 'fixed',
          bottom: 16,
          // right: '38%',
          right: '48%',
          width: '530px',
          height: '512px',
          transition: 'all 0.4s ease-in-out',
          backgroundColor: 'white',
          borderRadius: 2,
          boxShadow: 6,
          overflow: 'hidden',
          opacity: 1,
          zIndex: 2005,
          transform:
            openImaging === true
              ? `translateY(0%) translateX(50%) scale(1)`
              : `translateY(50%) translateX(50%) scale(0)`,
          // open
          //   ? `translateY(0%) translateX(50%) scale(1)`
          //   : `translateY(50%) translateX(50%) scale(0)`,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            transition: 'all 0.2s ease-in-out',
            //   transform: `translateX(${selectedChat ? -330 : 0}px)`,
            width: '660px',
            position: 'relative',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              pt: 2,
              px: 2,
              pb: 1,
              width: '80%',
            }}
          >
            <Box />
            <Typography variant="h6">Result Imaging</Typography>
            <IconButton color="#000" onClick={() => handleClose()}>
              <Iconify icon="mdi:close" />
            </IconButton>
          </Stack>
        </Stack>
        {/* <Stack sx={{ px: 1, pb: 1 }}>
          <TextField
            label="Search"
            fullWidth
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
            type="search"
          />
        </Stack> */}
        <Scrollbar
          sx={{
            borderTop: '1px solid',
            borderColor: 'divider',
            height: '506px',
          }}
        >
          <Stack
            divider={
              <Divider
                sx={{
                  width: '270px',
                  ml: 'auto',
                }}
              />
            }
            sx={{
              height: 400,
              width: '100%',
            }}
          >
            <DataGrid
              getRowId={(row, index) => `${row?.id}-${index}`}
              rows={filteredData}
              columns={imagingColumn}
              //   processRowUpdate={processRowUpdate}
              disableRowSelectionOnClick
              rowHeight={80}
              sx={{
                '& .MuiDataGrid-cell': {
                  padding: '0 20px',
                },
                '& .MuiDataGrid-columnHeader': {
                  padding: '0 20px',
                },
              }}
            />
          </Stack>
        </Scrollbar>
      </Stack>
    </>
  );
}
