import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

// depthPath is the path to the slice from the root reducer end with a dot (.)
const name = 'patientMedicalPackage';
const depthPath = 'managements.';

const apis = {
  getOne: '/api-medical-setting/get-allery-sheet-type',
  getAll: '/api-patient/get-all',
  add_edit: '/api-medical-setting/add-edit-allery-sheet-type',
  delete: '/api-medical-setting/delete-allery-sheet-type',
};

// initialState is the initial state of the slice
const initialState = {
  data: [],
  loading: false,
  currentData: null,
  open: {
    create: false,
    delete: false,
  },
};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCurrentData: (state, action) => {
      state.currentData = action.payload;
    },
    setOpenDialog: (state, action) => {
      const { target, value } = action.payload;
      state.open[target] = value;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

const fetchAllPatientMedicalPackage = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAll, data);
    dispatch(slice.actions.setData(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};
const addEditAllergySheetType = (data) => async (dispatch) => {
  try {
    const response = await axios.post(apis.add_edit, data);
    if (response.status === 200) dispatch(slice.actions.setData(response.data?.data));
    return { status: true, message: response?.data?.message };
  } catch (error) {
    return { status: false, message: error.message };
  }
};
const deleteAllergySheetType = (id) => async (dispatch) => {
  try {
    const response = await axios.delete(`${apis.delete}/${id}`);
    if (response.status === 200) dispatch(slice.actions.setData(response.data?.data));
    return { status: true, message: response?.data?.message };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

const promiseActions = {
  fetchAllPatientMedicalPackage,
  addEditAllergySheetType,
  deleteAllergySheetType,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
