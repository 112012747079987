import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

// depthPath is the path to the slice from the root reducer end with a dot (.)
const name = 'patient-visit-procedure-type';
const depthPath = 'managements.';

const apis = {
  getAll: '/api-patient/get-patient-procedure-type',
  addProcedure: '/api-patient/add-patient-procedure-type',
  delete: '/api-patient/delete-patient-procedure-by-patient-procedure-type-id',
};

// initialState is the initial state of the slice
const initialState = {
  procedureData: [],
  loading: false,
  addData: [],
  deleteData: [],
};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.procedureData = action.payload;
    },
    setAddData: (state, action) => {
      state.addData = action.payload;
    },
    setDeleteData: (state, action) => {
      state.deleteData = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
    setOpenCreateDialog: (state, action) => {
      state.openCreateDialog = action.payload;
    },
    setCurrentEditData: (state, action) => {
      state.currentEditData = action.payload;
    },
  },
});

// promiseActions are the actions that are dispatched by the components
const fetchAllVisitProcedure = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAll, data);
    const reversedData = response.data?.data ? [...response.data.data].reverse() : [];

    dispatch(slice.actions.setData(reversedData));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const addVisitPatientProcedure = (data) => async (dispatch, getState) => {
  try {
    await axios.post(apis.addProcedure, data);
  } catch (error) {
    dispatch(slice.actions.setError(error));
  }
};

const deleteVisitPateintProcedure = (data) => async (dispatch, getState) => {
  try {
    await axios.post(apis.delete, data);
  } catch (error) {
    dispatch(slice.actions.setError(error));
  }
};

const promiseActions = {
    fetchAllVisitProcedure,
    addVisitPatientProcedure,
    deleteVisitPateintProcedure
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
