import axios from 'axios';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

const name = 'balancesheetlist';
const depthPath = 'managements.';

const apis = {
  getAllBalance: '/api-finance/get-all-financial-balance-sheets',
  addBalance: '/api-finance/add-financial-balance-sheet',
  editBalance: '/api-finance/update-financial-balance-sheet/',
  deleteBalance: '/api-finance/delete-financial-balance-sheet/',

  // Asset
  getAllAsset: '/api-finance/get-all-financial-assets',
  addAsset: '/api-finance/add-financial-asset',
  editAsset: '/api-finance/update-financial-asset/',
  deleteAsset: '/api-finance/delete-financial-asset/',

  // Liability
  getAllLiability: '/api-finance/get-all-financial-liabilities',
  addLiability: '/api-finance/add-financial-liability',
  editLiability: '/api-finance/update-financial-liability/',
  deleteLiability: '/api-finance/delete-financial-liability/',

  // Equity
  getAllEquity: '/api-finance/get-all-financial-equities',
  addEquity: '/api-finance/add-financial-equity',
  editEquity: '/api-finance/update-financial-equity/',
  deleteEquity: '/api-finance/delete-financial-equity/',
};

const initialState = {
  data: [],
  currentData: {},

  openDrawer: {
    add: false,
    edit: false,
    delete: false,
    detail: false,
    nameOpen: null,
  },
  assetData: null,
  assetType: null,
  assetLoading: false,
  addAssetLoading: false,
  deleteAssetLoading: false,

  liabilityData: null,
  liabilityType: null,
  liabilityLoading: false,
  addLiabilityLoading: false,
  deleteLiabilityLoading: false,

  equityData: null,
  equityType: null,
  equityLoading: false,
  addEquityLoading: false,
  deleteEquityLoading: false,

  isDetail: false,
  loading: false,
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setCurrentData: (state, action) => {
      state.currentData = action.payload;
    },
    setIsDetail: (state, action) => {
      state.isDetail = action.payload;
    },

    // Asset
    setAssetData: (state, action) => {
      state.assetData = action.payload;
    },
    setAssetType: (state, action) => {
      state.assetType = action.payload;
    },
    setAssetLoading: (state, action) => {
      state.assetLoading = action.payload;
    },
    setAddAssetLoading: (state, action) => {
      state.addAssetLoading = action.payload;
    },
    setDeleteAssetLoading: (state, action) => {
      state.deleteAssetLoading = action.payload;
    },

    // Liability
    setLiabilityData: (state, action) => {
      state.liabilityData = action.payload;
    },
    setLiabilityType: (state, action) => {
      state.liabilityType = action.payload;
    },
    setLiabilityLoading: (state, action) => {
      state.liabilityLoading = action.payload;
    },
    setAddLiabilityLoading: (state, action) => {
      state.addLiabilityLoading = action.payload;
    },
    setDeleteLiabilityLoading: (state, action) => {
      state.deleteLiabilityLoading = action.payload;
    },

    // Equity
    setEquityData: (state, action) => {
      state.equityData = action.payload;
    },
    setEquityType: (state, action) => {
      state.equityType = action.payload;
    },

    setEquityLoading: (state, action) => {
      state.equityLoading = action.payload;
    },
    setAddEquityLoading: (state, action) => {
      state.addEquityLoading = action.payload;
    },

    setDeleteEquityLoading: (state, action) => {
      state.deleteEquityLoading = action.payload;
    },

    // Meta ß
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAddLoading: (state, action) => {
      state.addLoading = action.payload;
    },
    setEditLoading: (state, action) => {
      state.editLoading = action.payload;
    },
    setDeleteLoading: (state, action) => {
      state.deleteLoading = action.payload;
    },
    setOpenDrawer: (state, action) => {
      const { target, value, nameOpen } = action.payload;
      state.openDrawer[target] = value;
      if (nameOpen) {
        // Handle the name if needed
        state.openDrawer.nameOpen = nameOpen;
      }
    },

    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

const getAllBalance = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAllBalance, data);
    dispatch(slice.actions.setData(response.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const addBalanceSheet = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setAddLoading(true));
    const response = await axios.post(apis.addBalance, data);
    dispatch(slice.actions.setData(response?.data?.data));
    dispatch(slice.actions.setAddLoading(false));
    dispatch(slice.actions.setIsDetail(true));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setAddLoading(false));
  }
};

const updateBalance = (balanceId, data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setAddLoading(true));
    await axios.put(apis.editBalance + balanceId, data);
    dispatch(slice.actions.setAddLoading(false));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setAddLoading(false));
  }
};

const deleteBalanceSheet = (id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setDeleteLoading(true));
    await axios.delete(apis.deleteBalance + id);
    // await dispatch(getAllBalance());
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setDeleteLoading(false));
  }
};

// Asset
const getAllAssetBalance = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setAssetLoading(true));
    const response = await axios.post(apis.getAllAsset, data);
    console.log(response.data);
    dispatch(slice.actions.setAssetType(response.data?.asset_type_data));
    dispatch(slice.actions.setAssetData(response.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setAssetLoading(false));
  }
};

const addAssetBalance = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setAddAssetLoading(true));
    const response = await axios.post(apis.addAsset, data);
    dispatch(slice.actions.setAddAssetLoading(false));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setAddAssetLoading(false));
  }
};

const updateAssetBalance = (assetId, data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setAddAssetLoading(true));
    const response = await axios.put(apis.editAsset + assetId, data);
    dispatch(slice.actions.setAddAssetLoading(false));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setAddAssetLoading(false));
  }
};

const deleteAssetBalance =
  ({ assetId, data }) =>
  async (dispatch, getState) => {
    try {
      const currentState = getState();
      const deleteData = currentState.managements.balancesheetlist.assetData.filter(
        (assetItem) => assetItem.id !== assetId
      );
      dispatch(slice.actions.setDeleteAssetLoading(true));
      await axios.delete(apis.deleteAsset + assetId, data);
      dispatch(slice.actions.setDeleteAssetLoading(false));
      dispatch(slice.actions.setAssetData(deleteData));
    } catch (error) {
      dispatch(slice.actions.setError(error));
    } finally {
      dispatch(slice.actions.setDeleteAssetLoading(false));
    }
  };

// Liability
const getAllLiabilityBalance = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLiabilityLoading(true));
    const response = await axios.post(apis.getAllLiability, data);
    console.log(response.data);
    dispatch(slice.actions.setLiabilityType(response.data?.liability_type_data));
    dispatch(slice.actions.setLiabilityData(response.data?.data));

    dispatch(slice.actions.setLiabilityLoading(false));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLiabilityLoading(false));
  }
};

const addLiabilityBalance = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setAddLiabilityLoading(true));
    const response = await axios.post(apis.addLiability, data);
    dispatch(slice.actions.setAddLiabilityLoading(false));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setAddLiabilityLoading(false));
  }
};

const updateLiabilityBalance = (assetId, data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setAddLiabilityLoading(true));
    const response = await axios.put(apis.editLiability + assetId, data);
    dispatch(slice.actions.setAddLiabilityLoading(false));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setAddLiabilityLoading(false));
  }
};

const deleteLiabilityBalance =
  ({ liabilityId, data }) =>
  async (dispatch, getState) => {
    try {
      const currentState = getState();
      const deleteData = currentState.managements.balancesheetlist.assetData.filter(
        (liabilityItem) => liabilityItem.id !== liabilityId
      );
      dispatch(slice.actions.setDeleteAssetLoading(true));
      await axios.delete(apis.deleteLiability + liabilityId, data);
      dispatch(slice.actions.setDeleteAssetLoading(false));
      dispatch(slice.actions.setLiabilityData(deleteData));
    } catch (error) {
      dispatch(slice.actions.setError(error));
    } finally {
      dispatch(slice.actions.setDeleteAssetLoading(false));
    }
  };

// Equity
const getAllEquityBalance = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setEquityLoading(true));
    const response = await axios.post(apis.getAllEquity, data);
    console.log(response.data);
    dispatch(slice.actions.setEquityType(response.data?.equity_type_data));
    dispatch(slice.actions.setEquityData(response.data?.data));

    dispatch(slice.actions.setEquityLoading(false));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setEquityLoading(false));
  }
};

const addEquityBalance = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setAddEquityLoading(true));
    const response = await axios.post(apis.addEquity, data);
    dispatch(slice.actions.setAddEquityLoading(false));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setAddEquityLoading(false));
  }
};

const updateEquityBalance = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setAddEquityLoading(true));
    const response = await axios.put(apis.editEquity, data);
    dispatch(slice.actions.setAddEquityLoading(false));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setAddEquityLoading(false));
  }
};

const deleteEquityBalance =
  ({ equityId, data }) =>
  async (dispatch, getState) => {
    try {
      const currentState = getState();
      const deleteData = currentState.managements.balancesheetlist.assetData.filter(
        (equityItem) => equityItem.id !== equityId
      );
      dispatch(slice.actions.setDeleteAssetLoading(true));
      await axios.delete(apis.deleteLiability + equityId, data);
      dispatch(slice.actions.setDeleteAssetLoading(false));
      // dispatch(slice.actions.setEquityData(deleteData));
    } catch (error) {
      dispatch(slice.actions.setError(error));
    } finally {
      dispatch(slice.actions.setDeleteAssetLoading(false));
    }
  };

const promiseActions = {
  getAllBalance,
  addBalanceSheet,
  updateBalance,
  deleteBalanceSheet,

  // Asset
  getAllAssetBalance,
  addAssetBalance,
  updateAssetBalance,
  deleteAssetBalance,

  // Liability
  getAllLiabilityBalance,
  addLiabilityBalance,
  updateLiabilityBalance,
  deleteLiabilityBalance,

  // Equity
  getAllEquityBalance,
  addEquityBalance,
  updateEquityBalance,
  deleteEquityBalance,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
