import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

const name = 'laborTestTemplet';
const depthPath = 'managements.';

const apis = {
  getAll: '/api-laboratory/get-all-labor-test-templates',
  add: '/api-laboratory/add-labor-test-template',
  edit: '/api-laboratory/edit-labor-test-template',
  delete: '/api-laboratory/delete-labor-test-template',
  getOne: '/api-laboratory/get-labor-test-template',
};

const initialState = {
  data: [],
  loading: false,
  option: {
    UNIT_DATA: null,
    TEST_TYPE: null,
    TEST_TYPE_VALUE: null,
    REFERENCE_RANGE_PEDIATRIC: null,
    REFERENCE_RANGE_ADULT_MALE: null,
    REFERENCE_RANGE_ADULT_FEMALE: null,
  },
  TRILL_CHILD: 3,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setOption: (state, action) => {
      const {
        unit,
        test_type,
        reference_range_pediatric_data,
        reference_range_adult_male_data,
        reference_range_adult_female_data,
      } = action.payload;
      state.option.UNIT_DATA = unit;
      state.option.TEST_TYPE = test_type;
      state.option.REFERENCE_RANGE_PEDIATRIC = reference_range_pediatric_data;
      state.option.REFERENCE_RANGE_ADULT_FEMALE = reference_range_adult_female_data;
      state.option.REFERENCE_RANGE_ADULT_MALE = reference_range_adult_male_data;
    },
    setTestTypeValue: (state, action) => {
      state.option.TEST_TYPE_VALUE = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

const fecthAlllaborTemplet = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAll, data);
    // dispatch(slice.actions.setData(response.data?.data));
    dispatch(
      slice.actions.setOption({
        unit: response.data?.units_data,
        test_type: response.data?.labor_test_type_data,
        reference_range_pediatric_data: response.data?.reference_range_pediatric_data,
        reference_range_adult_female_data: response.data?.reference_range_adult_female_data,
        reference_range_adult_male_data: response.data?.reference_range_adult_male_data,
      })
    );
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};
const fecthLaborTempletByTestType = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAll, data);
    dispatch(slice.actions.setData(response.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};
const createlaborTemplet = (data, add_child) => async (dispatch) => {
  try {
    const response = await axios.post(apis.add, data);
    if (response.status === 200) {
      if (!add_child) dispatch(slice.actions.setData(response.data?.data));
      else {
        const res = await axios.post(apis.getAll, null);
        dispatch(slice.actions.setData(res.data?.data));
      }
    }
    return { status: true, message: response?.data?.message };
  } catch (error) {
    return { status: false, message: error.message };
  }
};
const updatelaborTemplet = (data, id) => async (dispatch) => {
  try {
    const response = await axios.put(`${apis.edit}/${id}`, data);
    if (response.status === 200) {
      const res = await axios.post(apis.getAll, null);
      dispatch(slice.actions.setData(res.data?.data));
    }
    return { status: true, message: response?.data?.message };
  } catch (error) {
    return { status: true, message: error?.data?.message };
  }
};
const deletelaborTemplet = (id) => async (dispatch) => {
  try {
    const response = await axios.delete(`${apis.delete}/${id}`);
    if (response.status === 200) dispatch(slice.actions.setData(response.data?.data));
    return { status: true, message: response?.data?.message };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

const promiseActions = {
  fecthAlllaborTemplet,
  fecthLaborTempletByTestType,
  createlaborTemplet,
  updatelaborTemplet,
  deletelaborTemplet,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
