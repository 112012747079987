import { combineReducers } from 'redux';
import visitTypeSlice from './visit-type';
import allergyTypeSlice from './allergy-type';
import settingSlice from './setting';
import patientSettingSlice from './patient-setting';
import laboratoryTestSheetSlice from './laboratory-test-sheet';
import medicalDiagnosisSheetSlice from './medical-diagnosis-sheet';
import investigationSlice from './investigation-setting';
import medicalPackageSlice from './medical-package-setting';
import medicalSettingSlice from './medical-setting';
import procedureTypeSlice from './procedure-type';
import serviceTypeSlice from './service-type';
import vaccinationTypeSlice from './vaccination-type';
import allergySheetTypeSlice from './allergy-sheet-type';
import medicalSupplyTypeSlice from './medical-supply-type';
import medicalRecordLetterSlice from './medical-record-letter';
import operationTypeSlice from './operation-type';

export default combineReducers({
  ...visitTypeSlice.reducer,
  ...allergyTypeSlice.reducer,
  ...settingSlice.reducer,
  ...patientSettingSlice.reducer,
  ...laboratoryTestSheetSlice.reducer,
  ...medicalDiagnosisSheetSlice.reducer,
  ...investigationSlice.reducer,
  ...medicalSettingSlice.reducer,
  ...serviceTypeSlice.reducer,
  ...procedureTypeSlice.reducer,
  ...vaccinationTypeSlice.reducer,
  ...allergySheetTypeSlice.reducer,
  ...medicalSupplyTypeSlice.reducer,
  ...medicalRecordLetterSlice.reducer,
  ...operationTypeSlice.reducer,
  ...medicalPackageSlice.reducer,
});
