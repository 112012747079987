import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Alert,
  Autocomplete,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

import { HOST_API_URL } from 'config-global';
import { setKeyValue } from 'utils/config';
// routes
import { PATH_AUTH } from '../../routes/paths';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// components
import Iconify from '../../components/iconify';
import FormProvider, { RHFAutocomplete, RHFTextField } from '../../components/hook-form';
import CustomLanguage from '../../components/custom-language';
import { isEmpty } from 'lodash';

// ----------------------------------------------------------------------

const URL_LIST_CLINIC = `${HOST_API_URL}/api-admin/get-customer-info`;

export default function AuthLoginForm() {
  const { login } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listClinic, setListClinic] = useState([]);

  const LoginSchema = Yup.object().shape({
    clinic: Yup.mixed().required('Clinic is required'),
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    clinic: null,
    username: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await login(data.username, data.password, data?.clinic?.secret_key);
    } catch (error) {
      console.error(error);
      reset();
      setError('afterSubmit', {
        ...error,
        message: error.message,
      });
    }
  };

  // const initListClinic = async () => {
  //   setLoading(true);
  //   try {
  //     const res = await axiosInstance.get(URL_LIST_CLINIC);

  //     if (res?.status !== 200) {
  //       enqueueSnackbar(res?.message || 'SERVER ERROR !', { variant: 'error' });
  //     }
  //     setListClinic(res?.data?.data || []);
  //   } catch (error) {
  //     enqueueSnackbar(error?.message || 'SERVER ERROR !', { variant: 'error' });
  //     console.error(error);
  //   }
  //   setLoading(false);
  // };

  const initListClinic = async () => {
    setLoading(true);
    try {
      const url = URL_LIST_CLINIC;
      const response = await fetch(url);

      if (!response.ok) {
        const errorResponse = await response.json();
        enqueueSnackbar(errorResponse?.message || 'SERVER ERROR !', { variant: 'error' });
      } else {
        const data = await response.json();
        const clinics = data?.data || [];
        setListClinic(clinics);
        setValue('clinic', !isEmpty(clinics) ? clinics[0] : null);
      }
    } catch (error) {
      enqueueSnackbar(error?.message || 'SERVER ERROR !', { variant: 'error' });
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    initListClinic();
  }, []);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <Autocomplete
          value={getValues('clinic')}
          options={listClinic || []}
          getOptionLabel={(option) => option?.customer_name}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!errors.clinic}
              helperText={!!errors.clinic && errors.clinic?.message}
              label="Clinic"
            />
          )}
          onChange={(e, val) => {
            setValue('clinic', val);
            console.log(val);
          }}
        />

        <RHFTextField name="username" label="Username" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <CustomLanguage />
      </Stack>

      <Stack alignItems="center" sx={{ my: 2 }}>
        <Link
          component={RouterLink}
          // update link from forget password to vhs link
          to="https://vibolrith-health-system.com/"
          target="_blank"
          variant="body2"
          fontWeight={700}
          // color="p"
          underline="always"
        >
          VISIT OUR WEBSITE
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        color="primary"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitSuccessful || isSubmitting}
        // sx={{
        //   bgcolor: 'text.primary',
        //   color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
        //   '&:hover': {
        //     bgcolor: 'text.primary',
        //     color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
        //   },
        // }}
      >
        Login
      </LoadingButton>
    </FormProvider>
  );
}
