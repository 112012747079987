import { Box, Divider, IconButton, Stack, TextField, Typography } from '@mui/material';

// redux
import globalResult from 'redux/slices/managements/global-result';
import { dispatch } from 'redux/store';
import Iconify from 'components/iconify';
import Scrollbar from 'components/scrollbar/Scrollbar';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { json2FormData } from 'utils/formdata';
import laborResultOverlay from '../../redux/slices/managements/labor-result-overlay';
import ToolbarLaborResult from './toolbar-labor-result';
import { columns } from './data-grid/column';

export default function LaborResultOverlay() {
  const [search, setSearch] = useState('');
  const [dataRow, setRows] = useState([]);
  const { data } = laborResultOverlay.selector();
  const { openLabor } = globalResult.selector();

  const handleClose = () => {
    dispatch(globalResult.promiseActions.openLabarBox(false));
  };

  useEffect(() => {
    if (openLabor === true) {
      dispatch(
        laborResultOverlay.promiseActions
          .fetchLaborGlobalResult
          // json2FormData({ object_type: 'IMAGING' })
          ()
      );
    }
  }, [openLabor]);

  useEffect(() => {
    setRows(data);
  }, []);

  console.log(data);

  const rowss = data
    ? data?.map((item, index) => ({
        patient_visit_id: item.patient_visit_id,
        patient_current_ipd_id:item.patient_current_ipd_id,
        id: item.patient_investigation_id,
        no: index + 1,
        patient_info: {
          fullname: item.patient_info.fullname,
          fullname_latin: item.patient_info.fullname_latin,
          gender: item.patient_info.gender,
          personal_card_id: item.patient_info.personal_card_id,
        },
        investigation_type: item.investigation_type,
        is_seen: item.is_seen,
      }))
    : [];

  // const countIsSeenZero = data ? data?.filter((item) => item.is_seen === 0).length : 0;

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearch(searchTerm);
    // Filtering rows based on name, phone, or age

    console.log(search);
    console.log(data);
    const filteredRows = data?.filter((row) =>
      row.patient_info.fullname_latin.toLowerCase().includes(searchTerm) || row.patient_info.fullname.toLowerCase().includes(searchTerm)
    );

    setRows(filteredRows);
  };

  return (
    <>
      {/* <ToolbarLaborResult viewType={2} count={countIsSeenZero} /> */}
      <Stack
        sx={{
          position: 'fixed',
          bottom: 16,
          // right: '38%',
          // left: '15%',
          right: '48%',
          width: '530px',
          height: '512px',
          transition: 'all 0.4s ease-in-out',
          backgroundColor: 'white',
          borderRadius: 2,
          boxShadow: 6,
          overflow: 'hidden',
          opacity: 1,
          zIndex: 2005,
          transform: openLabor
            ? `translateY(0%) translateX(50%) scale(1)`
            : `translateY(50%) translateX(50%) scale(0)`,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            transition: 'all 0.2s ease-in-out',
            //   transform: `translateX(${selectedChat ? -330 : 0}px)`,
            width: '660px',
            position: 'relative',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              pt: 2,
              px: 2,
              pb: 1,
              width: '80%',
            }}
          >
            <Box />
            <Typography variant="h6" textAlign="center">
              Result Laboratory
            </Typography>
            <IconButton color="#000" onClick={() => handleClose()}>
              <Iconify icon="mdi:close" />
            </IconButton>
          </Stack>
        </Stack>
        {/* <Stack sx={{ px: 1, pb: 1 }}>
          <TextField
            label="Search"
            fullWidth
            size="small"
            value={search}
            onChange={handleSearch}
            type="search"
          />
        </Stack> */}
        <Scrollbar
          sx={{
            borderTop: '1px solid',
            borderColor: 'divider',
            height: '506px',
          }}
        >
          <Stack
            divider={
              <Divider
                sx={{
                  width: '270px',
                  ml: 'auto',
                }}
              />
            }
            sx={{
              height: 400,
              width: '100%',
            }}
          >
            <DataGrid
              rows={rowss}
              columns={columns}
              
            
              //   processRowUpdate={processRowUpdate}
              disableRowSelectionOnClick
              rowHeight={80}
              sx={{
                '& .MuiDataGrid-cell': {
                  padding: '0 20px',
                },
                '& .MuiDataGrid-columnHeader': {
                  padding: '0 20px',
                },
              }}
            />
          </Stack>
        </Scrollbar>
      </Stack>
    </>
  );
}

