import { lazy, Suspense } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

export const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

export const LoadableDialog = (Component) => (props) =>
  (
    <Suspense fallback={null}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));

// SETTINGS
export const VisitTypePage = Loadable(
  lazy(() => import('../pages/main/health-care-setting/visit-type'))
);
export const AllergyTypePage = Loadable(
  lazy(() => import('../pages/main/health-care-setting/allergy-type'))
);
export const HealthCareServiceCategoryPage = Loadable(
  lazy(() => import('../pages/main/health-care-setting/health-care-service-category'))
);
export const PharmaceuticalListPage = Loadable(
  lazy(() => import('../pages/main/health-care-setting/pharmaceutical-list'))
);
export const ImmunizationListPage = Loadable(
  lazy(() => import('../pages/main/health-care-setting/immunization-list'))
);
export const DiagnosisListPage = Loadable(
  lazy(() => import('../pages/main/health-care-setting/diagnostic-list'))
);
export const BranchPage = Loadable(lazy(() => import('../pages/main/health-care-setting/branch')));
export const ClinicPage = Loadable(lazy(() => import('../pages/main/health-care-setting/clinic')));

// BLANK PAGE
export const BlankPage = Loadable(lazy(() => import('../pages/main/blank-page')));

// TEST RENDER PAGE BY ROLE
export const PermissionDeniedPage = Loadable(lazy(() => import('../pages/main/page-401')));

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));

// GUEST VIEW
export const UpdatePatientInfoFromTG = Loadable(lazy(() => import('../pages/guest/update-info')));

export const GuestInfo = Loadable(lazy(() => import('../pages/main/guest-info/index')));
