import { Badge, Button, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import getSocket from 'utils/socket';

// redux
import globalResult from 'redux/slices/managements/global-result';
import investigationApp from 'redux/slices/managements/investigation-socket-app';

import Iconify from 'components/iconify';
import { useEffect, useState } from 'react';
import { dispatch } from 'redux/store';

export default function ToolbarLaborResult({ viewType = 1, count = 0 }) {
  const { openLabor } = globalResult.selector();
  const { individualLabSocket } = investigationApp.selector();

  const [resultComing, setResultComing] = useState(false);

  const handleOpenResult = () => {
    dispatch(globalResult.promiseActions.openLabarBox(true));
    setResultComing(false);
    dispatch(investigationApp.actions.setIndividualLabSocket(false));
  };

  useEffect(() => {
    if(individualLabSocket===true){
      setResultComing(true);
      startCloseTimer();
    }
   
  }, [individualLabSocket]);

  const startCloseTimer = () => {
    setTimeout(() => {
      setResultComing(false);
      dispatch(investigationApp.actions.setIndividualLabSocket(false));
    }, 30000); // 30 seconds   // 120000 ms =  minutes
  };

  return (
    <Stack
      sx={{
        // position: 'fixed',
        // bottom: 10,
        // left: '45%',
        transition: 'all 0.4s ease-in-out',
        height: '40px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px',
        boxShadow: 6,
        cursor: 'pointer',
        backgroundColor: 'white',
        opacity: openLabor === false ? 1 : 0,
        zIndex: openLabor === false ? 2000 : -1,
        // transform:
        //   open === false
        //     ? `translateY(-10%) translateX(50%) scale(1)`
        //     : `translateY(50%) translateX(50%) scale(0)`,
      }}
      //   onClick={() => dispatch(laborResultOverlay.promiseActions.openLaborDialog(!open))}
    >
      <Badge
        badgeContent={count}
        color="error"
        sx={{
          '& .MuiBadge-badge': {
            mt: -0.5,
            mr: -0.5,
          },
        }}
      >
        <Button
          sx={{
            p: 2,
            backgroundColor: resultComing ? '#FF8B00' : 'transparent',
            color: resultComing ? 'white' : 'primary',
            border: '1px solid',
            height: '40px',
            transition: 'background-color 0.1s ease-in-out',
            '@keyframes blink': {
              '30%': { backgroundColor: 'primary' },
              '50%': { backgroundColor: 'transparent' },
              '100%': { backgroundColor: 'primary' },
            },
            animation: resultComing ? 'blink 1s infinite' : 'none',
          }}
          size="small"
          // Main Function for open LaborDialog

          onClick={() => handleOpenResult()}
          variant={resultComing === true ? 'contained' : 'outlined'}
        >
          <Iconify icon="fa6-solid:x-ray" />
          {/* RESULT LABORATORY */}
        </Button>
      </Badge>
    </Stack>
  );
}

ToolbarLaborResult.propTypes = {
  viewType: PropTypes.number,
  count: PropTypes.number,
};
// <Button
//   ref={invCardRefResult}
//   sx={{
//     backgroundColor: status ? '#FF8B00' : 'transparent',
//     color: status ? 'white' : 'primary',
//     border: '1px solid',
//     transition: 'background-color 0.1s ease-in-out',
//     '@keyframes blink': {
//       '30%': { backgroundColor: 'primary' },
//       '50%': { backgroundColor: 'transparent' },
//       '100%': { backgroundColor: 'primary' },
//     },
//     animation: status ? 'blink 1s infinite' : 'none',
//   }}
//   size="small"
//   onClick={handleOpenResult}
//   variant={status === true ? 'contained' : 'outlined'}
//   startIcon={<Iconify icon="carbon:result-new" />}
// >
//   Result
// </Button>;
