import axios from 'axios';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

// depthPath is the path to the slice from the root reducer end with a dot (.)
const name = 'patient-visit-operation';
const depthPath = 'managements.';

const apis = {
  getAll: '/api-medical-setting/get-all-operation-types',
  addEditOperation: '/api-medical-setting/add-edit-operation-type',
  deleteOperation: '/api-medical-setting/delete-operation-type',
};

// initialState is the initial state of the slice
const initialState = {
  operationData: [],
  loading: false,
  addData: [],
  deleteData: [],
};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setOption: (state, action) => {
      state.operationData = action.payload;
    },
    setData: (state, action) => {
      state.procedureData = action.payload;
    },
    setAddData: (state, action) => {
      state.addData = action.payload;
    },
    setDeleteData: (state, action) => {
      state.deleteData = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
    setOpenCreateDialog: (state, action) => {
      state.openCreateDialog = action.payload;
    },
    setCurrentEditData: (state, action) => {
      state.currentEditData = action.payload;
    },
  },
});

// promiseActions are the actions that are dispatched by the components
const fetchAllVisitOperation = (data) => async (dispatch, getState) => {
  let res=null;
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAll, data);
    res = response.data?.data ? [...response.data.data]?.reverse() : [];

    dispatch(slice.actions.setOption(res));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
  return res;
};

const addVisitPatientOperation = (data) => async (dispatch, getState) => {
  try {
    await axios.post(apis.addEditOperation, data);
  } catch (error) {
    dispatch(slice.actions.setError(error));
  }
};

const deleteVisitPateintOperation = (data) => async (dispatch, getState) => {
  try {
    await axios.post(apis.deleteOperation, data);
  } catch (error) {
    dispatch(slice.actions.setError(error));
  }
};

const promiseActions = {
    fetchAllVisitOperation,
    addVisitPatientOperation,
    deleteVisitPateintOperation
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
