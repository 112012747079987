import { Box, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import Logo from 'components/logo/Logo';
import { getClinic } from 'utils/config';

export default function MedicalHeader({ onClose }) {
  const clinic = getClinic();
  console.log(clinic?.name);
  return (
    <Stack direction="row"  justifyContent="space-between" sx={{ mx: 2.5, my: 3 }}>
      <Stack direction="row">
        <Logo />
        <Box width="20px" />
        <Typography variant="h6"  textAlign='center'>
          {clinic?.name || '---'}
        </Typography>
      </Stack>

      <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
        <CloseIcon />
      </IconButton>
    </Stack>
  );
}

MedicalHeader.propTypes = {
  onClose: PropTypes.func,
};
