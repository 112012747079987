// Constants ================================================================
const CLINIC_KEY = 'clinic';
const USER_KEY = 'user';
// Functions ================================================================
export const setKeyValue = (key, value, storage = localStorage) => {
  if (value) {
    storage.setItem(key, JSON.stringify(value));
  } else {
    storage.removeItem(key);
  }
};

export const getKeyValue = (key, storage = localStorage) => {
  const data = storage.getItem(key);
  // console.log(localStorage);
  return data ? JSON.parse(data) : null;



};

export const setClinic = (clinic) => setKeyValue(CLINIC_KEY, clinic, localStorage);

export const getClinic = () => getKeyValue(CLINIC_KEY, localStorage);


export const setWithExpiry = (key, value, ttl) => {
  const now = new Date();
  
  // `ttl` is the time-to-live in milliseconds
  const item = {
    value,
    expiry: now.getTime() + ttl,
  };

  // setKeyValue(key, value, localStorage)
  localStorage.setItem(key, JSON.stringify(item));
};

// Utility function to get data from local storage
export const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);
  
  if (!itemStr) {
    return null;
  }
  
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  
  return item.value;
};


console.log(getKeyValue(CLINIC_KEY, localStorage.profile_data));

export const getClinicWorkingTime = () => {
  const clinic = getClinic();
  const { break_time_end, break_time_start, working_time_start, working_time_end } = clinic;

  const break_time_start_hour = parseInt(break_time_start.split(':')[0], 10);
  const break_time_start_minute = parseInt(break_time_start.split(':')[1], 10);

  const break_time_end_hour = parseInt(break_time_end.split(':')[0], 10);
  const break_time_end_minute = parseInt(break_time_end.split(':')[1], 10);

  const working_time_start_hour = parseInt(working_time_start.split(':')[0], 10);
  const working_time_start_minute = parseInt(working_time_start.split(':')[1], 10);

  const working_time_end_hour = parseInt(working_time_end.split(':')[0], 10);
  const working_time_end_minute = parseInt(working_time_end.split(':')[1], 10);

  return {
    break_time_end,
    break_time_start,
    working_time_start,
    working_time_end,
    break_time_start_hour,
    break_time_start_minute,
    break_time_end_hour,
    break_time_end_minute,
    working_time_start_hour,
    working_time_start_minute,
    working_time_end_hour,
    working_time_end_minute,
  };
};

export const setUser = (user) => setKeyValue(USER_KEY, user, localStorage);

export const getUser = () => getKeyValue(USER_KEY, localStorage);
