import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import settingReducer from './slices/settings';
import managementReducer from './slices/managements';
// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  settings: settingReducer,
  managements: managementReducer,
});

export default rootReducer;
