import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { json2FormData } from 'utils/formdata';
import { showError } from '../../../utils/error';

// depthPath is the path to the slice from the root reducer end with a dot (.)
const name = 'investigation-socket-ipd';
const depthPath = 'managements.';

const apis = {
  getAllTelegram: '/api-telegram/telegram-chat',
  getAllInvestigationType: '/api-patient/get-patient-investigation-type',
  sendRequest: '/api-telegram/telegram-send-message',
};

// initialState is the initial state of the slice
const initialState = {
  status: false,
  imagingStatus: false,
  socketPatientId: null,

  loading: false,
  sendLoading: false,
};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setImagingStatus: (state, action) => {
      state.imagingStatus = action.payload;
    },
    setPatientSocketId: (state, action) => {
      state.socketPatientId = action.payload;
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },

    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

const receiveResultLaborIPD = (data) => async (dispatch, getState) => {
  dispatch(slice.actions.setStatus(data));
};

const receiveResultImagingIPD = (data) => async (dispatch, getState) => {
  dispatch(slice.actions.setImagingStatus(data));
};

const promiseActions = {
  receiveResultLaborIPD,
  receiveResultImagingIPD,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
