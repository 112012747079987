import axios from 'axios';
import { get, isEmpty } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

const name = 'roomManagement';
const depthPath = 'managements.';

const initialState = {
  data: [],
  loading: false,
  currentEditData: null,
  actionLoading: false,
  patientRooms: [],
  availableRooms: [],
  patiendLoading: false,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setPatientLoading: (state, action) => {
      state.patiendLoading = action.payload;
    },
    setActionLoading: (state, action) => {
      state.actionLoading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
    setCurrentEditData: (state, action) => {
      state.currentEditData = action.payload;
    },
    setPatiendRooms: (state, action) => {
      state.patientRooms = action.payload;
    },
    setAvailableRooms: (state, action) => {
      state.availableRooms = action.payload;
    },
    removeItemFromData: (state, action) => {
      state.data = state.data?.filter((item) => +item.patient_progress_note_id !== +action.payload);
    },
    updateStatusRoom: (state, action) => {
      const bed_id = action.payload.bed_id;
      const status = action.payload.status;
      state.availableRooms = state.availableRooms?.map((room) => {
        if (+room.building_floor_room_bed_id === +bed_id) {
          return { ...room, is_unavailable: +status };
        }
        return room;
      });
    },
  },
});

const fetchAllRoomManagement = (data) => async (dispatch) => {
  try {
    // dispatch(slice.actions.setLoading(true));
    const response = await axios.post('/api-system-setting/get-all-building-floor-room-beds', data);
    console.log(response);
    dispatch(slice.actions.setData(response.data?.data || []));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  }
  // finally {
  //   dispatch(slice.actions.setLoading(false));
  // }
};

const createEditRoomManagement = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setActionLoading(true));
    const api = {
      create: '/api-system-setting/add-building-floor-room-bed',
      update: '/api-system-setting/update-building-floor-room-bed',
    };
    const bed_id = data.get('building_floor_room_bed_id');
    if (isEmpty(bed_id)) {
      const response = await axios.post(api.create, data);
      dispatch(slice.actions.setData(response.data?.data || []));
    } else {
      const response = await axios.post(api.update, data);
      dispatch(fetchAllRoomManagement());
    }
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setActionLoading(false));
  }
};

const deleteRoomManagement = (id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setActionLoading(true));
    const response = await axios.delete(`/api-system-setting/delete-building-floor-room-bed/${id}`);
    console.log(response);
    dispatch(slice.actions.setData(response.data?.data || []));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setActionLoading(false));
  }
};

// PATIEND ROOM
const getPatientRooms = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setPatientLoading(true));
    const response = await axios.post(`/api-patient/get-current-ipd-patient-room-bed`, data);
    console.log(response);
    dispatch(slice.actions.setPatiendRooms(response.data?.data || []));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setPatientLoading(false));
  }
};
const getPatientAvailableRooms = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setPatientLoading(true));
    const response = await axios.post(`/api-patient/get-all-available-room-beds`, data);
    console.log(response);
    dispatch(slice.actions.setAvailableRooms(response.data?.data || []));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setPatientLoading(false));
  }
};
const actionAddPatientToBed = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setPatientLoading(true));
    const response = await axios.post(
      `/api-patient/action-assign-patient-current-ipd-to-room-bed`,
      data
    );
    dispatch(slice.actions.setPatiendRooms(response.data?.data || []));
    // update room status
    const bed_id = data.get('building_floor_room_bed_id');
    if (bed_id) {
      dispatch(slice.actions.updateStatusRoom({ bed_id, status: 1 }));
    }
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setPatientLoading(false));
  }
};
const deleteBedFromPatient = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setPatientLoading(true));
    const response = await axios.post(
      `/api-patient/delete-assign-patient-current-ipd-to-room-bed`,
      data
    );
    dispatch(slice.actions.setPatiendRooms(response.data?.data || []));
    // update room status
    const bed_id = data.get('building_floor_room_bed_id');
    if (bed_id) {
      dispatch(slice.actions.updateStatusRoom({ bed_id, status: 0 }));
    }
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setPatientLoading(false));
  }
};

const promiseActions = {
  fetchAllRoomManagement,
  createEditRoomManagement,
  deleteRoomManagement,
  getPatientRooms,
  getPatientAvailableRooms,
  actionAddPatientToBed,
  deleteBedFromPatient,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
