import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

const name = 'laboratory-test-template';
const depthPath = 'managements.';

const apis = {
  getAll: '/api-laboratory/get-all-labor-test-templates',
  add_edit: '/api-laboratory/add-edit-labor-test-template',
  delete: '/api-laboratory/delete-labor-test-template',
  getOne: '/api-laboratory/get-labor-test-template',
};

const initialState = {
  data: [],
  loading: false,
  current: {
    data: null,
    loading: false,
  },
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCurrentData: (state, action) => {
      state.current.data = action.payload;
    },
    setCurrentLoading: (state, action) => {
      state.current.loading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

const fetchAllLaboratoryTestTemplates = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAll, data);
    dispatch(slice.actions.setData(response.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};
const fetchOneLaboratoryTestTemplate = (id) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setCurrentLoading(true));
    const response = await axios.get(`${apis.getOne}/${id}`);
    dispatch(slice.actions.setCurrentData(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setCurrentLoading(false));
  }
};
const addEditLaboratoryTestTemplate = (data) => async (dispatch) => {
  try {
    const response = await axios.post(apis.add_edit, data);
    if (response.status === 200) dispatch(slice.actions.setData(response.data?.data));
    return { status: true, message: response?.data?.message };
  } catch (error) {
    return { status: false, message: error.message };
  }
};
const deleteLaboratoryTestTemplate = (id) => async (dispatch) => {
  try {
    const response = await axios.delete(`${apis.delete}/${id}`);
    if (response.status === 200) dispatch(slice.actions.setData(response.data?.data));
    return { status: true, message: response?.data?.message };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

const promiseActions = {
  fetchAllLaboratoryTestTemplates,
  addEditLaboratoryTestTemplate,
  deleteLaboratoryTestTemplate,
  fetchOneLaboratoryTestTemplate,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
