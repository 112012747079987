import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { get } from 'lodash';
import { showError } from '../../../utils/error';

// ===========================
// depthPath is the path to the slice from the root reducer end with a dot (.)
const depthPath = 'settings.';
const name = 'visit-type';
const apis = {
  getOne: '',
  getAll: '',
  create: '',
  update: '',
  delete: '',
};

// ===========================
// initialState is the initial state of the slice
const initialState = {
  data: [],
  loading: false,
  openCreateDialog: false,
  currentEditData: null,
  currentDeleteData: null,
};

// ===========================
// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
    setOpenCreateDialog: (state, action) => {
      state.openCreateDialog = action.payload;
    },
    setCurrentEditData: (state, action) => {
      state.currentEditData = action.payload;
    },
    setCurrentDeleteData: (state, action) => {
      state.currentDeleteData = action.payload;
    },
  },
});

// ===========================
// promiseActions are the actions that are dispatched by the components
const fetchData = () => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.get(apis.getAll);
    dispatch(slice.actions.setData(response.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const createData = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    await axios.post(apis.create, data);
    dispatch(fetchData());
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const updateData = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    await axios.post(apis.update, data);
    dispatch(fetchData());
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const deleteData = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    await axios.delete(apis.delete, data);
    dispatch(fetchData());
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

// ===========================
// export
const promiseActions = {
  fetchData,
  createData,
  updateData,
  deleteData,
};
// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
