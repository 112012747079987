import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import orderBy from 'lodash/orderBy';
import { showError } from '../../../utils/error';

// depthPath is the path to the slice from the root reducer end with a dot (.)
const name = 'ipd';
const depthPath = 'managements.';

const apis = {
  getOne: '/api-patient/get-patient-current-ipd/',
  getAll: '/api-patient/get-all-patient-current-ipds',
};

// initialState is the initial state of the slice
const initialState = {
  data: [],
  statusData: [],
  loading: false,
  currentEditData: null,
  openCreateDialog: false,
  openAddPatientDialog: false,
  currentViewPatientId: null,
  currentViewPatientData: null,
  selectPatientQr: null,
};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setSelectPatientQr: (state, action) => {
      state.selectPatientQr = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
    setOpenCreateDialog: (state, action) => {
      state.openCreateDialog = action.payload;
    },
    setOpenAddPatientDialog: (state, action) => {
      state.openAddPatientDialog = action.payload;
    },
    setCurrentViewPatientId: (state, action) => {
      state.currentViewPatientId = action.payload;
    },
    setCurrentEditData: (state, action) => {
      state.currentEditData = action.payload;
    },
    setCurrentViewPatientData: (state, action) => {
      state.currentViewPatientData = action.payload;
    },
    setVisitFeatureStatus: (state, action) => {
      const { status, object_key } = action.payload;
      const index = state.currentViewPatientData?.visit_feature_data?.findIndex(
        (x) => x?.object_key === object_key
      );
      if (index !== -1) {
        state.currentViewPatientData.visit_feature_data[index].completed_status = `${status}`;
      }
    },
    setIPDStatus: (state, action) => {
      state.statusData = action.payload;
    },
  },
});

// promiseActions are the actions that are dispatched by the components
const fetchAll = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAll, data);

    console.log(response.data.data);
    
    dispatch(
      slice.actions.setData(
        response.data?.data?.map((item) => ({ ...item, id: item?.patient_current_ipd_id }))
      )
    );

    dispatch(slice.actions.setIPDStatus(response.data?.status_data));

  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const getOneData = (id) => async (dispatch, getState) => {
  try {
    const response = await axios.get(apis.getOne + id);
    dispatch(
      slice.actions.setCurrentViewPatientData({
        ...response.data?.data,
        visit_feature_data: orderBy(response.data?.user_visit_feature_data, ['sortkey'], ['asc']),
      })
    );
  } catch (error) {
    dispatch(slice.actions.setError(error));
  }
};

const promiseActions = {
  getOneData,
  fetchAll,
};
// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
