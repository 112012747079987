import axios from 'axios';
import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from 'utils/error';

const name = 'wardRoomBed';
const depthPath = 'managements.';

const apis = {
  // Ward

  getAllWardData: '/api-system-setting/get-all-wards',
  getOneWardData: '/api-system-setting/get-ward',
  addWard: '/api-system-setting/add-ward',
  updateWard: '/api-system-setting/update-ward',
  deleteWard: '/api-system-setting/delete-ward',

  // Room

  getAllRoomData: '/api-system-setting/get-all-rooms',
  getOneRoomData: '/api-system-setting/get-room',
  addRoom: '/api-system-setting/add-room',
  updateRoom: '/api-system-setting/update-room',
  deleteRoom: '/api-system-setting/delete-room',

  // Bed

  getAllBedData: '/api-system-setting/get-all-beds',
  getOneBedData: '/api-system-setting/get-bed',
  addBed: '/api-system-setting/add-bed',
  updateBed: '/api-system-setting/update-bed',
  deleteBed: '/api-system-setting/delete-bed',
};

const initialState = {
  allWardData: [],
  wardTypeData: [],
  departmentData: [],

  allRoomData: [],
  roomTypeData: [],
  allBedData: [],
  loading: false,
  createLoading: false,

  openDrawer: {
    create: false,
    edit: false,
    delete: false,
  },

  currentWard: {
    data: null,
    loading: false,
    editLoading: false,
    deleteLoading: false,
    openDrawer: {
      create: false,
      edit: false,
      delete: false,
    },
  },
  currentRoom: {
    data: null,
    loading: false,
    editLoading: false,
    deleteLoading: false,
    openDrawer: {
      create: false,
      edit: false,
      delete: false,
    },
  },
  currentBed: {
    data: null,
    loading: false,
    editLoading: false,
    deleteLoading: false,
    openDrawer: {
      create: false,
      edit: false,
      delete: false,
    },
  },
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    // Wards Data
    setAllWardData: (state, action) => {
      state.allWardData = action.payload;
    },
    setWardTypeData: (state, action) => {
      state.wardTypeData = action.payload;
    },
    setDepartmentData: (state, action) => {
      state.departmentData = action.payload;
    },
    // Add Ward
    addWardDataSuccess(state, action) {
      // Find the maximum existing id
      const maxId = state.allWardData.reduce((max, ward) => Math.max(max, ward.id), 0);
      // Ensure the new ward data has a unique id
      const newWardData = {
        ...action.payload,
        id: maxId + 1, // Set the new id to be one greater than the maximum existing id
      };
      state.allWardData.push(newWardData); // Add the new Ward data to the list
      state.loading = false;
    },
    addWardDataFailure(state, action) {
      state.error = action.payload;
      state.loading = false;
    },

    // Update Room
    updateWardDataSuccess(state, action) {
      const index = state.allWardData.findIndex((ward) => ward.id === action.payload.id);
      if (index !== -1) {
        state.allWardData[index] = action.payload; // Update the existing Ward data
      }
      state.loading = false;
    },
    updateWardDataFailure(state, action) {
      state.error = action.payload;
      state.loading = false;
    },

    // Rooms Data
    setAllRoomData: (state, action) => {
      state.allRoomData = action.payload;
    },
    setRoomTypeData: (state, action) => {
      state.roomTypeData = action.payload;
    },
    // Add Room
    addRoomDataSuccess(state, action) {
      // Find the maximum existing id
      const maxId = state.allRoomData.reduce((max, room) => Math.max(max, room.id), 0);
      // Ensure the new room data has a unique id
      const newWardData = {
        ...action.payload,
        id: maxId + 1, // Set the new id to be one greater than the maximum existing id
      };
      state.allRoomData.push(newWardData); // Add the new Room data to the list
    },
    addRoomDataFailure(state, action) {
      state.error = action.payload;
      state.loading = false;
    },

    // Update Room
    updateRoomDataSuccess(state, action) {
      const index = state.allRoomData.findIndex((room) => room.id === action.payload.id);
      if (index !== -1) {
        state.allRoomData[index] = action.payload; // Update the existing Room data
      }
      state.loading = false;
    },
    updateRoomDataFailure(state, action) {
      state.error = action.payload;
      state.loading = false;
    },

    // Beds Data
    setAllBedData: (state, action) => {
      state.allBedData = action.payload;
    },

    // Add Bed
    addBedDataSuccess(state, action) {
      // Find the maximum existing id
      const maxId = state.allBedData.reduce((max, ward) => Math.max(max, ward.id), 0);
      // Ensure the new bed data has a unique id
      const newWardData = {
        ...action.payload,
        id: maxId + 1, // Set the new id to be one greater than the maximum existing id
      };
      state.allBedData.push(newWardData); // Add the new Bed data to the list
    },
    addBedDataFailure(state, action) {
      state.error = action.payload;
      state.loading = false;
    },

    // Update Bed
    updateBedDataSuccess(state, action) {
      const index = state.allBedData.findIndex((bed) => bed.id === action.payload.id);
      if (index !== -1) {
        state.allBedData[index] = action.payload; // Update the existing bed data
      }
      state.loading = false;
    },
    updateBedDataFailure(state, action) {
      state.error = action.payload;
      state.loading = false;
    },

    // Loading
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCreateLoading: (state, action) => {
      state.createLoading = action.payload;
    },

    // OpenDrawer
    setBedOpenDrawer: (state, action) => {
      const { target, value } = action.payload;
      state.currentBed.openDrawer[target] = value;
    },
    setWardOpenDrawer: (state, action) => {
      const { target, value } = action.payload;
      state.currentWard.openDrawer[target] = value;
    },
    setRoomOpenDrawer: (state, action) => {
      const { target, value } = action.payload;
      state.currentRoom.openDrawer[target] = value;
    },

    // Current Ward
    setCurrentWard: (state, action) => {
      state.currentWard.data = action.payload;
    },
    setCurrentWardLoading: (state, action) => {
      state.currentWard.loading = action.payload;
    },
    setCurrentWardEditLoading: (state, action) => {
      state.currentWard.editLoading = action.payload;
    },
    setCurrentWardDeleteLoading: (state, action) => {
      state.currentWard.deleteLoading = action.payload;
    },

    // Current Room
    setCurrentRoom: (state, action) => {
      state.currentRoom.data = action.payload;
    },
    setCurrentRoomLoading: (state, action) => {
      state.currentRoom.loading = action.payload;
    },
    setCurrentRoomEditLoading: (state, action) => {
      state.currentRoom.editLoading = action.payload;
    },
    setCurrentRoomDeleteLoading: (state, action) => {
      state.currentRoom.deleteLoading = action.payload;
    },

    // Current Bed
    setCurrentBed: (state, action) => {
      state.currentBed.data = action.payload;
    },
    setCurrentBedLoading: (state, action) => {
      state.currentBed.loading = action.payload;
    },
    setCurrentBedEditLoading: (state, action) => {
      state.currentBed.editLoading = action.payload;
    },
    setCurrentBedDeleteLoading: (state, action) => {
      state.currentBed.deleteLoading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

// --- WARDS --- //
const fetchAllWardsData = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const res = await axios.post(apis.getAllWardData, data);
    dispatch(slice.actions.setAllWardData(res?.data?.data));
    dispatch(slice.actions.setWardTypeData(res?.data?.ward_type_data));
    dispatch(slice.actions.setDepartmentData(res?.data?.department_data));
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchOneWardData = (id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setCurrentWardLoading(true));
    const res = await axios.get(`${apis.getOneWardData}/${id}`);
    dispatch(slice.actions.setCurrentWard(res?.data?.data));
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setCurrentWardLoading(false));
  }
};

const addWardData = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setCreateLoading(true));
    const res = await axios.post(apis.addWard, data);
    if (res.status === 200) {
      dispatch(slice.actions.addWardDataSuccess(res.data));
    } else {
      dispatch(slice.actions.addWardDataFailure(res.data.message));
    }
    return res;
  } catch (err) {
    dispatch(slice.actions.setError(err));
    return { status: 500, message: 'An error occurred' }; // Return an error object
  } finally {
    dispatch(slice.actions.setCreateLoading(false));
  }
};

const updateWardData = (data, id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setCurrentWardEditLoading(true));
    const res = await axios.put(`${apis.updateWard}/${id}`, data);
    if (res.status === 200) {
      dispatch(slice.actions.updateWardDataSuccess(res.data));
    } else {
      dispatch(slice.actions.updateWardDataFailure(res.data.message));
    }
    return res;
  } catch (err) {
    dispatch(slice.actions.setError(err));
    return { status: 500, message: 'An error occurred' }; // Return an error object
  } finally {
    dispatch(slice.actions.setCurrentWardEditLoading(false));
  }
};

const deleteWardData = (id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setCurrentWardDeleteLoading(true));
    const res = await axios.delete(`${apis.deleteWard}/${id}`);
    if (res.status === 200) {
      dispatch(slice.actions.setAllWardData(res.data?.data));
    }
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setCurrentWardDeleteLoading(false));
  }
};

// --- ROOMS --- //

const fetchAllRoomsData = () => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const res = await axios.post(apis.getAllRoomData);
    dispatch(slice.actions.setAllRoomData(res?.data?.data));
    dispatch(slice.actions.setRoomTypeData(res?.data?.room_type_data));
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchOneRoomData = (id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setCurrentRoomLoading(true));
    const res = await axios.get(`${apis.getOneRoomData}/${id}`);
    dispatch(slice.actions.setCurrentRoom(res?.data?.data));
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setCurrentRoomLoading(false));
  }
};

const addRoomData = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setCreateLoading(true));
    const res = await axios.post(apis.addRoom, data);
    if (res.status === 200) {
      dispatch(slice.actions.addRoomDataSuccess(res.data));
    } else {
      dispatch(slice.actions.addRoomDataFailure(res.data.message));
    }
    return res;
  } catch (err) {
    dispatch(slice.actions.setError(err));
    return { status: 500, message: 'An error occurred' }; // Return an error object
  } finally {
    dispatch(slice.actions.setCreateLoading(false));
  }
};

const updateRoomData = (data, id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setCurrentRoomEditLoading(true));
    const res = await axios.put(`${apis.updateRoom}/${id}`, data);
    if (res.status === 200) {
      dispatch(slice.actions.updateRoomDataSuccess(res.data));
    } else {
      dispatch(slice.actions.updateRoomDataFailure(res.data.message));
    }
    return res;
  } catch (err) {
    dispatch(slice.actions.setError(err));
    return { status: 500, message: 'An error occurred' }; // Return an error object
  } finally {
    dispatch(slice.actions.setCurrentRoomEditLoading(false));
  }
};

const deleteRoomData = (id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setCurrentBedDeleteLoading(true));
    const res = await axios.delete(`${apis.deleteRoom}/${id}`);
    if (res.status === 200) {
      dispatch(slice.actions.setAllRoomData(res.data?.data));
    }
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setCurrentBedDeleteLoading(false));
  }
};

// --- BEDS --- //

const fetchAllBedsData = () => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const res = await axios.post(apis.getAllBedData);
    console.log(res?.data);
    dispatch(slice.actions.setAllBedData(res?.data?.data));
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchOneBedData = (id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setCurrentBedLoading(true));
    const res = await axios.get(`${apis.getOneBedData}/${id}`);
    dispatch(slice.actions.setCurrentBed(res?.data?.data));
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setCurrentBedLoading(false));
  }
};

const addBedData = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setCreateLoading(true));
    const res = await axios.post(apis.addBed, data);
    if (res.status === 200) {
      dispatch(slice.actions.addBedDataSuccess(res.data));
    } else {
      dispatch(slice.actions.addBedDataFailure(res.data.message));
    }
    return res;
  } catch (err) {
    dispatch(slice.actions.setError(err.message || 'An error occurred'));
    return { status: 500, message: 'An error occurred' };
  } finally {
    dispatch(slice.actions.setCreateLoading(false));
  }
};

const updateBedData = (data, id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setCurrentBedEditLoading(true));
    const res = await axios.put(`${apis.updateBed}/${id}`, data);
    if (res.status === 200) {
      dispatch(slice.actions.updateBedDataSuccess(res.data));
    } else {
      dispatch(slice.actions.updateBedDataFailure(res.data.message));
    }
    return res;
  } catch (err) {
    dispatch(slice.actions.setError(err.message || 'An error occurred'));
    return { status: 500, message: 'An error occurred' };
  } finally {
    dispatch(slice.actions.setCurrentBedEditLoading(false));
  }
};

const deleteBedData = (id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setCurrentBedDeleteLoading(true));
    const res = await axios.delete(`${apis.deleteBed}/${id}`);
    console.log('API Response:', res);
    if (res.status === 200) {
      dispatch(slice.actions.setAllBedData(res.data?.data));
    }
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setCurrentBedDeleteLoading(false));
  }
};

const promiseActions = {
  // Wards
  fetchAllWardsData,
  fetchOneWardData,
  addWardData,
  updateWardData,
  deleteWardData,

  // Rooms
  fetchAllRoomsData,
  fetchOneRoomData,
  addRoomData,
  updateRoomData,
  deleteRoomData,

  // Beds
  fetchAllBedsData,
  fetchOneBedData,
  addBedData,
  updateBedData,
  deleteBedData,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
