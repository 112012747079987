import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getClinic } from 'utils/config';
import { enUS, khKH, viVN, deDE, zhCN } from '@mui/material/locale';
import localStorageAvailable from '../utils/localStorageAvailable';
import { allLangs, defaultLang } from './config-lang';
import { useSettingsContext } from 'components/settings';

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const { onChangeDirectionByLang } = useSettingsContext();
  const [availableLangs, setAvailableLangs] = useState(allLangs);
  const [currentLang, setCurrentLang] = useState(defaultLang);

  const storageAvailable = localStorageAvailable();
  const langStorage = storageAvailable ? localStorage.getItem('i18nextLng') : '';

  const updateAvailableLangs = () => {
    const clinic = getClinic();
    const countryLanguage = clinic?.country_language || 'en';
    const langs = getAvailableLangs(countryLanguage);
    setAvailableLangs(langs);
    const lang = langs.find((languages) => languages.value === langStorage) || defaultLang;
    setCurrentLang(lang);
  };

  useEffect(() => {
    updateAvailableLangs();
  }, [storageAvailable]);

  const handleChangeLanguage = (newLang) => {
    i18n.changeLanguage(newLang);
    onChangeDirectionByLang(newLang);
    setCurrentLang(availableLangs.find((lang) => lang.value === newLang) || defaultLang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate: (text, options) => translate(text, options),
    currentLang,
    allLangs: availableLangs,
  };
}

// Define a function to get available languages based on clinic's country language
function getAvailableLangs(countryLanguage) {
  const languages = [
    {
      label: 'English',
      value: 'en',
      systemValue: enUS,
      icon: '/assets/icons/flags/ic_flag_en.svg',
    },
    {
      label: 'German',
      value: 'gm',
      systemValue: deDE,
      icon: '/assets/icons/flags/ic_flag_de.svg',
    },
    {
      label: 'Chinese',
      value: 'cn',
      systemValue: zhCN,
      icon: '/assets/icons/flags/ic_flag_cn.svg',
    },
    {
      label: 'Khmer',
      value: 'kh',
      systemValue: khKH,
      icon: '/assets/icons/flags/ic_flag_kh2.svg',
    },
    {
      label: 'Vietnamese',
      value: 'vn',
      systemValue: viVN,
      icon: '/assets/icons/flags/ic_flag_vn.svg',
    },
  ];

  switch (countryLanguage) {
    case 'VN':
      return languages.filter((lang) => lang.value === 'en' || lang.value === 'vn');
    case 'KH':
      return languages.filter((lang) => lang.value === 'en' || lang.value === 'kh');
    case 'CN':
      return languages.filter((lang) => lang.value === 'en' || lang.value === 'cn');
    case 'GM':
      return languages.filter((lang) => lang.value === 'en' || lang.value === 'gm');
    default:
      return languages;
  }
}
