// @mui
import { Box, Divider, Paper, Stack, Typography } from '@mui/material';

// ----------------------------------------------------------------------
import { Link } from 'react-router-dom';
import Logo from '../../components/logo/Logo';

// auth
// routes
// layouts
//
import AuthLoginForm from './AuthLoginForm';

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        component="img"
        src="/assets/background/background.jpg"
        sx={{
          top: 0,
          left: 0,
          zIndex: 0,
          width: '100vw',
          height: '100vh',
          filter: 'blur(3px)',
          position: 'absolute',
          objectFit: { xs: 'cover', md: 'initial' },
        }}
      />

      <Paper
        sx={{
          p: 4,
          zIndex: 2,
          height: 'auto',
          overflow: 'auto',
          width: { xs: '90%', md: '480px' },
          borderRadius: { xs: '16px', md: '32px' },
          boxShadow: (theme) => theme.customShadows.z20,
        }}
      >
        <Stack alignItems="center" spacing={2} sx={{ mb: 4, position: 'relative' }}>
          <Logo sx={{ height: '100px', width: '100px' }} />
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Typography
              textAlign="center"
              sx={{ fontSize: '24px', fontWeight: 600, fontHeight: 400, lineHeight: '24px' }}
            >
              Welcome Vibolrith Kaom
            </Typography>
            <Typography
              color="inherit"
              textAlign="center"
              sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '24px' }}
            >
              Login to access the Vibolrith’s Health System
            </Typography>
            <Divider variant="middle" sx={{ borderStyle: 'dashed', marginTop: 1, marginBottom: 1 }}>
              <Typography variant="overline" color="text.secondary">
                INFORMATION
              </Typography>
            </Divider>
            <AuthLoginForm />
          </Stack>
        </Stack>
      </Paper>

      {/* Disble Power By Team Logo  */}
      {/* <Stack
        direction="row"
        spacing={2}
        sx={{
          px: 2,
          zIndex: 3,
          left: '50%',
          bottom: '24px',
          height: '44px',
          borderRadius: 1,
          position: 'absolute',
          alignItems: 'center',
          width: 'max-content',
          justifyContent: 'center',
          transform: 'translateX(-50%)',
          backgroundColor: 'rgba(0,0,0,0.5)',
        }}
      >
        {/* <CamisLogo /> 
        <Typography
          fontSize={{ xs: '12px', md: '16px' }}
          fontWeight={400}
          lineHeight="24px"
          color="white"
        >
          Powered by{' '}
          <Link href="/team" style={{ color: 'unset', textDecoration: 'none' }}>
            Team
          </Link>
        </Typography>
      </Stack> */}
    </Stack>
  );
}
