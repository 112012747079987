import { Link as RouterLink } from 'react-router-dom';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Avatar, Box, Link, Typography } from '@mui/material';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// routes
// components

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { user } = useAuthContext();

  return (
    // <Link component={RouterLink} to="" underline="none" color="inherit">
    <StyledRoot>
      <Avatar src={user?.photoURL} alt={user?.displayName} name={user?.displayName} />

      <Box sx={{ ml: 2, minWidth: 0 }}>
        <Typography fontSize={12} fontWeight={500} noWrap>
          {`Vibolrith's Health System`}
        </Typography>

        <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
          user: {user?.username}
        </Typography>
      </Box>
    </StyledRoot>
    // </Link>
  );
}
