import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { get } from 'lodash';
import { showError } from 'utils/error';

// ===========================
// depthPath is the path to the slice from the root reducer end with a dot (.)
const depthPath = 'managements.';
const name = 'newRegistration';

const apis = {
  addNewToVisitList: '/api-patient/add-new-to-visit-list',
  addPatientToVisitList: '/api-patient/add-patient-to-visit-list',
};

// ===========================
// initialState is the initial state of the slice
const initialState = {
  loading: false,
  selectedPatient: null,
};

// ===========================
// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setSelectedPatient: (state, action) => {
      state.selectedPatient = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

const existedResponse = (response) => get(response, 'data.patient_visit_id.status', null) === 400;
// ===========================
// promiseActions are the actions that are dispatched by the components
const addNewToVisitList = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.addNewToVisitList, data);
    if (existedResponse(response)) {
      throw new Error('Patient already exists in the visit list');
    }
    const redirect_url = get(
      response,
      'data.patient_visit_id.department_data.visit_redirect_url_page',
      null
    );
    if (redirect_url) {
      window.open(redirect_url, '_self');
    }
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const addPatientToVisitList = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.addPatientToVisitList, data);
    if (existedResponse(response)) {
      throw new Error('Patient already exists in the visit list');
    }
    const redirect_url = get(
      response,
      'data.patient_visit_id.department_data.visit_redirect_url_page',
      null
    );
    if (redirect_url) {
      window.open(redirect_url, '_self');
    }
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

// ===========================
// export
const promiseActions = {
  addNewToVisitList,
  addPatientToVisitList,
};
// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
