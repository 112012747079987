import axios from 'axios';
import { get } from 'lodash';

import { json2FormData } from 'utils/formdata';
import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { getKeyValue, setKeyValue } from 'utils/config';
import { showError } from '../../../utils/error';
import { HOST_API_URL } from 'config-global';

const guest_app_key = getKeyValue('guest_app_key');

const customUrl = HOST_API_URL;
const name = 'guest';
const depthPath = 'managements.';

const apis = {
  getPatientInformation: '/api-patient/get',
  getVitalSignHistory: '/api-patient/get-vital-sign-history-logs',
  getPhysicalExamHistory: '/api-patient/get-physical-examination-history-logs',
  getPrecriptionHistory: '/api-patient/get-prescription-history-logs',
  getMedicalHistory: '/api-patient/get-medical-history-logs',
  getVaccination: '/api-patient/get-vaccination-history-logs',
  getAppointment: '/api-patient/get-appointment-history-logs',
  getLaboratoryTestResult: '/api-laboratory/get-laboratory-test-result-logs',
  getImageResult: '/api-laboratory/get-imaging-result-logs',
  getPatientNote: '/api-patient/get-note-history-logs',
  // getPatientData: '/api-telegram/webview-data',
  getPatientData: '/api-admin/get-decrypt-data',
};

// initialState is the initial state of the slice
const initialState = {
  data: [],
  data_patient: [],
  data_vital_sign: [],
  data_medicine: [],
  data_physical: [],
  data_precription: [],
  data_appointment: [],
  data_laboratory: [],
  data_vanccinate: [],
  data_image_result: [],
  data_patient_note: [],
  loading: false,
  error: null,
  //   calendarViewDate: new Date(),
};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setPatientData: (state, action) => {
      state.data_patient = action.payload;
    },
    setVitalSignData: (state, action) => {
      state.data_vital_sign = action.payload;
    },
    setMedicineData: (state, action) => {
      state.data_medicine = action.payload;
    },
    setPhysicalData: (state, action) => {
      state.data_physical = action.payload;
    },
    setPrecriptionData: (state, action) => {
      state.data_precription = action.payload;
    },
    setApointmentnData: (state, action) => {
      state.data_appointment = action.payload;
    },
    setLabornData: (state, action) => {
      state.data_laboratory = action.payload;
    },
    setVaccinateData: (state, action) => {
      state.data_vanccinate = action.payload;
    },
    setImageResultData: (state, action) => {
      state.data_image_result = action.payload;
    },
    setPatientNotetData: (state, action) => {
      state.data_patient_note = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

const fetchGetPatientWebViewData = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post( customUrl+apis.getPatientData, data );
    // console.log(response?.data?.data);
    setKeyValue('guest_app_key',response?.data?.data?.secret)
    dispatch(slice.actions.setData(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchGetPatientInformation = (id) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    // const response = await axios.get(customUrl+'/'+guest_app_key+apis.getPatientInformation + id, { headers });
    const response = await axios.get(`${customUrl}/${guest_app_key}${apis.getPatientInformation}/${id}`, { headers });
    // console.log(response?.data);
    dispatch(slice.actions.setPatientData(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

// const data = new FormData();
// data.append('patient_id', 1);

const headers = {
  Authorization:
    'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzQ2NTEzMTIwLCJpYXQiOjE3MTQ5NzcxMjAsImp0aSI6IjI0MjBkZTA3Mzk2YTRhNjE4N2JmMDBjMTg1ZWU4YTljIiwidXNlcl9pZCI6MX0.81PIIgP0yDti6-juSNzpZjsmkL6EZ29DuDSAEGukn_Q',
};

const fetchGetVitalSignHistoryLog = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    // const response = await axios.post(apis.getVitalSignHistory, data, { headers });
    const response = await axios.post(`${customUrl}/${guest_app_key}${apis.getVitalSignHistory}`,data, { headers });
    // console.log(response?.data);
    dispatch(slice.actions.setVitalSignData(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchGetPhysicalExamHistoryLog = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    // const response = await axios.post(apis.getPhysicalExamHistory, data, { headers });
    const response = await axios.post(`${customUrl}/${guest_app_key}${apis.getPhysicalExamHistory}`,data, { headers });
    // console.log(response?.data);
    dispatch(slice.actions.setPhysicalData(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchGetPrecriptionHistory = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getPrecriptionHistory, data, { headers });
    // console.log(response?.data);
    dispatch(slice.actions.setPrecriptionData(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchGetMedicalHistory = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    // const response = await axios.post(apis.getMedicalHistory, data, { headers });
    const response = await axios.post(`${customUrl}/${guest_app_key}${apis.getMedicalHistory}`,data, { headers });
    // console.log(response?.data);
    dispatch(slice.actions.setMedicineData(response.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchGetVaccination = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    // const response = await axios.post(apis.getVaccination, data, { headers });
    const response = await axios.post(`${customUrl}/${guest_app_key}${apis.getVaccination}`,data, { headers });
    // console.log(response?.data);
    dispatch(slice.actions.setVaccinateData(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchGetAppointment = (data) => async (dispatch, getState) => {
  const datas = {
    patient_id: 254,
  };
  try {
    dispatch(slice.actions.setLoading(true));
    // const response = await axios.post(apis.getAppointment, datas, { headers });
    const response = await axios.post(`${customUrl}/${guest_app_key}${apis.getAppointment}`,data, { headers });
    // console.log(response?.data);
    dispatch(slice.actions.setApointmentnData(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchGetLaboratoryTestResult = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    // const response = await axios.post(apis.getLaboratoryTestResult, data, { headers });
    const response = await axios.post(`${customUrl}/${guest_app_key}${apis.getLaboratoryTestResult}`,data, { headers });
    // console.log(response?.data);
    dispatch(slice.actions.setLabornData(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchGetImageResult = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    // const response = await axios.post(apis.getImageResult, data, { headers });
    const response = await axios.post(`${customUrl}/${guest_app_key}${apis.getImageResult}`,data, { headers });
    // console.log(response?.data);
    dispatch(slice.actions.setImageResultData(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchGetPatientNote = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    // const response = await axios.post(apis.getPatientNote, data, { headers });
    const response = await axios.post(`${customUrl}/${guest_app_key}${apis.getPatientNote}`,data, { headers });
    // console.log(response);
    dispatch(slice.actions.setPatientNotetData(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const promiseActions = {
  fetchGetPatientInformation,
  fetchGetVitalSignHistoryLog,
  fetchGetPhysicalExamHistoryLog,
  fetchGetPrecriptionHistory,
  fetchGetMedicalHistory,
  fetchGetVaccination,
  fetchGetAppointment,
  fetchGetLaboratoryTestResult,
  fetchGetImageResult,
  fetchGetPatientNote,
  fetchGetPatientWebViewData,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
