import axios from 'axios';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from 'utils/error';

const name = 'financialDeposit';
const depthPath = 'managements.';

const apis = {
  getAllDeposits: '/api-finance/get-all-financial-deposits',
  addDeposit: '/api-finance/add-financial-deposit',
  updateDeposit: '/api-finance/update-financial-deposit',
  deleteDeposit: '/api-patient/delete-patient-refund',
};

const initialState = {
  data: [],
  loading: false,
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
  openDrawer: {
    add: false,
    update: false,
    delete: false,
  },
  currentData: null,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAddLoading: (state, action) => {
      state.addLoading = action.payload;
    },

    setEditLoading: (state, action) => {
      state.editLoading = action.payload;
    },
    setDeleteLoading: (state, action) => {
      state.deleteLoading = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setCurrentData: (state, action) => {
      state.currentData = action.payload;
    },
    setOpenDrawer: (state, action) => {
      const { target, value } = action.payload;
      state.openDrawer[target] = value;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

const fetchAllFinancialDeposit = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const res = await axios.post(apis.getAllDeposits, data);
    dispatch(slice.actions.setData(res?.data));
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const addFinancialDeposit = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setAddLoading(true));
    const res = await axios.post(apis.addDeposit, data);
    if (res.status === 200) {
      dispatch(fetchAllFinancialDeposit());
      dispatch(slice.actions.setAddLoading(false));
    }
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setAddLoading(false));
  }
};

const updateFinancialDepsoit = (data, id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setEditLoading(true));
    const res = await axios.put(`${apis.updateDeposit}/${id}`, data);
    if (res.status === 200) {
      // await dispatch(fetchAllFinancialDeposit());
      dispatch(slice.actions.setEditLoading(false));
    }
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setEditLoading(false));
  }
};

const deleteFinancialDeposit = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setDeleteLoading(true));
    const res = await axios.delete(`${apis.deleteDeposit}/${data}`);
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setDeleteLoading(false));
  }
};
const promiseActions = {
  fetchAllFinancialDeposit,
  addFinancialDeposit,
  updateFinancialDepsoit,
  deleteFinancialDeposit,
};
// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
