import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';
// import { limit } from 'firebase/firestore';

// depthPath is the path to the slice from the root reducer end with a dot (.)
const name = 'letterTemplateList';
const depthPath = 'managements.';

const apis = {
  getOne: '/api-patient/get/',
  getAll: '/api-system-setting/get-all-medical-record-letter-templates',
  getAllComponent: '/api-admin/get-all-template-components',
  create: '/api-system-setting/add-medical-record-letter-template',
  update: '/api-system-setting/edit-medical-record-letter-template',
};

// initialState is the initial state of the slice
const initialState = {
  template_data: [],
  component_data: [],
  loading: false,
};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setTemplateData: (state, action) => {
      state.template_data = action.payload;
    },
    setComponentData: (state, action) => {
      state.component_data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
        showError(action.payload);
    },
  }
});

// promiseActions are the actions that are dispatched by the components
const fetchAllTemplate= (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAll, data);
    dispatch(slice.actions.setTemplateData(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const updateTemplate= (rawData) => async (dispatch, getState) => {
  const component = rawData.blocks.map((template) => ({
      object_type: template.type,
      data: template.config.children && Array.isArray(template.config.children) && template.config.children.length > 0
          ? template.config.children // Use children if it exists and is an array with elements
          : template.config, // Otherwise, fallback to using just config
  }));


  const data ={
    name: rawData.name,
    description: rawData.note,
    type: rawData.type,
    parent_id: rawData.parentId,
    components: component,
  }
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.put(`${apis.update}/${rawData.id}`, data);
    // dispatch(slice.actions.setTemplateData(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchAllComponent= () => async (dispatch, getState) => {
    try {
      dispatch(slice.actions.setLoading(true));
      const response = await axios.post(apis.getAllComponent);
      dispatch(slice.actions.setComponentData(response?.data?.data));
    } catch (error) {
      dispatch(slice.actions.setError(error));
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };
const promiseActions = {
    fetchAllTemplate,
    fetchAllComponent,
    updateTemplate
  };
// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
