import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';

import { showError } from '../../../utils/error';

// depthPath is the path to the slice from the root reducer end with a dot (.)
const name = 'patient-visit-service-type';
const depthPath = 'managements.';

const apis = {
  getAll: '/api-patient/get-patient-service-type',
  addService: '/api-patient/add-patient-service-type',
  deleteService: '/api-patient/delete-patient-service-by-patient-service-type-id',
};

// initialState is the initial state of the slice
const initialState = {
  serviceData: [],
  serviceIPD: [],
  loading: false,
  addData: [],
  deleteData: [],
  deleteLoading: false,
};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.serviceData = action.payload;
    },
    setServiceIPD: (state, action) => {
      state.serviceIPD = action.payload;
    },
    setAddData: (state, action) => {
      state.addData = action.payload;
    },
    setDeleteLoading: (state, action) => {
      state.deleteLoading = action.payload;
    },
    setDeleteData: (state, action) => {
      state.deleteData = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
    setOpenCreateDialog: (state, action) => {
      state.openCreateDialog = action.payload;
    },
    setCurrentEditData: (state, action) => {
      state.currentEditData = action.payload;
    },
  },
});

// promiseActions are the actions that are dispatched by the components
const fetchAllVisitService =
  (data, type = 0) =>
  async (dispatch, getState) => {
    try {
      dispatch(slice.actions.setLoading(true));
      const response = await axios.post(apis.getAll, data);
      
      if (type === 0) {
        dispatch(slice.actions.setData(response.data?.data));
      } else {
        dispatch(slice.actions.setServiceIPD(response.data?.data));
      }
    } catch (error) {
      dispatch(slice.actions.setError(error));
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };

const addVisitPatientService = (data) => async (dispatch, getState) => {
  try {
    await axios.post(apis.addService, data);
  } catch (error) {
    dispatch(slice.actions.setError(error));
  }
};

const deleteVisitPateintService = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setDeleteLoading(true));
    await axios.post(apis.deleteService, data);
    dispatch(slice.actions.setDeleteLoading(false));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setDeleteLoading(false));
  }
};

const promiseActions = {
  fetchAllVisitService,
  addVisitPatientService,
  deleteVisitPateintService,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
