// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const vn = {
  IR: 'IR',
  ENT: 'ENT',
  ECG: 'ECG',
  MRI: 'MRI',
  HPV: 'HPV',
  DATE: 'Ngày',
  BMD: 'BMD',
  EEG: 'EEG',
  EMG: 'EMG',
  NOTE: 'Ghi chú',
  SPO2: 'SPO2',
  FEES: 'LỆ PHÍ',
  X_RAY: 'X-Ray',
  OTHER: 'Khác',
  PULSE: 'Mạch',
  HEIGHT: 'Chiều cao',
  WEIGHT: 'Trọng lượng',
  CT_SCAN: 'Quét CT',
  ABDOMEN: 'Bụng',
  GLUCOSE: 'Đường huyết',
  VACCINES: 'Vắc-xin',
  SYSTOLIC: 'Tâm thu',
  OPERATION: 'Vận hành',
  FIBROSCAN: 'Fibroscan',
  ALLERGIES: 'Dị ứng',
  DIASTOLIC: 'Tâm trương',
  PATHOLOGY: 'Bệnh lý',
  VACCINATION: 'Tiêm chủng',
  SPIROMETRY: 'Đo phế dung',
  EVALUATION: 'Đánh giá',
  VITAL_SIGN: 'Dấu hiệu sinh tồn',
  ECHOGRAPHY: 'Siêu âm',
  OSTEOSCOPY: 'Nội soi xương',
  COLONOSCOPY: 'Nội soi đại tràng',
  TEMPERATURE: 'Nhiệt độ',
  MEDICATIONS: 'Thuốc',
  MAMMOGRAPHY: 'Chụp nhũ ảnh',
  PRESCRIPTION: 'Đơn thuốc',
  CATH_LAB: 'Phòng thí nghiệm Cath',
  MEDICAL_SUPPLY: 'Vật tư y tế',
  RADIOTHERAPY: 'Xạ trị',
  ADMINISTERED: 'Được quản lý',
  ENT_ENDOSCOPY: 'Nội soi ENT',
  INVESTIGATION: 'Điều tra',
  MENTAL_STATUS: 'Trạng thái tinh thần',
  NEUROSONOLOGY: 'Siêu âm thần kinh',
  TREATMENT_PLAN: 'Kế hoạch điều trị',
  SKIN_EXTREMITY: 'Da cực đoan',
  CLINIC_FEATURE: 'Tính năng phòng khám',
  FAMILY_HISTORY: 'Tiền sử gia đình',
  SOCIAL_HISTORY: 'Tiền sử sinh hoạt xã hội',
  PRESENT_ILLNESS: 'Bệnh hiện tại',
  MEDICAL_HISTORY: 'Hồ sơ bệnh án',
  CHIEF_COMPLAINT: 'Khiếu nại chính',
  RESPIRATORY_RATE: 'Tần số hô hấp',
  CYTOPATHOLOGY_HPV: 'HPV tế bào học',
  UROGENITAL_SYSTEM: 'Hệ thống niệu sinh dục',
  REVIEW_OF_SYSTEMS: 'Đánh giá hệ thống',
  CURRENT_MEDICATION: 'Thuốc hiện tại',
  HISTORY_OF_ILLNESS: 'Tiểu sử bệnh tật',
  GENERAL_APPEARANCE: 'Diện mạo chung',
  RESPIRATORY_SYSTEM: 'Hệ hô hấp',
  GLASGOW_COMA_SCALE: 'Thang hôn mê Glasgow',
  EVALUATION_SUMMARY: 'Tóm tắt đánh giá',
  OTORHINOLARYNGOLOGY: 'Tai mũi họng',
  VACCINATION_HISTORY: 'Tiểu sử tiêm chủng',
  EVALUATION_CATEGORY: 'Danh mục đánh giá',
  EVALUATION_DIAGNOSIS: 'Đánh giá chẩn đoán',
  PHYSICAL_EXAMINATION: 'Kiểm tra thể chất',
  PAST_MEDICAL_HISTORY: 'Tiểu sử y tế trước đây',
  H_PYLORI_BREATH_TEST: 'Kiểm tra hơi thở H-Pylori',
  LABORATORY_TEST_SHEET: 'Bảng thử nghiệm trong phòng thí nghiệm',
  CARDIOVASCULAR_SYSTEM: 'Hệ thống tim mạch',
  CENTRAL_NERVOUS_SYSTEM: 'Hệ thần kinh trung ương',
  GASTRO_INTESTINAL_SYSTEM: 'Hệ thống tiêu hóa',
  PRODUCT_NAME_LOT_NUMBER: 'Tên sản phẩm | Số lô',
  FINAL_DIAGNOSIS_EVALUATION: 'Chẩn đoán & Đánh giá Cuối cùng',
  TODAY_APPOINTMENT_LIST: 'Danh sách cuộc hẹn hôm nay',
  APPOINTMENT_TIME: 'Thời gian hẹn',
  PATIENT: 'Bệnh nhân',
  TOTAL_PATIENT: 'Tổng số bệnh nhân',
  CURRENT_PATIENT_IPD_INFORMATION: 'Thông tin IPD bệnh nhân hiện tại',
  SAVE: 'Lưu',
  ADMISSION_CODE: 'Mã nhập viện',
  ADMISSION_DATE: 'Ngày nhập viện',
  EXPECTED_DISCHARGE_DATE: 'Ngày xuất viện dự kiến',
  DEPARTMENT: 'Khoa',
  ADMISSION_TYPE: 'Loại nhập viện',
  ATTENDING_PHYSICIAN: 'Bác sĩ điều trị',
  NURSE_ASSIGNED: 'Y tá được chỉ định',
  DIAGNOSIS: 'Chẩn đoán',
  STATUS: 'Trạng thái',
  BLOOD_PRESSURE: 'Huyết áp',
  PHYSICIAN: 'Bác sĩ',
  PATIENT_INFORMATION: 'Thông tin bệnh nhân',
  PATIENT_QR_CODE: 'Mã QR của bệnh nhân',
  FULLNAME: 'Họ và tên',
  FULLNAME_LATIN: 'Họ và tên đầy đủ (Latin)',
  GENDER: 'Giới tính',
  AGE: 'Tuổi',
  PATIENT_PAYMENT_TYPE: 'Loại thanh toán của bệnh nhân',
  REFER_DOCTOR: 'Bác sĩ giới thiệu',
  BLOOD_GROUP: 'Nhóm máu',
  VISIT_INFORMATION: 'Thông tin thăm khám',
  PREVIEW: 'Xem trước',
  ADD_NEW: 'Thêm mới',
  EDIT: 'Chỉnh sửa',
  DELETE: 'Xóa',
  EXIPIRED_DATE: 'Ngày hết hạn',
  UPLOAD_IMAGE: 'Tải hình ảnh lên',
  OPEN: 'Mở',
  PRICE_DOMESTIC: 'Giá trong nước',
  PRICE_FOREIGNER: 'Giá người nước ngoài',
  PRICE_NSSF: 'Giá NSSF',
  PRICE_INSURANCE: 'Giá bảo hiểm',
  PHARMACY: 'Nhà thuốc',
  MEDICATION: 'Thuốc',
  MEDICATION_LIST: 'Danh sách thuốc',
  ADD_MEDICAL_FROM_PROVIDER: 'Thêm thuốc từ nhà cung cấp',
  MEDICATION_FORM: 'Mẫu đơn thuốc',
  PACK: 'Đóng gói',
  IN_STOCK: 'Trong kho',
  STOCK_LSIT: 'Danh sách kho',
  COUNT: 'Số lượng',
  INSTRUCTION_INFORMATION: 'Thông tin hướng dẫn',
  FREQUENCY: 'Tần suất',
  ROUTE: 'Đường dùng',
  STRENGTH: 'Hàm lượng',
  INSTRUCTIONS_FOR_USE: 'Hướng dẫn sử dụng',
  COUNT_IN_STOCK: 'Số lượng trong kho',
  LAST_DELIVERY: 'Lần giao hàng cuối',
  VISIT_DATE: 'Ngày thăm khám',
  VISIT_CODE: 'Mã thăm khám',
  PATIENT_NAME: 'Tên bệnh nhân',
  VISIT_INFORMAITON: 'Thông tin thăm khám',
  ISSUED_PRESCRIPTION: 'Đơn thuốc đã phát hành',
  QUANTITY: 'Số lượng',
  DOSAGE_FORM_MORNING: 'Liều dùng (Buổi sáng)',
  DOSAGE_FORM_AFTERNOON: 'Liều dùng (Buổi chiều)',
  DOSAGE_FORM_EVENING: 'Liều dùng (Buổi tối)',
  DOSAGE_FORM_NIGHT: 'Liều dùng (Ban đêm)',
  DETAIL_PRESCRIPTION: 'Chi tiết đơn thuốc',
  MEDICAL_SETTING: 'Cài đặt y tế',
  INVESTIGATION_SETTING: 'Cài đặt điều tra',
  IMAGING_TYPE: 'Loại hình ảnh',
  EXTERNAL_SERVICE: 'Dịch vụ bên ngoài',
  SERVICE_TYPE: 'Loại dịch vụ',
  AUTOMATIC_FEE: 'Phí tự động',
  PROCEDURE_TYPE: 'Loại thủ tục',
  LABORATORY_TYPE: 'Loại phòng thí nghiệm',
  VACCINATION_TYPE: 'Loại vắc-xin',
  ALERGY_TYPE: 'Loại dị ứng',
  MEDICAL_SUPPLY_TYPE: 'Loại vật tư y tế',
  MEDICAL_DIAGNOSIS_TYPE: 'Loại chẩn đoán y khoa',
  SHORT_DESCRIPTION: 'Mô tả ngắn',
  LONG_DESCRIPTION: 'Mô tả dài',
  EQUIPMENT_TYPE: 'Loại thiết bị',
  UNIT_PRICE: 'Đơn giá',
  ALERT_COUNT_IN_STOCK: 'Cảnh báo số lượng hàng tồn kho',
  RETURN: 'Trả lại',
  NOT_RETURN: 'Không trả lại',
  EQUIPMENT: 'Thiết bị',
  REQUEST: 'Yêu cầu',
  NOT_RETURN_ITMES: 'Các mục không trả lại',
  RETURN_ITEMS: 'Các mục trả lại',
  ISSUED_BY: 'Được phát hành bởi',
  OPEN_REQUEST_EQUIPMENT: 'Mở yêu cầu thiết bị',
  REQUEST_BY: 'Yêu cầu bởi',
  LIST_REQUESTED_EQUIPMENTS: 'Danh sách thiết bị được yêu cầu',
  BILL_NUMBER: 'Số hóa đơn',
  PAYMENT_DATE: 'Ngày thanh toán',
  TOTAL: 'Tổng cộng',
  BILL_STATEMENT: 'Bảng kê hóa đơn',
  BILL_LIST: 'Danh sách hóa đơn',
  INCOME: 'Thu nhập',
  PRINT_INVOICE: 'In hóa đơn',
  INVOICE: 'Hóa đơn',
  TO_PATIENT: 'Gửi bệnh nhân',
  MEDICAL_PACKAGE: 'Gói y tế',
  DISCOUNT: 'Giảm giá',
  PAYMENT: 'Thanh toán',
  PAYMENT_ACCOUNT: 'Tài khoản thanh toán',
  AMOUNT: 'Số tiền',
  INCOME_TYPE: 'Loại thu nhập',
  LIST: 'Danh sách',
  EXPENSE_INFO: 'Thông tin chi phí',
  EXPENSE: 'Chi phí',
  SUPPLIER_NAME: 'Nhà cung cấp',
  TOTAL_AMOUNT: 'Tổng số tiền',
  EXPENSE_STATEMENT: 'Bảng kê chi phí',
  STATEMENT: 'Bảng kê',
  EXPENSE_CODE: 'Mã chi phí',
  EXPENSE_TYPE: 'Loại chi phí',
  EXPENSE_DATE_TIME: 'Ngày/giờ chi phí',
  PATIENT_RERUND: 'Hoàn tiền cho bệnh nhân',
  IPD_DATE: 'Ngày IPD',
  REGISTERED: 'Đã đăng ký',
  PERSONAL_CARD: 'Thẻ cá nhân',
  PHONE: 'Điện thoại',
  ACTIONS: 'Hành động',
  SEARCH_PATIENT: 'Tìm kiếm bệnh nhân',
  ARE_YOU_SURE: 'Bạn có chắc không?',
  CANCEL: 'Hủy bỏ',
  FOREINGER: 'Người nước ngoài',
  LASTNAME: 'Họ',
  FIRSTNAME: 'Tên',
  LASTNAME_LATIN: 'Họ (Latin)',
  FIRSTNAME_LATIN: 'Tên (Latin)',
  DATE_OF_BIRTH: 'Ngày sinh',
  PROFESSION: 'Nghề nghiệp',
  EMAIL: 'Email',
  FIRST_PHONE: 'Số điện thoại thứ nhất',
  SECOND_PHONE: 'Số điện thoại thứ hai',
  PERSONAL_CARD_ID: 'Số thẻ cá nhân',
  NATIONALITY: 'Quốc tịch',
  EMERGENCY_CONTACT: 'Liên hệ khẩn cấp',
  ADDRESS_INFORMATION: 'Thông tin địa chỉ',
  CITY_DISTRICT: 'Thành phố/Quận',
  COMMUNE: 'Xã/Phường',
  VILLAGE: 'Thôn/Làng',
  ADDRESS: 'Địa chỉ',
  HEALTH_INFORMATION: 'Thông tin sức khỏe',
  FULL_NSSF: 'NSSF (Quỹ An sinh Xã hội Quốc gia)',
  NSSF_REGISTRATION: 'Đăng ký NSSF',
  NSSF_NUMBER: 'Số NSSF',
  INSURANCE: 'Bảo hiểm',
  MALE: 'Nam',
  FEMALE: 'Nữ',
  MEDICATION_IS_EMPTY: 'Thuốc đã hết',
  NAME: 'Tên',
  COUNTRY: 'Quốc gia',
  DETAIL: 'Chi tiết',
  LIST_OF_DEPARTMENTS: 'Danh sách các khoa',
  MEDICATION_INFORMATION: 'Thông tin thuốc',
  ROOM_NUMBER: 'Số phòng',
  COUNT_OF_BEDS: 'Số lượng giường',
  ROOM_TYPE: 'Loại phòng',
  FLOOR: 'Tầng',
  WARDS_TYPE: 'Loại khoa',
  SPECIAL_FEATURES: 'Tính năng đặc biệt',
  BED_COUFNT: 'Số lượng giường',
  CURRENT_CURRENCY: 'Tiền tệ hiện tại',
  BED_NUMBER: 'Số giường',
  OCCUPIED: 'Đã sử dụng',
  NAME_LATIN: 'Tên (tiếng La-tinh)',
  PRICE: 'Giá',
  LIST_OF_BEDS: 'Danh sách giường',
  LIST_OF_ROOMS: 'Danh sách phòng',
  LIST_OF_WARDS: 'Danh sách các khoa',
  SEARCH: 'Tìm kiếm',
  CODE: 'Mã số',
  CREATE_CHILD: 'Tạo con',
  SELECT_COUNTRY: 'Chọn quốc gia',
  SELECT_MEDICATION_FORM: 'Chọn dạng thuốc',
  CREATE: 'Tạo',
  CREATE_INVESTIGATION: 'Tạo điều tra',
  AUTOMATIC_SERVICE: 'Dịch vụ tự động',
  OPD: 'Khám ngoại trú',
  IPD: 'Điều trị nội trú',
  YES: 'Có',
  NO: 'Không',
  CONFIRM: 'Xác nhận',
  UPDATE: 'Cập nhật',
  DESCRIPTION: 'Mô tả',
  HEALTH_CARE_DESCRIPTION: 'Mô tả chăm sóc sức khỏe',
  ADD: 'Thêm',
  CATEGORY: 'Danh mục',
  SORT_KEY: 'Khóa sắp xếp',
  TYPE: 'Loại',
  PLEASE_SELECT_EQUIPMENT_TO_STOCK: 'Vui lòng chọn thiết bị để nhập kho',
  REQUESTED_PERSON: 'Người yêu cầu',
  START_DATE: 'Ngày bắt đầu',
  END_DATE: 'Ngày kết thúc',
  APPROVED: 'Đã phê duyệt',
  DO_YOU_STILL_WANT_TO_APPROVE_THIS_REQUEST: 'Bạn vẫn muốn phê duyệt yêu cầu này chứ?',
  NO_REQUESTED_EQUIPMENTS: 'Không có thiết bị yêu cầu',
  NOT_APPROVED: 'Chưa được phê duyệt',
  NO_DATA_AVAILABLE: 'Không có dữ liệu',
  DO_YOU_WANT_TO_DELETED: 'Bạn có muốn xóa không?',
  APPROVE: 'Phê duyệt',
  TOTAL_DOCTORS: 'Tổng số bác sĩ',
  TOTAL_APPOINTMENTS: 'Tổng số cuộc hẹn',
  TOTAL_REVENUES: 'Tổng doanh thu',
  DOCTOR: 'Bác sĩ',
  APPOINTMENT_TYPE: 'Loại cuộc hẹn',
  REJECT: 'Từ chối',
  ACCEPT: 'Chấp nhận',
  CHECK: 'Kiểm tra',
  EMPLOYEE_INFORMATION: 'Thông tin nhân viên',
  ADMINISTRATOR: 'Người quản lý',
  PAYMENT_METHOD: 'Phương thức thanh toán',
  SIGNATURE_OF_AUTHORIZED_PERSON: 'Chữ ký của người được ủy quyền',
  BILL_STATEMENT_TYPE: 'Loại sao kê hóa đơn',
  START_TIME: 'Thời gian bắt đầu',
  END_TIME: 'Thời gian kết thúc',
  PATIENT_PAYMENT_REQUEST_TYPE: 'Loại yêu cầu thanh toán của bệnh nhân',
  REASON: 'Lý do',
  SELECTION: 'Lựa chọn',
  FIELD_IS_REQUIRED: 'Lĩnh vực được yêu cầu',
  INFORMATION: 'Thông tin',
  TIME: 'Thời gian',
  CHOOSE: 'Chọn',
  APPLY_TO: 'Nộp đơn',
  APPLY_DEPARTMENT_DOCTOR: 'Nộp đơn Khoa | Bác sĩ',
  SELECT_APPLY_TYPE: 'Chọn Áp dụng Loại',
  JANUARY: 'Tháng 1',
  FEBRUARY: 'Tháng 2',
  MARCH: 'Tháng 3',
  APRIL: 'Tháng 4',
  MAY: 'Tháng 5',
  JUNE: 'Tháng 6',
  JULY: 'Tháng 7',
  AUGUST: 'Tháng 8',
  SEPTEMBER: 'Tháng 9',
  OCTOBER: 'Tháng 10',
  OCTORBER: 'Tháng 10',
  NOVEMBER: 'Tháng 11',
  DECEMBER: 'Tháng 12',
  OPERATION_INCOME: 'Thu nhập hoạt động',
  USE: 'Sử dụng',
  SUPPLIER: 'Nhà cung cấp',
  REFERENCE_NUMBER: 'Số tham chiếu',
  PATIENT_INFO: 'Thông tin bệnh nhân',
  BED_NUMBER_WARD_ROOM: 'Giường | Khoa | Phòng',
  ADD_PATIENT_BED: 'Thêm bệnh nhân | Giường',
  WARD_NAME: 'Tên khoa',
  ROOM_NAME: 'Tên phòng',
  NSSF_MEMBER: 'Thành viên BHXH',
  DISCHARGE_DATE: 'Ngày xuất viện',
  BED: 'Giường',
  ROOM: 'Phòng',
  WARD: 'Khoa',
  DAILY: 'Hàng ngày',
  CONTINUE: 'Tiếp tục',
  DESTINATION: 'Điểm đến',
  SUMMARY: 'Tóm tắt',
  ADDITIONAL_INFORMATION: 'Thông tin bổ sung',
  INSTRUCTION_FOR_PATIENT: 'Hướng dẫn cho bệnh nhân',
  SUBJECTIVE: 'Chủ quan',
  OBJECTIVE: 'Khách quan',
  ASSESSMENT: 'Đánh giá',
  PLAN: 'Kế hoạch',
  APPOINTMENT: 'Cuộc hẹn',
  FROM_MEDICAL_PACKAGE: 'Từ gói y tế',
  EXTERNAL: 'Dịch vụ bên ngoài',
  RESULT: 'Kết quả',
  SEND_REQUESTS_TO_LABORATORY: 'Gửi yêu cầu đến phòng xét nghiệm',
  SEND_REQUESTS_TO_IMAGING: 'Gửi yêu cầu đến khoa chẩn đoán hình ảnh',
  PRINT: 'In',
  ADD_NEW_PACKAGE: 'Thêm gói mới',
  MENU_OPTIONS: 'Tùy chọn menu',
  SUPPLY_TYPE: 'Loại vật tư',
  ADD_FROM_PATIENT_LIST: 'Thêm từ danh sách bệnh nhân',
  MEASUREMENT: 'Đo lường',
  OPERATION_DATE: 'Ngày phẫu thuật',
  OPERATION_TYPE: 'Loại phẫu thuật',
  REQUEST_DATE: 'Ngày yêu cầu',
  REFUSE: 'Từ chối',
  REASON_TYPE: 'Loại lý do',
  DATE_OUT: 'Ngày ra viện',
  VOICE_ATTACHMENT: 'Ghi âm | Tệp đính kèm',
  ATTACHMENT: 'Tệp đính kèm',
  VOICE: 'Ghi âm',
  CURRENT: 'Hiện tại',
  HISTORY: 'Tiền sử',
  HISTORY_DATE: 'Ngày tiền sử',
  WARD_TYPE: 'Loại khoa',
  OBSERVATION_REVIEW: 'Đánh giá theo dõi',
  SERVICE: 'Dịch vụ',
  INCREASE_PRICE: 'Tăng giá',
  DECREASE_PRICE: 'Giảm giá',
  CALCULATED_PRICE: 'Giá tính toán',
  ITEM: 'Mục',
  OVER_PACKAGE: 'Vượt gói',
  LIST_OF_PATIENTS: 'Danh sách bệnh nhân',
  APPLY_PATIENT_BED: 'Áp dụng bệnh nhân | giường',
  LIST_OF_PATIENT_REFUNDS: 'Danh sách hoàn trả',
  REFUND_DATE: 'Ngày hoàn trả',
  REASON_OF_REFUND: 'Lý do hoàn trả',
  VISIT_CODE_DATE: 'Mã khám | Ngày',
  ADMISSION_CODE_DATE: 'Mã nhập viện | Ngày',
  OPERATING_EXPENSE: 'Chi phí hoạt động',
  OPERATING_INCOME: 'Thu nhập hoạt động',
  TOTAL_PRICE: 'Tổng giá',
  SOURCE: 'Nguồn',
  DEPOSIT_DATE: 'Ngày đặt cọc',
  NUMBER_OF_DEPOSIT: 'Số lần đặt cọc',
  DEPOSIT_SOURCE: 'Nguồn đặt cọc',
  FINANCIAL_DEPOSIT: 'Đặt cọc tài chính',
  BREAKDOWN_OF_DEPOSIT_SOURCE: 'Phân tích chi tiết nguồn đặt cọc',
  SUMMARY_OF_DEPOSITS: 'Tóm tắt các khoản đặt cọc',
  MONTH: 'Tháng',
  YEAR: 'Năm',
  ASSET: 'Tài sản',
  ACCOUNT: 'Tài khoản',
  LIABILITY: 'Nợ phải trả',
  EQUITY: 'Vốn chủ sở hữu',
  FINANCIAL_BALANCE_SHEET_DATE: 'Ngày lập bảng cân đối kế toán',
  CASH_FLOW_ANALYSIS: 'Phân tích dòng tiền',
  NUMBER_OF_TRANSACTIONS: 'Số lượng giao dịch',
  INCOME_GENERETED: 'Thu nhập phát sinh',
  PROCEDURE: 'Thủ thuật',
  PROVIDING_MEDICATION: 'Cung cấp thuốc',
  DOSAGE: 'Liều lượng',
  PROVIDED_TIME: 'Thời gian cung cấp',
  YES_PROVIDE: 'Có, cung cấp',
  INSTRUCTION: 'Hướng dẫn',
  NR: 'Số',
  TEMPLATE: 'Mẫu',
  LETTER: 'Thư',
  SHOW: 'Hiển thị',
  LOT_NUMBER: 'Số lô',
  LOCATION: 'Vị trí',
  HOME_PRESCRIPTION: 'Đơn thuốc tại nhà',
  IMAGING: 'Chẩn đoán hình ảnh',
  MEDICAL_CERTIFICATE: 'Giấy chứng nhận y tế',
  USER_INFORMATION: 'Thông tin người dùng',
  USER_NAME: 'Tên người dùng',
  DASHBOARD: 'Bảng điều khiển',
  LOGOUT: 'Đăng xuất',
  YOUR_DATA_HAS_BEEN_SAVED_SUCCESSFULLY: 'Dữ liệu của bạn đã được lưu thành công',
  FILE_NAME: 'Tên tệp',
  FILE_TYPE: 'Loại tệp',
  FILE_SIZE: 'Kích thước tệp',
  LIST_OF_INSURANCES: 'Danh sách bảo hiểm',
  LIST_OF_SUPPLIERS: 'Danh sách nhà cung cấp',
  CONTACT_PERSON: 'Người liên hệ',
  CONTACT_PHONE: 'Số điện thoại liên hệ',
  CONTACT_EMAIL: 'Email liên hệ',
  VITAL_SIGN_HISTORY: 'Lịch sử dấu hiệu quan trọng',
  PRESCRIPTION_HISTORY: 'Lịch sử kê đơn',
  APPOINTMENT_HISTORY: 'Lịch sử cuộc hẹn',
  NOTE_HISTORY: 'Lịch sử ghi chú',
  MEDICAL_CERTIFICATE_HISTORY: 'Lịch sử giấy chứng nhận y tế',
  ACTIVE: 'Đang hoạt động',
  INACTIVE: 'Không hoạt động',
  IPD_INFO: 'Thông tin nội trú',
  SEARCH_MEDICATION_FORM: 'Biểu mẫu tìm kiếm thuốc',
  EXPIRED_DATE: 'Ngày hết hạn',
  PHYSCAL_EXAMINATION: 'Khám thể chất',
  WEEK: 'Tuần',
  REGISTERED_NUMBER: 'Số đăng ký',
  INVOICE_INFORMATION: 'Thông tin hóa đơn',
  INVOICE_NUMBER: 'Số hóa đơn',
  INVOICE_DATE: 'Ngày hóa đơn',
  TOTAL_PAYMENT_AMOUNT: 'Tổng số tiền thanh toán',
  TOTAL_PAID_AMOUNT: 'Tổng số tiền đã thanh toán',
  TOTAL_AMOUNT_REMAINING: 'Tổng số tiền còn lại',
  OPD_INFO: 'Thông tin ngoại trú',
  END_PRICE: 'Giá cuối cùng',
  TOTAL_PAYMENT: 'Tổng thanh toán',
  PAYMENT_BY: 'Thanh toán bởi',
  REFRESH: 'Làm mới',
  TODAY: 'Hôm nay',
  NUMBER_OF_VISITS: 'Số lần thăm khám',
  NUMBER_OF_ADMISSIONS: 'Số lần nhập viện',
  ACCOUNTS_RECEIVALBE_AGING_BREAKDOWN: 'Phân tích tuổi nợ các khoản phải thu',
  AGE_CATEGORY: 'Nhóm tuổi',
  AMOUNT_OUTSTANDING: 'Số tiền chưa thanh toán',
  ACCOUNTS_PAYABLE_AGING_BREAKDOWN: 'Phân tích tuổi nợ các khoản phải trả',
  TOTAL_OUTSTANDING_BALANCE: 'Tổng số dư chưa thanh toán',
  OUTSTANDING_BALANCE: 'Số dư chưa thanh toán',
  DISCOUNT_OUTSTANDING: 'Chiết khấu chưa thanh toán',
  DISCOUNT_BREAKDOWN_BY_SERVICE: 'Phân tích chiết khấu theo dịch vụ',
  SERVICE_INFO: 'Thông tin dịch vụ',
  VALUE: 'Giá trị',
  AVERAGE: 'Trung bình',
  DEPOSIT_BREAKDOWN_BY_SERVICE: 'Phân tích tiền đặt cọc theo dịch vụ',
  AVERAGE_DEPOSIT: 'Tiền đặt cọc trung bình',
  BALANCE_BREAKDOWN_BY_SUPPLIER: 'Phân tích số dư theo nhà cung cấp',
  NUMBER_OF_INVOICES: 'Số lượng hóa đơn',
  PAYMENT_DUE_DATE: 'Ngày đến hạn thanh toán',
  MONTHLY_BALANCE_DISTRIBUTION: 'Phân bổ số dư hàng tháng',
  DEPOSIT_BREAKDOWN_BY_PAYMENT_METHOD: 'Phân tích tiền đặt cọc theo phương thức thanh toán',
  NUMBER: 'Số lượng',
  ITEM_OF_EXPENSE: 'Khoản chi tiêu',
  DETAIL_INFORMATION: 'Thông tin chi tiết',
  EXPENSE_DATE: 'Ngày chi tiêu',
  REFERENCE: 'Tham chiếu',
  ASSIGNED_INFORMATION: 'Thông tin phân công',
  GENERAL_INFORMATION: 'Thông tin chung',
  INCOME_DATE: 'Ngày thu nhập',
  YOUR_DATA_HAS_BEEN_SUCCESSFULLY_REMOVED: 'Dữ liệu của bạn đã được xóa thành công.',
  YOUR_DATA_HAS_BEEN_SUCCESSFULLY_UPDATED: 'Dữ liệu của bạn đã được cập nhật thành công.',
  YOUR_DATA_HAS_BEEN_SUCCESSFULLY_CREATED: 'Dữ liệu của bạn đã được tạo thành công.',
  ARE_YOU_SURE_WANT_TO_DELETE: 'Bạn có chắc chắn muốn xóa không?',
  BILL_DATE: 'Ngày lập hóa đơn',
  UPLOAD_ATTACHMENT: 'Tải lên tệp đính kèm',
  YOUR_DATA_HAS_BEEN_SUCCESSFULLY_SAVED: 'Dữ liệu của bạn đã được lưu thành công.',
  REASON_FOR_REFUND: 'Lý do hoàn tiền',
  MEDICATION_FROM_PROVIDER: 'Thuốc từ nhà cung cấp',
  PATIENT_APPOINTMENT: 'Cuộc hẹn của bệnh nhân',
  RESPIRATORY_SYTSTEM: 'Hệ hô hấp',
  PDF_VIEWER: 'Trình xem PDF',
  JOB_STATUS: 'Trạng thái công việc',
  JOB_TYPE: 'Loại công việc',
  FACTORY_NUMBER: 'Số nhà máy',
  PATENT_NUMBER: 'Số bằng sáng chế',
  START_WORK_DATE: 'Ngày bắt đầu làm việc',
  WORKING_STATUS: 'Trạng thái làm việc',
  APPLY: 'Áp dụng',
  APPLIED: 'Đã áp dụng',
  CHOOSED: 'Đã chọn',
  USERNAME: 'Tên người dùng',
  NURSE: 'Y tá',
  ASSIGN_TO: 'Phân công cho',
  ADMIN_STAFF: 'Nhân viên quản trị',
  DELETE_USER: 'Xóa người dùng',
  ACCESS_MODULE_LIST: 'Danh sách module truy cập',
  MENU_BY_ROLE: 'Menu theo vai trò',
  VIEW: 'Xem',
  ASSIGN: 'Phân công',
  ASSIGN_MODULE: 'Phân công module',
  LABORATORY_IMAGING: 'Phòng xét nghiệm | Chẩn đoán hình ảnh',
  BY: 'Bởi',
  CHECK_EXCHANGE_RATE: 'Kiểm tra tỷ giá hối đoái',
  WORKING_DAYS: 'Ngày làm việc',
  TIME_TABLES: 'Bảng giờ',
  CURRENCY_TYPE: 'Loại tiền tệ',
  TELEGRAM_NAME: 'Tên Telegram',
  SYSTEM_FEATURES: 'Tính năng hệ thống',
  THE_CLINIC_HOSPITAL_TYPE: 'Loại phòng khám | bệnh viện',
  THE_CLINIC_HOSPITAL_INFO: 'Thông tin phòng khám | bệnh viện',
  MONDAY: 'Thứ Hai',
  TUESDAY: 'Thứ Ba',
  WEDNESDAY: 'Thứ Tư',
  THURSDAY: 'Thứ Năm',
  FRIDAY: 'Thứ Sáu',
  SATURDAY: 'Thứ Bảy',
  SUNDAY: 'Chủ Nhật',
  EXCHANGE_RATE: 'Tỷ giá hối đoái',
  MORNING: 'Buổi sáng',
  AFTERNOON: 'Buổi chiều',
  EVENING: 'Buổi tối',
  REFERENCE_RANGE_PEDIATRIC:'Reference range (Pediatric)',
  REFERENCE_RANGE_ADULT_FEMALE:'Reference range (Adult Female)',
  REFERENCE_RANGE_ADULT_MALE:'Reference range (Adult Male)',
  UNIT:'Unit',
  SEARCH_ENTER:'Search (Enter)',
  ID:'ID',
  LABORATORY:'Laboratory',
  LABORATORY_ITEM:'Laboratory item',
  LABORATORY_TEST_TEMPLATE:'Laboratory test template',
  REQUESTED_DATE:'Requested date',
  RESULT_DATA_ENTRY:'Result data entry',
  RESULT_DATA_UPLOAD:'Result data upload',
  PENDING:'Pending',
  DONE:'Done',
  PLEASE_SELECT_DATE:'Plese select date',
  BENEFIT_VALUE:'Benefit value',
  LABORATORY_TEST_RESULT:'Laboratory test result',
  LABORATORY_TEST_UNIT:'Laboratory test unit',
  CLINIC_LOCATION:'Location',
  PAYMENT_INFORMATION:'Payment information',
  ADD_PAYMENT:'Add payment',
  PAID_AMOUNT:'Paid amount',
  SELECTED:'Selected',
  SELECT:'Select',
  PLEASE_SECTIONS:'Please selections',
  REASON_FOR_VISIT:'Lý do ghé thăm',
  DOCTOR_DETAILS:'Doctor details',
  DOCTOR_SESSION:'Doctor visit session',
  DOCTOR_BENEFITS:'Doctor benefits',
  NURSE_DETAILS:'Nurse details',
  STAFF_DETAILS:'Staff details',
  PAYMENT_BILL_STATUS:'Payment bill status',
  QUALIFICATIONS:'Qualifications',
  SPECIALTY:'Specialty',
  CLINICAL_EXPERTISE:'Clinical expertise',
  PROFESSIONAL_MEMBERSHIPS_AND_AFFILIATIONS:'Professional membership and alliances',
  PATIENT_REVIEWS_AND_TESTIMONIALS:'Patient reviews and Testimonials',
  BENEFIT_PERCENTAGE:'Benefit percentage',
  QUALIFICATIONS_DETAILS:'Qualification details',
  SPECIALTY_DETAILS:'Specialty details',
  CLINICAL_EXPERTISE_DETAILS:'Clinical expertise details',
  PROFESSIONAL_MEMBERSHIP_AND_AFFILIATION_DETAILS:'Professional membership and Affiliation details',
  PATIENT_REVIEWS_AND_TESTIMONIALS_DETAILS:'Patient reviews and Testitimonials details',
  LIST_OF_PATIENT_INSURANCE_CLAIMS:'List of patient insurance claims',
  AMOUNT_CLAIM:'Amount claim',
  DESCRIPTION_OF_CLAIM:'Description of claim',
  DATE_OF_CLAIM:'Date of claim',
  BILL_INFORMATION:'Bill information',
  INSURANCE_CLAIM:'Insurance claim',
};

export default vn;
