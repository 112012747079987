import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useReducer } from 'react';
import { HOST_API_URL } from 'config-global';
import { json2FormData } from 'utils/formdata';
import axios from 'axios';
// import axios from 'utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';
import { isValidToken, setSession } from './utils';
import { getClinic, getKeyValue, getUser, setClinic, setKeyValue, setUser } from '../utils/config';
import { useLocales } from 'locales';
import { allLangs, defaultLang } from '../locales/config-lang'; 

// ----------------------------------------------------------------------
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      return Promise.reject(error);
    }
    if (error.response?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      return window.location.reload();
    }
    return Promise.reject(error);
  }
);
// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  if (action.type === 'UPDATE_ACCESS_MODULE') {
    console.log('newModule:', action.payload);
    return {
      ...state,
      isAuthenticated: true,
      user: { ...state.user, user_access_module: action.payload },
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const app_key = storageAvailable ? getKeyValue('app_key') : 'no key';

      axios.defaults.baseURL = `${HOST_API_URL}/${app_key}`;

      const user = getUser();
      const clinic = getClinic();
      console.log(clinic);
      const loggedIn = accessToken && isValidToken(accessToken) && user;
      if (loggedIn) {
        setSession(accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const { onChangeLang } = useLocales();
  // LOGIN
  const login = useCallback(async (username, password, appkey) => {
    axios.defaults.baseURL = `${HOST_API_URL}/${appkey}`;
    setKeyValue('app_key', appkey);
    const response = await axios.post(
      '/api-user/login',
      json2FormData({
        username,
        password,
      })
    );
    const { access, clinic_data, ...user } = response.data;
    axios.defaults.headers.common.Authorization = `Bearer ${access}`;
    setSession(access);
    setUser(user);
    setClinic(clinic_data);

    // Set default language after login
    if (storageAvailable) {
      localStorage.setItem('i18nextLng', defaultLang.value);
      onChangeLang(defaultLang.value);
    }
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async (body) => {
    await axios.post('/api-user/logout', body);
    setSession(null);
    localStorage.clear();
    axios.defaults.headers.common.Authorization = '';

    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  const updateAccessModule = useCallback(async (body) => {
    const user = getUser();
    const u_user = { ...user, user_access_module: body };
    setKeyValue('user', u_user);

    dispatch({
      type: 'UPDATE_ACCESS_MODULE',
      payload: body,
    });
  }, [onChangeLang]);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      login,
      register,
      logout,
      updateAccessModule,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      logout,
      register,
      updateAccessModule,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
