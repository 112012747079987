import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { json2FormData } from 'utils/formdata';
import { showError } from '../../../utils/error';

// depthPath is the path to the slice from the root reducer end with a dot (.)
const name = 'telegram-group-chat';
const depthPath = 'managements.';

const apis = {
  getAllTelegram: '/api-telegram/telegram-chat',
  getAllInvestigationType: '/api-patient/get-patient-investigation-type',
  sendRequest: '/api-telegram/telegram-send-message',
  getSearchInvestigation: '/api-medical-setting/search-all-investigations',
};

// initialState is the initial state of the slice
const initialState = {
  data: [],
  dataInvestigationType: [],
  requestData: [],
  status: false,
  InvesStatus: false,
  loading: false,
  sendLoading: false,
  socketPatientId: null,
  individualLabSocket: false,
  individualImagingSocket: false,

  searchData: [],
};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setInvestigationDataType: (state, action) => {
      state.dataInvestigationType = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setStatusInves: (state, action) => {
      state.InvesStatus = action.payload;
    },
    setPatientSocketId: (state, action) => {
      state.socketPatientId = action.payload;
    },
    setRequestData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setRequestLoading: (state, action) => {
      state.sendLoading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
    setIndividualLabSocket: (state, action) => {
      state.individualLabSocket = action.payload;
    },
    setIndividualImagingSocket: (state, action) => {
      state.individualImagingSocket = action.payload;
    },

    setSearchData: (state, action) => {
      state.searchData = action.payload;
    },
  },
});

const getSearchInves = (data) => async (dispatch, getState) => {
  try {
    // dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getSearchInvestigation, data);
    console.log(response.data);
    dispatch(slice.actions.setSearchData(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    // dispatch(slice.actions.setLoading(false));
  }
};
// promiseActions are the actions that are dispatched by the components
const fetchAllTelegramGroup = () => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(
      apis.getAllTelegram,
      json2FormData({ object_type: 'GROUP_CHAT' })
    );
    dispatch(slice.actions.setData(response.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchAllInvestigationType = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAllInvestigationType, data);
    console.log('response', response.data);
    dispatch(slice.actions.dataInvestigationType(response.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const sendRequestInvestigation = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setRequestLoading(true));
    const response = await axios.post(apis.sendRequest, data);
    dispatch(slice.actions.setRequestData(response.data?.data));

    console.log('Request Labor', response.data?.data);
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setRequestLoading(false));
  }
};

const receivedStatus = () => async (dispatch, getState) => {
  dispatch(slice.actions.setStatus(true));
};

const receivedStatusInves = () => (dispatch, getState) => {
  dispatch(slice.actions.setStatusInves(true));
};

const resetStatusInves = () => (dispatch, getState) => {
  dispatch(slice.actions.setStatusInves(false));
};

const resetStatus = () => async (dispatch, getState) => {
  dispatch(slice.actions.setStatus(false));
};

const promiseActions = {
  fetchAllTelegramGroup,
  sendRequestInvestigation,
  fetchAllInvestigationType,
  receivedStatus,
  receivedStatusInves,
  resetStatusInves,
  resetStatus,
  getSearchInves,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
