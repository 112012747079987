import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

// depthPath is the path to the slice from the root reducer end with a dot (.)
const name = 'labor-result-overlay';
const depthPath = 'managements.';

const apis = {
  getAll: '/api-laboratory/get-all-my-patient-result-detail',
  seenCount: '/api-laboratory/seen-my-patient-result-detail',
};

// initialState is the initial state of the slice
const initialState = {
  loading: false,
  open: false,
  data: null,
  imagingData: [],
  openInvesTab: false,
  openInvesIPD: false,
  paramId: null,
  laborData: null,
};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLaborData: (state, action) => {
      state.laborData = action.payload;
    },
    setImagingData: (state, action) => {
      state.imagingData = action.payload;
    },
    setOpenInves: (state, action) => {
      state.openInvesTab = action.payload;
    },
    setOpenInvesIPD: (state, action) => {
      state.openInvesIPD = action.payload;
    },
    setParamsId: (state, action) => {
      state.paramId = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

const fetchLaborGlobalResult = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAll, data);
    console.log(response?.data?.data);
    dispatch(slice.actions.setData(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const fetchImagingGlobalResult = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAll, data);
    dispatch(slice.actions.setImagingData(response?.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const seenResult = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    await axios.post(apis.seenCount, data);
    fetchLaborGlobalResult();
    fetchImagingGlobalResult();
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

// promiseActions are the actions that are dispatched by the components
const openLaborDialog = (data) => async (dispatch, getState) => {
  dispatch(slice.actions.setOpen(data));
};

const openMainInvesDialog = (data) => async (dispatch, getState) => {
  dispatch(slice.actions.setOpenInves(data));
};

const assignParamId = (id) => (dispatch, getState) => {
  dispatch(slice.actions.setParamsId(id));
};

const promiseActions = {
  openLaborDialog,
  openMainInvesDialog,
  assignParamId,
  fetchLaborGlobalResult,
  fetchImagingGlobalResult,
  seenResult
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
