/* eslint-disable consistent-return */
/* eslint-disable no-void */
import { get, lowerCase } from 'lodash';

import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { dispatch } from 'redux/store';
import axios from 'axios';
import { showError } from '../../../utils/error';

const name = 'medicalPackageSetting';
const depthPath = 'settings.';

const apis = {};

const initialState = {
  data: null,
  loading: false,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

async function getAllMedicalPackages() {
  try {
    const { data } = await axios({
      method: 'post',
      url: '/api-medical-setting/get-all-medical-packages',
    });

    dispatch(slice.actions.setData(data.data));
    return data.data;
  } catch (error) {
    return null;
  }
}

async function addMedicalPackage(formData) {
  try {
    const body = new FormData();

    Object.entries(formData).forEach(([k, v]) => {
      body.append(k, v);
    });

    const { data } = await axios({
      method: 'post',
      url: '/api-medical-setting/add-medical-package',
      data: body,
    });

    void (await getAllMedicalPackages());

    // dispatch(slice.actions.setData(data.data));
    return data.data;
  } catch (error) {
    return null;
  }
}

async function editMedicalPackage(formData) {
  try {
    const body = new FormData();

    Object.entries(formData).forEach(([k, v]) => {
      body.append(k, v);
    });

    const { data } = await axios({
      method: 'put',
      url: `/api-medical-setting/update-medical-package/${formData.id}`,
      data: body,
    });

    void (await getAllMedicalPackages());

    // dispatch(slice.actions.setData(data.data));
    return data.data;
  } catch (error) {
    return null;
  }
}

async function deleteMedicalPackage(id) {
  try {
    const body = new FormData();
    body.append('medical_package_id', id);

    const { data } = await axios({
      method: 'post',
      url: '/api-medical-setting/delete-medical-package',
      data: body,
    });

    void (await getAllMedicalPackages());

    // dispatch(slice.actions.setData(data.data));
    return data.data;
  } catch (error) {
    return null;
  }
}

async function deleteMedicalPackageItem(id) {
  try {
    const body = new FormData();
    body.append('medical_package_item_id', id);

    const { data } = await axios({
      method: 'post',
      url: '/api-medical-setting/delete-medical-package-item',
      data: body,
    });

    void (await getAllMedicalPackages());

    // dispatch(slice.actions.setData(data.data));
    return data.data;
  } catch (error) {
    return null;
  }
}

async function getToAddMedicalItem({ object_type }) {
  try {
    const urls = {
      IMAGING: '/api-medical-setting/get-all-investigations',
      SERVICE: '/api-medical-setting/get-all-service-types',
      PROCEDURE: '/api-medical-setting/get-all-procedure-types',
      VACCINATION: '/api-medical-setting/get-all-vaccination-sheets',
      LABORATORY: '/api-medical-setting/get-all-laboratory-test-sheets',
      MEDICAL: '/api-pharmacy/search-medication',
      // allergy: '/api-medical-setting/get-all-allery-sheet-types',
      // ['medical diagnosis']: '',
    };

    const { data } = await axios({ method: 'post', url: urls[object_type] });

    return data.data;
  } catch (error) {
    /* empty */
  }
}

async function addItemToPackage({ object_type, data, refresh = true }) {
  try {
    const urls = {
      IMAGING: '/api-medical-setting/add-edit-imaging-to-medical-package',
      SERVICE: '/api-medical-setting/add-edit-service-to-medical-package',
      PROCEDURE: '/api-medical-setting/add-edit-procedure-to-medical-package',
      LABORATORY: '/api-medical-setting/add-edit-laboratory-test-sheet-to-medical-package',
      MEDICAL: '/api-medical-setting/add-edit-medication-to-medical-package',
      // vaccination: '/api-medical-setting/get-all-vaccination-sheets',
      // allergy: '/api-medical-setting/get-all-allery-sheet-types',
      // ['medical diagnosis']: '',
    };

    const keys = {
      IMAGING: 'investigation_type',
      SERVICE: 'service_type',
      PROCEDURE: 'procedure_type',
      LABORATORY: 'laboratory_test_sheet',
      MEDICAL: 'medication',
      // vaccination: 'vaccination-sheet',
      // allergy: 'allery-sheet-type',
    };

    const key = keys[object_type];

    const itemList = data.packageItems.map((packageItem) => ({
      [`${key}_id`]: String(packageItem.item.id),
      quantity: String(packageItem.quantity),
    }));

    const updateData = {
      medical_package_id: data.medical_package_id,
      [`${key}_data`]: itemList,
    };

    await axios({ method: 'post', url: urls[object_type], data: updateData });
    if (refresh) await getAllMedicalPackages();
  } catch (error) {
    console.log(error);
  }
}

const promiseActions = {
  getAllMedicalPackages,
  addMedicalPackage,
  deleteMedicalPackage,
  getToAddMedicalItem,
  addItemToPackage,
  deleteMedicalPackageItem,
  editMedicalPackage,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export {
  getAllMedicalPackages,
  addMedicalPackage,
  deleteMedicalPackage,
  getToAddMedicalItem,
  addItemToPackage,
  deleteMedicalPackageItem,
  editMedicalPackage,
};

export default { ...slice, promiseActions, selector, reducer, name };
