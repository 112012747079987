import axios from 'axios';
import { get, isEmpty } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from 'utils/error';
import { da } from 'date-fns/locale';

const name = 'ipd_treatment';
const depthPath = 'managements.';

const apis = {
  getAll: '/api-patient/get-all-patient-medication-nureses',
  addMed: '/api-patient/add-patient-medication-nurse',
  deleteMed: '/api-patient/delete-patient-medication-nurse',
  provideMed: '/api-patient/action-nurse-provide-medication',
};

const initialState = {
  data: [],
  dateList: [],
  selectedDate: {},
  loading: false,
  deleteLoading: false,
  provideLoading: false,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },

    setDateList: (state, action) => {
      state.dateList = action.payload;
    },

    setSelectDate: (state, action) => {
      state.selectedDate = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setProvideLoading: (state, action) => {
      state.provideLoading = action.payload;
    },

    setDeleteLoading: (state, action) => {
      state.deleteLoading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

// promiseActions are the actions that are dispatched by the components
const onGetAllMedIPD = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAll, data);
    console.log(response.data);

    dispatch(slice.actions.setData(response.data?.all_data));
    dispatch(slice.actions.setDateList(response.data?.day_data));
    const state = getState();

    // purpose choose only first time && first index of array data_data
    const dayDate = state.managements.ipd_treatment.selectedDate;
    if (isEmpty(dayDate)) {
      dispatch(slice.actions.setSelectDate(response.data?.day_data[0]));
    }
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const onAddMedIPD = (data) => async (dispatch, getState) => {
  try {
    const response = await axios.post(apis.addMed, data);
    return response;
  } catch (error) {
    dispatch(slice.actions.setError(error));
    return { error };
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const onProvideMedIPD = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setProvideLoading(true));
    const response = await axios.post(apis.provideMed, data);
    return response;
  } catch (error) {
    dispatch(slice.actions.setError(error));
    return { error };
  } finally {
    dispatch(slice.actions.setProvideLoading(false));
  }
};
const onDeleteMedIPD = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setDeleteLoading(true));
    const response = await axios.post(apis.deleteMed, data);
    return response;
  } catch (error) {
    dispatch(slice.actions.setError(error));
    return { error };
  } finally {
    dispatch(slice.actions.setDeleteLoading(false));
  }
};

const promiseActions = {
  onGetAllMedIPD,
  onAddMedIPD,
  onDeleteMedIPD,
  onProvideMedIPD,
};
// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
