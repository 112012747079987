import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from 'utils/error';

const name = 'patient-insurance-claim';
const depthPath = 'managements.';

const apis = {
  getAllPatientInsuranceClaims: '/api-patient/get-all-patient-insurance-claims',
  addPatientInsuranceClaim: '/api-patient/add-patient-insurance-claim',
  updatePatientInsuranceClaim: '/api-patient/update-patient-insurance-claim',
  deletePatientInsuranceClaim: '/api-patient/delete-patient-insurance-claim',
  getAllBill: '/api-finance/get-all-bills',
};

const initialState = {
  data: [],
  loading: false,
  billData: [],
  openDrawer: {
    add: false,
    update: false,
    delete: false,
  },
  currentData: {
    data: null,
    loading: false,
  },
  singleBill: null,
  loadingBill: false,
  editLoading: false,
  deleteLoading: false,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setEditLoading: (state, action) => {
      state.editLoading = action.payload;
    },
    setDeleteLoading: (state, action) => {
      state.deleteLoading = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setCurrentData: (state, action) => {
      state.currentData.data = action.payload;
    },
    setAllBill: (state, action) => {
      state.billData = action.payload;
    },
    setLoadingBill: (state, action) => {
      state.loadingBill = action.payload;
    },
    setSingleData: (state, action) => {
      state.singleBill = action.payload;
    },
    setOpenDrawer: (state, action) => {
      const { target, value } = action.payload;
      state.openDrawer[target] = value;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

const fetchAllPatientInsuranceClaims = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const res = await axios.post(apis.getAllPatientInsuranceClaims, data);
    dispatch(slice.actions.setData(res.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};
const fetchAllBill = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoadingBill(true));
    const res = await axios.post(apis.getAllBill, data);
    dispatch(slice.actions.setAllBill(res?.data?.data));
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setLoadingBill(false));
  }
};
const addPatientInsuranceClaim = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const res = await axios.post(apis.addPatientInsuranceClaim, data);
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};
const updatePatientInsuranceClaim = (data, id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setEditLoading(true));
    const res = await axios.put(`${apis.updatePatientInsuranceClaim}/${id}`, data);
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setEditLoading(false));
  }
};

const deletePatientInsuranceClaim = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setDeleteLoading(true));
    const res = await axios.delete(`${apis.deletePatientInsuranceClaim}/${data}`);
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setDeleteLoading(false));
  }
};

const promiseActions = {
  fetchAllPatientInsuranceClaims,
  addPatientInsuranceClaim,
  updatePatientInsuranceClaim,
  deletePatientInsuranceClaim,
  fetchAllBill,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
