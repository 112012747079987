import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

// depthPath is the path to the slice from the root reducer end with a dot (.)
const name = 'issuedPrescriptions';
const depthPath = 'managements.';

const apis = {
  getPrescriptionSummary: '/api-patient/get-prescription-summary',
  useActionIssuedMedication: '/api-patient/action-issued-medication',
};

const initialState = {
  data: [],
  prescription_status: [],
  loading: false,
  openDrawer: {
    detail: false,
  },
  current: {
    id: null,
    data: null,
    loading: false,
  },
};

// slice is the slice of the redux store
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setPrescriptionStatus: (state, action) => {
      state.prescription_status = action.payload;
    },
    setOpenDrawer: (state, action) => {
      const { target, value } = action.payload;
      state.openDrawer[target] = value;
    },
    setCurrentData: (state, action) => {
      console.log(action.payload);
      state.current.data = action.payload;
    },
    setCurrentId: (state, action) => {
      state.current.id = action.payload;
    },
    setCurrentLoading: (state, action) => {
      state.current.loading = action.payload;
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },

    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

const onGetPrescriptionSummary = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const res = await axios.post(apis.getPrescriptionSummary, data);
    dispatch(slice.actions.setData(res?.data));
    dispatch(slice.actions.setPrescriptionStatus(res?.data?.provide_prescription_status_data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const onUseActionIssuedMedication = (data) => async (dispatch) => {
  try {
    const res = await axios.post(apis.useActionIncomeType, data);
    console.log(res.data);
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const promiseActions = {
  onGetPrescriptionSummary,
  onUseActionIssuedMedication,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
